<!-- eslint-disable vue/no-unused-vars -->
<template>
  <aula-modal
    ref="createAlbumModal"
    css-class="create-album-modal modal-fullscreen"
    :hide-header="true"
    :hide-footer="true"
    :always-visible="true"
    :disable-close="true"
  >
    <!-- ALBUM DETAILS -->
    <div v-if="isAlbumDetailViewEnabled" class="album-details">
      <i
        tabindex="0"
        role="button"
        :class="isMobile ? 'icon-Aula_app_arrow_slideback' : 'icon-Aula_close'"
        :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
        @click="closeCreateAlbumModal()"
        @keydown.enter="closeCreateAlbumModal()"
      />
      <b-row>
        <b-col lg="6" sm="12" class="mb-4">
          <h1><i v-if="!isMobile" class="icon-Album" />{{ getTitle() }}</h1>
        </b-col>
        <b-col v-if="institutionsThatCanBeSeen.length > 1" lg="3" sm="12" class="mb-3">
          <label v-if="isMobile">{{ 'SELECT_INSTITUTION_HEADER' | fromTextKey }}</label>
          <aula-select
            v-model="selectedInstitutionCode"
            :disabled="addMode || editMode || !canEditAlbumInfo || albumSaved"
            :aria-label="'ARIA_LABEL_GALLERY_CHOOSE_INSTITUTION' | fromTextKey"
            @change="onInstitutionSelected"
          >
            <aula-option
              v-for="institution in institutionsThatCanBeSeen"
              :key="institution.institutionCode"
              :label="institution.name"
              :value="institution.institutionCode"
            />
          </aula-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" lg="3" class="mb-3">
          <label v-if="isMobile">{{ 'GALLERY_LABEL_TITLE' | fromTextKey }} <span class="mandatory">*</span></label>
          <b-form-input
            v-model="albumTitle"
            :disabled="addMode || albumSaved || !canEditAlbumInfo"
            type="text"
            :placeholder="'GALLERY_PLACEHOLDER_TITLE' | fromTextKey"
            :aria-label="'ARIA_LABEL_GALLERY_TITLE' | fromTextKey"
            @input="noTitleWarning = false"
          />
          <b-alert variant="danger" :show="noTitleWarning" class="mt-2 mb-0">
            {{ 'GALLERY_NO_TITLE_WARNING' | fromTextKey }}
          </b-alert>
        </b-col>
        <b-col sm="12" lg="3" class="mb-3">
          <label v-if="isMobile">{{ 'GALLERY_SHARE_WITH' | fromTextKey }}</label>
          <b-input-group class="search-module">
            <aula-search-recipients
              :existing-participants="sharedWithGroups"
              :disabled="addMode || parentIsGroup || albumSaved || !canEditAlbumInfo || isCommunicationBlock"
              :from-module="moduleTypes.GALLERY"
              :disable-portal-roles-members-when-expanding-group="[
                portalRoles.CHILD,
                portalRoles.EMPLOYEE,
                portalRoles.GUARDIAN,
              ]"
              :select-all="false"
              :close-dropdown-after-select="false"
              :scope-employees-to-institution="false"
              :doc-types-api="[docTypes.GROUP]"
              :enable-relatives-for-students="false"
              :allow-create="false"
              :reset-input="resetRecipientsInput"
              :institution-code="selectedInstitutionCode"
              :portal-roles="allowedPortalRoles"
              :include-otp-from-guardian-in-sub-group="true"
              placeholder-textkey="GALLERY_SHARE_WITH"
              @emitSelected="addGroupsToList"
            />
            <b-input-group-addon>
              <i class="icon-Aula_search" style="font-size: 1.5em" />
            </b-input-group-addon>
          </b-input-group>
        </b-col>
        <b-col sm="12" lg="3">
          <label v-if="isMobile">{{ 'GALLERY_DESCRIPTION' | fromTextKey }}</label>
          <b-form-textarea
            v-model="albumDescription"
            :disabled="addMode || albumSaved || !canEditAlbumInfo"
            :rows="isMobile ? 3 : 1"
            class="album-description"
            :aria-label="'ARIA_LABEL_GALLERY_DESCRIPTION' | fromTextKey"
            :placeholder="'GALLERY_DESCRIPTION_PLACEHOLDER' | fromTextKey"
          />
        </b-col>
        <b-col v-if="isMobile || (!albumSaved && !addMode && !editMode)" sm="12" lg="3">
          <b-btn v-if="isMobile" variant="light-blue" class="pull-right next-btn" @click="prepareAlbum()">
            {{ 'NEXT_STEP' | fromTextKey }}
          </b-btn>
          <b-btn v-else variant="light-blue" class="pull-right next-btn" @click="prepareAlbum()">
            {{ editMode ? 'GALLERY_UPDATE_ALBUM' : 'GALLERY_SAVE_ALBUM' | fromTextKey }}
          </b-btn>
        </b-col>
      </b-row>
    </div>
    <!-- CONTAINER FOR ADDING INFORMATION TO MULTIPLE MEDIA -->
    <div v-if="isUpdateInformationOnChosenMediaEnabled" class="multiple-media-data">
      <i
        v-if="isMobile"
        role="button"
        tabindex="0"
        class="icon-Aula_app_arrow_slideback"
        :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
        @click="previousStep()"
      />
      <i
        v-else
        tabindex="0"
        role="button"
        class="icon-Aula_close"
        :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
        @click="closeCreateAlbumModal()"
        @keydown.enter="closeCreateAlbumModal()"
      />
      <div v-if="!isMobile" class="multiple-media-counter">
        <i class="icon-Aula_check" /> {{ selectedMedia.length }}
        {{ selectedMedia.length == 1 ? 'GALLERY_IMAGE_SELECTED' : 'GALLERY_IMAGES_SELECTED' | fromTextKey }}
      </div>
      <b-row :class="isMobile ? 'mt-5' : ''">
        <b-col cols="12">
          <h2>
            {{ isMobile ? 'GALLERY_TAG_MEDIA_MOBILE' : 'GALLERY_TAG_MEDIA_DESKTOP' | fromTextKey }}
          </h2>
        </b-col>
      </b-row>
      <b-row v-if="isMobile">
        <b-col v-for="(selected, i) in selectedMedia" :key="i" cols="6" sm="4" class="thumb">
          <AulaImg
            style="height: 80px; width: 100%"
            :img-url="medias.find(med => med.guid == selected.guid).media.file.url"
            :alt="medias.find(med => med.guid == selected.guid).media.title"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="mb-2">
            <b-col md="4" sm="12">
              <label v-if="isMobile">{{ 'GALLERY_TAG_PERSONS' | fromTextKey }}</label>
              <b-input-group class="search-module">
                <aula-search-recipients
                  :from-module="moduleTypes.GALLERY"
                  :select-all="false"
                  :include-self="true"
                  :enable-relatives-for-students="false"
                  :allow-create="false"
                  :close-dropdown-after-select="false"
                  :only-unique-profiles="true"
                  :existing-participants="tags"
                  placeholder-textkey="GALLERY_TAG_PERSONS"
                  invite-group-when-adding-members
                  invite-members-when-selecting-group-portal-role
                  include-choosen-in-search
                  @emitSelected="addTagsToList"
                />
                <b-input-group-addon v-if="tags.length == 0">
                  <i class="icon-tag" style="font-size: 1.5em" />
                </b-input-group-addon>
              </b-input-group>
            </b-col>
            <b-col md="4" sm="12">
              <label v-if="isMobile">{{ 'GALLERY_LABEL_TITLE' | fromTextKey }}</label>
              <b-form-input v-model="title" type="text" :placeholder="'GALLERY_LABEL_TITLE' | fromTextKey" />
            </b-col>
            <b-col md="4" sm="12">
              <label v-if="isMobile">{{ 'GALLERY_DESCRIPTION' | fromTextKey }}</label>
              <b-form-textarea
                v-model="description"
                class="album-description"
                :rows="isMobile ? 3 : 1"
                :placeholder="'GALLERY_DESCRIPTION' | fromTextKey"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex align-items-start">
              <b-btn
                :aria-label="'GALLERY_UPDATE_MEDIA_MARKED_MEDIA_LABEL' | fromTextKey"
                variant="light-blue"
                class="pull-right"
                @click="selectedMedia.length > 1 ? $refs.editMultipleMediaModal.show() : saveMediaData()"
              >
                {{ 'GALLERY_UPDATE_MEDIA_MARKED_MEDIA' | fromTextKey }}
              </b-btn>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <!-- CONTAINER FOR LISTING UPLOADED MEDIA -->
    <div v-if="isUploadedMediaListViewEnabled" class="album-medias">
      <i
        v-if="isMobile"
        role="button"
        tabindex="0"
        class="icon-Aula_app_arrow_slideback step-1"
        :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
        @click="previousStep"
      />
      <b-btn
        v-if="isMobile"
        variant="link"
        class="album-tag-media-mobile"
        :disabled="selectedMedia.length == 0"
        @click="nextStep()"
      >
        {{ 'GALLERY_TAG_MEDIA' | fromTextKey }}
      </b-btn>
      <b-row :class="isMobile ? 'mt-5' : ''">
        <b-col v-if="!isMobile" cols="8">
          <h2>{{ 'GALLERY_ADD_INFORMATION' | fromTextKey }}</h2>
        </b-col>
        <b-col v-if="!editMode" cols="12" lg="4">
          <upload
            icon="icon-Aula_plus"
            parent="gallery"
            :button-text="medias.length > 0 ? 'GALLERY_UPLOAD_MORE_PICTURES' : 'GALLERY_UPLOAD_PICTURES'"
            :show-uploaded-files="false"
            :allow-tagging-for-all="true"
            :limit-file-upload="200"
            :upload-from-gallery="false"
            :existing-media="medias"
            @mediaUpdated="filesWereUploaded"
            @showErrorMaxWidthHeightBody="showErrorMaxWidthHeightBody = true"
            @resetWarnings="showErrorMaxWidthHeightBody = false"
          />
        </b-col>
      </b-row>
      <b-row v-if="showErrorMaxWidthHeightBody" class="mb-3">
        <b-col>
          <i class="icon-Aula_exclamation_sign in-alert-circle white-bg" />
          {{ 'UPLOAD_MODAL_ERROR_MAX_WIDTH_HEIGHT_BODY' | fromTextKey }}
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="medias.length > 1" :class="isMobile ? 'text-right' : ''" cols="12">
          <b-form-checkbox
            class="m-0 mb-lg-2"
            :checked="selectedMedia.length == medias.length"
            @change="toggleAllMedia"
          >
            {{
              selectedMedia.length == medias.length
                ? 'GROUP_ADMINISTRATION_DESELECT_ALL'
                : 'GROUP_ADMINISTRATION_SELECT_ALL' | fromTextKey
            }}
          </b-form-checkbox>
        </b-col>
        <b-col v-if="isMobile && medias.length > 0" cols="12">
          <div class="multiple-media-counter">
            <i class="icon-Aula_check" /> {{ selectedMedia.length }}
            {{ 'GALLERY_IMAGES_SELECTED' | fromTextKey }}
          </div>
        </b-col>
      </b-row>
      <div class="medias">
        <div class="media-upload-preview">
          <RecycleScroller
            v-slot="{ item, index }"
            class="scroller"
            :items="mediaChunks"
            :item-size="isMobile ? 220 : 186"
            key-field="id"
          >
            <div class="media-upload-row mb-2">
              <template v-for="media in item.mediaList">
                <media-data
                  v-if="
                    media &&
                    (isMobile === false || editMode === false || media.media.currentUserCanEditMetaData === true)
                  "
                  :key="media.guid"
                  :selected-media="selectedMedia"
                  class="mb-4"
                  :attachment="media"
                  :medias-updated="mediasUpdated"
                  :can-edit="canEditAlbumInfo"
                  :show-media-data="!isMobile"
                  :allow-selected="isMobile ? true : medias.length > 1"
                  :allow-tagging-for-all="!editMode"
                  :institution-code-for-tagging="albumInstitutionCode"
                  :disabled="isUpdateInformationOnChosenMediaEnabled"
                  @mediaSelected="mediaSelected"
                  @mediaInfoUpdated="mediaInfoUpdated"
                  @removeMedia="removeMedia(media)"
                  @updateUrl="updateUrl"
                />
              </template>
            </div>
          </RecycleScroller>
        </div>
        <b-row>
          <b-col cols="12">
            <div class="gallery-consent-delay">
              <b-alert variant="danger" :show="hasUploadedMediasExceededLimit === true">
                {{ 'GALLERY_MEDIA_LIMIT_EXCEEDED' | fromTextKey }}
              </b-alert>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row v-if="editMode || medias.length > 0">
        <b-col cols="12">
          <div v-if="isMobile">
            <div class="tool-link large">
              <div class="aula-circle-large" @click="saveMediaToAlbum">
                <div class="aula-circle-small">
                  <aula-spinner v-if="disableSave" class="aula-spinner mobile"></aula-spinner>
                  <div class="aula-circle" :class="{ disabled: isLoading }">
                    {{ 'GALLERY_SAVE_MEDIA_TO_ALBUM' | fromTextKey }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-btn
            v-if="!isMobile && (albumSaved || editMode || addMode)"
            variant="light-blue"
            class="float-right"
            :disabled="disableSave"
            @click="saveMediaToAlbum"
          >
            {{ 'GALLERY_SAVE_MEDIA_TO_ALBUM' | fromTextKey }}
            <aula-spinner v-if="disableSave" class="aula-spinner"></aula-spinner>
          </b-btn>
        </b-col>
      </b-row>
      <aula-spinner v-if="isLoading" />
    </div>
    <aula-modal
      ref="editMultipleMediaModal"
      @okClicked="allowMultipleMedia"
      @cancelClicked="$refs.editMultipleMediaModal.hide()"
    >
      {{ 'GALLERY_EDIT_MULTIPLE_MEDIA_1' | fromTextKey }}<br />
      {{ 'GALLERY_EDIT_MULTIPLE_MEDIA_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="communicationBlockedModal"
      :show-cancel="false"
      @okClicked="$refs.communicationBlockedModal.hide()"
    >
      {{ 'GALLERY_BLOCKED_COMMUNICATION_WARNING' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationModal"
      :show-cancel="false"
      @okClicked="$refs.blockedCommunicationModal.hide()"
    >
      {{ 'CALENDAR_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="BUTTON_YES"
      cancel-text="NO"
      @cancelClicked="$refs.theCommunicationChannelIsBlocked.hide()"
      @okClicked="
        $refs.theCommunicationChannelIsBlocked.hide();
        removeBlocked();
      "
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS' | fromTextKey }}
      <div class="mt-2">
        {{ blockedRecipientsString }}
      </div>
    </aula-modal>
    <aula-modal
      ref="tooManyMediaFiles"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.tooManyMediaFiles.hide()"
    >
      {{ 'UPLOAD_MODAL_ERROR_TOO_MANY_MEDIA_FILES' | fromTextKey }}
    </aula-modal>
  </aula-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import { docTypes } from '../../../shared/enums/docTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import MediaData from './MediaData.vue';
import Upload from '../../../shared/components/Upload.vue';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import Vue from 'vue';
import { errorSubCodeTypes } from '../../../shared/enums/errorSubCodeTypes';
import { RecycleScroller } from 'vue-virtual-scroller';
import AulaImg from '../../../shared/components/AulaImg';
import { filterGroupMembershipInstitutions, getDefaultInstitutionCode } from '../../../shared/utils/institutionUtil';
import chunk from 'lodash/chunk';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { blockedCommunicationChannelService } from '../../../shared/services/blockedCommunicationChannelService/blockedCommunicationChannelService';

export default {
  components: {
    AulaImg,
    Upload,
    AulaSearchRecipients,
    MediaData,
    RecycleScroller,
  },
  props: {
    parent: { type: String, default: parentTypes.GALLERY },
  },
  data: function () {
    return {
      Vue: Vue,
      albumTitle: '',
      canEditAlbumInfo: true,
      title: '',
      albumDescription: '',
      description: '',
      tags: [],
      selectedInstitutionCode: null,
      sharedWithGroups: [],
      medias: [],
      blockedCommunicationChannelResponse: null,
      docTypes: docTypes,
      portalRoles: portalRoles,
      parentTypes: parentTypes,
      moduleTypes: moduleTypes,
      albumSaved: false,
      resetRecipientsInput: false,
      selectedMedia: [],
      mediasUpdated: false,
      noTitleWarning: false,
      step: 0,
      isLoading: false,
      editMode: false,
      addMode: false,
      disableSave: false,
      albumId: null,
      allChecked: false,
      showErrorMaxWidthHeightBody: false,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      albums: types.GET_ALBUMS,
      activeAlbum: types.GET_ACTIVE_ALBUM,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      children: types.GET_CHILDREN,
      albumMedia: types.GET_ALBUM_DETAILS,
      activeGroup: types.GET_ACTIVE_GROUP,
      limitMedia: types.GET_LIMIT_MEDIA,
      hasPermissionOnInstitutionGroup: types.HAS_PERMISSION_ON_INSTITUTION_GROUP,
    }),
    blockedRecipientsString() {
      return this.blockedCommunicationChannelResponse
        ? this.blockedCommunicationChannelResponse.getBlockedGroupRecipientsString(Vue.filter('fromTextKey'))
        : '';
    },
    isCommunicationBlock() {
      const institution = this.institutions.find(
        institution => institution.institutionCode === this.selectedInstitutionCode
      );
      return institution?.communicationBlock ?? false;
    },
    allowedPortalRoles() {
      return [portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.OTP];
    },
    isAlbumCreator() {
      return this.creatorInstitutionProfileId === this.albumDetail?.creator?.id;
    },
    institutionsThatCanBeSeen() {
      let institutions = this.institutions;
      institutions = institutions.filter(institution => !institution.communicationBlock);
      const group = this.activeGroup;
      if (this.parent === parentTypes.GROUP) {
        institutions = filterGroupMembershipInstitutions(institutions, group).filter(institution =>
          this.hasPermissionOnInstitutionGroup({ permissionId: permissionEnum.SHARE_MEDIA, institution, group })
        );
      }
      return institutions;
    },
    isAlbumDetailViewEnabled() {
      if (this.isMobile) {
        return this.step === 0;
      }

      return this.selectedMedia.length === 0;
    },
    isUpdateInformationOnChosenMediaEnabled() {
      if (this.isMobile) {
        return this.step === 2;
      }
      return this.selectedMedia.length > 0;
    },
    isUploadedMediaListViewEnabled() {
      if (this.isMobile) {
        return this.step === 1;
      }
      return this.addMode || this.editMode || this.albumSaved;
    },
    albumInstitutionCode() {
      if (this.activeAlbum && this.activeAlbum.creator) {
        return this.activeAlbum.creator.institutionCode;
      }
      return this.selectedInstitutionCode;
    },
    multiButtonItems() {
      return [
        // {icon: 'icon-Aula_check', text: 'GALLERY_SAVE_ALBUM', action: 'saveMediaToAlbum'},
        { icon: 'icon-Aula_attach_doc', action: 'tagMedia' },
      ];
    },
    albumDetail: function () {
      return this.activeAlbum;
    },
    parentIsGroup() {
      return this.activeGroup.id != null;
    },
    hasUploadedMediasExceededLimit() {
      return this.medias.length > 1000;
    },
    creatorInstitutionProfileId() {
      if (this.selectedInstitutionCode != null) {
        return this.institutions.find(inst => inst.institutionCode == this.selectedInstitutionCode)
          .institutionProfileId;
      }
      return this.institutions.find(inst => inst.isPrimary).institutionProfileId;
    },
    mediaChunks() {
      const chunks = chunk(this.medias, 2);
      return chunks.map((chunk, index) => ({ id: index, mediaList: chunk }));
    },
  },
  watch: {
    albumDetail() {
      this.calculateSharedGroups();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setHasMediaChanged: types.MUTATE_HAS_MEDIA_CHANGED,
    }),
    ...mapActions({
      createAlbum: types.CREATE_ALBUM,
      updateAlbum: types.UPDATE_ALBUM,
      createAttachments: types.CREATE_ATTACHMENTS,
      updateAttachments: types.UPDATE_ATTACHMENTS,
      loadAlbumMedia: types.ACTION_GET_ALBUM_DETAILS,
      deleteMedia: types.DELETE_MEDIA,
    }),
    onInstitutionSelected() {
      if (!this.parentIsGroup) {
        this.resetRecipientsInput = !this.resetRecipientsInput;
      }
      this.sharedWithGroups = [];
    },
    updateUrl(value) {
      const index = this.medias.findIndex(m => m.guid == value.guid);
      if (index > -1) {
        this.medias[index].media.file.url = value.url;
      }
    },
    getTitle() {
      let key = 'GALLERY_SAVE_ALBUM';
      if (this.editMode) {
        key = 'GALLERY_UPDATE_ALBUM';
      } else if (this.albumSaved || ['addMedia', 'group-add-media'].includes(this.$route.name)) {
        key = 'GALLERY_ADD_MEDIA_TO_ALBUM_TITLE';
      }
      return this.$options.filters.fromTextKey(key);
    },
    closeCreateAlbumModal() {
      this.sharedWithGroups = [];
      this.disableSave = false;
      this.$emit('closed');
      this.$refs.createAlbumModal.hide();
      if (this.parentIsGroup) {
        if (this.addMode || this.isAlbumCreator) {
          this.$router.push({
            name: 'group-album-details',
            params: {
              groupId: this.activeGroup.id,
              albumId: this.$route.params.albumId,
            },
          });
        } else {
          this.$router.push({
            name: 'group-gallery',
            params: { groupId: this.activeGroup.id },
          });
        }
      } else {
        if ((this.editMode && this.isAlbumCreator) || this.addMode) {
          this.$router.push({
            name: 'albumDetails',
            params: { albumId: this.$route.params.albumId },
          });
        } else {
          this.$router.push({ name: 'gallery' });
        }
      }
    },
    toggleAllMedia() {
      if (this.selectedMedia.length != this.medias.length) {
        this.selectedMedia = [];
        for (const media of this.medias) {
          this.selectedMedia.push({
            guid: media.guid,
            url: media.media.file.url,
          });
        }
      } else {
        this.selectedMedia = [];
      }
      this.mediasUpdated = !this.mediasUpdated;
    },
    filesWereUploaded(medias) {
      for (const media of medias) {
        if (this.medias.filter(med => media.name == med.name && media.size == med.size).length == 0) {
          if (media.media) {
            media.media.tags = [];
          } else {
            media.media = { tags: [] };
          }
          this.medias.push(media);
        }
      }
    },
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
      if (
        (this.addMode && this.step < 1) ||
        (this.editMode && this.step < 0) ||
        (!this.addMode && !this.editMode && this.step < 0)
      ) {
        if (this.parentIsGroup) {
          this.$router.push({ name: 'group-gallery' });
        } else {
          this.$router.push({ name: 'gallery' });
        }
      }
    },
    removeMedia(media) {
      this.selectedMedia = [];
      if (this.editMode) {
        this.deleteMedia({ mediaIds: [media.id] });
      }
      const mediaIndex = this.medias.findIndex(mediaItem => mediaItem.guid === media.guid);
      this.medias.splice(mediaIndex, 1);
    },
    addGroupsToList(groups) {
      this.sharedWithGroups = groups;
    },
    validate() {
      let isValid = true;

      const creatorInstitution = this.institutions.find(
        institution => institution.institutionProfileId === this.creatorInstitutionProfileId
      );
      if (creatorInstitution.communicationBlock) {
        this.$refs.communicationBlockedModal.show();
        isValid = false;
      }

      if (this.albumTitle.trim() === '') {
        this.noTitleWarning = true;
        isValid = false;
      }
      return isValid;
    },
    async prepareAlbum() {
      const isValid = this.validate();
      if (isValid) {
        const hasBlockedComChannels = await this.checkForBlockedComChannels();
        if (hasBlockedComChannels !== true) {
          await this.handleSaveAlbum();
          this.step++;
        }
      }
      this.disableSave = false;
    },
    removeBlocked() {
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.$nextTick(() => {
        const sharedWithGroupsMergedPortalRoles = this.blockedCommunicationChannelResponse.getUnblockedGroups();
        this.sharedWithGroups = this.splitGroupsIntoPortalRoleForDisplay(sharedWithGroupsMergedPortalRoles);
        if (this.sharedWithGroups.length > 0) {
          this.handleSaveAlbum();
        }
      });
    },
    async checkForBlockedComChannels() {
      if (this.sharedWithGroups.length > 0) {
        this.blockedCommunicationChannelResponse =
          await blockedCommunicationChannelService.checkBlockedCommunicationChannel(
            blockedCommunicationChannelService.toBCCRequestModelForPostAndGallery(
              this.creatorInstitutionProfileId,
              this.mergeGroupsIntoPortalRolesForApiCall(this.sharedWithGroups)
            )
          );
        if (this.blockedCommunicationChannelResponse.hasBlockedRecipients()) {
          this.$refs.theCommunicationChannelIsBlocked.show();
          this.disableSave = false;
        }
      }
      return this.blockedCommunicationChannelResponse?.hasBlockedRecipients() ?? false;
    },
    async saveAlbum() {
      let albumId = null;
      const sharedWithGroupsMergedPortalRoles = this.mergeGroupsIntoPortalRolesForApiCall(this.sharedWithGroups);

      if (this.editMode || this.albumId) {
        await this.updateAlbum({
          albumId: this.albumId,
          title: this.albumTitle,
          description: this.albumDescription,
          sharedWith: sharedWithGroupsMergedPortalRoles,
          sharedWithGroups: sharedWithGroupsMergedPortalRoles,
          creatorInstitutionProfileId: this.creatorInstitutionProfileId,
        });
      } else {
        albumId = await this.createAlbum({
          title: this.albumTitle,
          description: this.albumDescription,
          sharedWithGroups: sharedWithGroupsMergedPortalRoles,
          creatorInstitutionProfileId: this.creatorInstitutionProfileId,
        });
      }

      this.albumSaved = true;
      return albumId;
    },
    async handleSaveAlbum() {
      this.isLoading = true;
      await this.saveAttachments();
      this.albumId = await this.saveAlbum();
      if (!this.albumId) {
        this.closeCreateAlbumModal();
      }
      this.isLoading = false;
    },
    saveAttachments() {
      const attachments = Vue.filter('prepareAttachments')(this.medias);
      const updatedMedia = attachments.existingMedia.filter(att => att.media.updated);

      if (updatedMedia.length > 0) {
        return this.updateAttachments({
          media: Vue.filter('prepareMedia')(updatedMedia),
        });
      }
    },
    saveMediaData() {
      for (const media of this.selectedMedia) {
        for (const k in this.medias) {
          if (this.medias[k].guid === media.guid) {
            const mediaTmp = {
              ...this.medias[k],
            };
            if (this.title !== '') {
              mediaTmp.media.title = this.title;
            }
            if (this.description !== '') {
              mediaTmp.media.description = this.description;
            }
            if (this.tags.length > 0) {
              mediaTmp.media.tags = this.tags;
            }
            mediaTmp.media.updated = true;
            this.medias[k] = mediaTmp;
          }
        }
      }
      this.mediasUpdated = !this.mediasUpdated;
      if (this.isMobile) {
        this.step--;
        this.selectedMedia = [];
      }

      this.resetMediaData();
    },
    mediaInfoUpdated(media) {
      for (const key in this.medias) {
        if (this.medias[key].guid == media.guid) {
          this.medias[key] = media;
          this.medias[key].media.updated = true;
        }
      }
    },
    mediaSelected(value) {
      if (!value.checked) {
        const index = this.selectedMedia.findIndex(m => m.guid == value.guid);
        if (index > -1) {
          this.selectedMedia.splice(index, 1);
        }
      } else {
        if (this.selectedMedia.filter(m => m.guid == value.guid).length === 0) {
          this.selectedMedia.push({ guid: value.guid, url: value.url });
        }
      }
      this.fillDataWithSelectedMedia();
    },
    resetMediaData() {
      this.title = '';
      this.tags = [];
      this.description = '';
    },
    fillDataWithSelectedMedia() {
      if (this.selectedMedia.length === 1) {
        const selectedMedia = this.medias.find(media => media.guid === this.selectedMedia[0].guid);
        this.title = selectedMedia.media.title;
        this.tags = selectedMedia.media.tags;
        this.description = selectedMedia.media.description;
      } else {
        this.resetMediaData();
      }
    },
    addTagsToList(tags) {
      // AulaSearchRecipient returns selected groups and their members, filter out group DTOs, keep members only
      this.tags = tags.filter(item => item.type !== docTypes.GROUP.toLowerCase());
    },
    saveMediaToAlbum() {
      if (this.hasUploadedMediasExceededLimit) {
        return;
      }
      this.disableSave = true;
      if (this.institutions.length > 0 && this.selectedInstitutionCode == null) {
        this.selectedInstitutionCode = this.institutions.find(inst => inst.isPrimary).institutionCode;
      }
      if (this.$refs.albumMultiButton != null) {
        this.$refs.albumMultiButton.hide();
      }

      for (const attachment of this.medias) {
        if (attachment.media != null) {
          attachment.media.albumId = this.albumId;
        }
      }

      if (this.editMode) {
        this.prepareAlbum();
      } else {
        const attachments = Vue.filter('prepareAttachments')(this.medias);
        if (attachments.media != null) {
          attachments.ownerInstitutionProfileId = this.institutions.find(
            inst => inst.institutionCode == this.selectedInstitutionCode
          ).institutionProfileId;
          attachments.institutionCode = this.selectedInstitutionCode;
          this.createAttachments(attachments)
            .then(() => {
              this.setHasMediaChanged(true);
              this.closeCreateAlbumModal();
            })
            .catch(error => {
              if (error.response.data.status.subCode === errorSubCodeTypes.mediaUploadExceeded) {
                this.$refs.tooManyMediaFiles.show();
              }

              this.disableSave = false;
            });
        } else {
          this.closeCreateAlbumModal();
        }
      }
    },
    setInitialGroups() {
      if (this.editMode || this.addMode) {
        this.calculateSharedGroups();
        return;
      }

      if (this.parentIsGroup) {
        this.sharedWithGroups = [
          {
            id: this.activeGroup.id,
            name: this.activeGroup.name,
            portalRole: portalRoles.GUARDIAN,
            value: 'group' + this.activeGroup.id + this.activeGroup.institutionCode + portalRoles.GUARDIAN,
            label:
              this.activeGroup.name +
              ' (' +
              Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + portalRoles.GUARDIAN.toUpperCase()) +
              ')',
          },
          {
            id: this.activeGroup.id,
            name: this.activeGroup.name,
            portalRole: portalRoles.EMPLOYEE,
            value: 'group' + this.activeGroup.id + this.activeGroup.institutionCode + portalRoles.EMPLOYEE,
            label:
              this.activeGroup.name +
              ' (' +
              Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + portalRoles.EMPLOYEE.toUpperCase()) +
              ')',
          },
          {
            id: this.activeGroup.id,
            name: this.activeGroup.name,
            portalRole: portalRoles.CHILD,
            value: 'group' + this.activeGroup.id + this.activeGroup.institutionCode + portalRoles.CHILD,
            label:
              this.activeGroup.name +
              ' (' +
              Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + portalRoles.CHILD.toUpperCase()) +
              ')',
          },
        ];
      }
    },
    calculateSharedGroups() {
      this.sharedWithGroups = this.splitGroupsIntoPortalRoleForDisplay(this.albumDetail?.sharedWithGroups ?? []);
    },
    // BE Api will use group.portalRoles but FE AulaSearch will use group.portalRole for displaying
    // Therefore, for display we need to split 1 group into multiple group with portalRole
    // and merge it into portalRoles upon api call (through mergeGroupsIntoPortalRolesForApiCall)
    splitGroupsIntoPortalRoleForDisplay(groupsToSplit) {
      if (Array.isArray(groupsToSplit) === false) {
        return [];
      }

      const sharedWithGroups = [];
      for (const sharedGroup of groupsToSplit) {
        if (sharedGroup.portalRoles != null && sharedGroup.portalRoles.length < 3) {
          for (const role of sharedGroup.portalRoles) {
            if (role === portalRoles.OTP) {
              continue;
            }

            if (sharedWithGroups.find(group => group.id == sharedGroup.id && group.portalRole == role) == null) {
              sharedWithGroups.push({
                id: sharedGroup.id,
                groupId: sharedGroup.id,
                name: sharedGroup.name,
                value: 'group' + sharedGroup.id + '-' + role,
                label:
                  sharedGroup.name + ' (' + Vue.filter('fromTextKey')('GROUP_LABEL_TYPE_' + role.toUpperCase()) + ')',
                portalRole: role,
                type: 'group',
              });
            }
          }
        } else {
          if (sharedWithGroups.find(group => group.id == sharedGroup.id) == null) {
            sharedWithGroups.push({
              id: sharedGroup.id,
              groupId: sharedGroup.id,
              name: sharedGroup.name,
              value: 'group' + sharedGroup.id,
              label: sharedGroup.name,
              portalRole: null,
              type: 'group',
            });
          }
        }
      }
      return sharedWithGroups;
    },
    mergeGroupsIntoPortalRolesForApiCall(groupsToMerge) {
      const sharedWithGroups = [];
      for (const group of groupsToMerge) {
        // Skip any groups that contains id null. Ref. AULA-31821
        if (group.id == null) {
          continue;
        }
        const existing = sharedWithGroups.find(gro => gro.groupId == group.id);
        if (existing != null) {
          if (group.portalRole != null) {
            if (existing.portalRoles != null) {
              existing.portalRoles.push(group.portalRole);
            }
          } else if (group.portalRoles != null) {
            existing.portalRoles = [...new Set([...existing.portalRoles, ...group.portalRoles])];
          } else {
            existing.portalRoles = null;
          }
        } else {
          const newPortalRoles =
            group.portalRole != null ? [group.portalRole] : group.portalRoles != null ? group.portalRoles : null;
          sharedWithGroups.push({
            id: group.id,
            groupId: group.id,
            name: group.name,
            portalRoles: newPortalRoles,
          });
        }
      }
      return sharedWithGroups;
    },
    allowMultipleMedia() {
      this.$refs.editMultipleMediaModal.hide();
      this.saveMediaData();
    },
    async init() {
      if (this.institutionsThatCanBeSeen[0]) {
        this.selectedInstitutionCode = getDefaultInstitutionCode(
          this.institutionsThatCanBeSeen,
          this.activeInstitutionCodes,
          this.institutionsThatCanBeSeen[0].institutionCode
        );
      }

      if (
        this.$route.params.albumId &&
        (this.$route.name == 'editAlbum' ||
          this.$route.name == 'addMedia' ||
          this.$route.name == 'group-edit-album' ||
          this.$route.name == 'group-add-media')
      ) {
        if (this.$route.path.includes('rediger')) {
          this.editMode = true;
          this.step = 0;
          this.albumId = this.$route.params.albumId;
          this.canEditAlbumInfo = this.albumDetail.currentUserCanEdit;
        }
        if (this.$route.path.includes('tilfoej')) {
          this.addMode = true;
          this.step = 1;
          this.albumId = this.$route.params.albumId;
        }
        this.medias = [];
        this.albumTitle = this.albumDetail.title;
        this.albumDescription = this.albumDetail.description;
        if (this.albumDetail.creator?.institutionCode != null) {
          const institution = this.institutions.find(
            inst => inst.institutionCode === this.albumDetail.creator.institutionCode
          );
          if (institution) {
            this.selectedInstitutionCode = institution.institutionCode;
          }
        }
        if (this.editMode) {
          this.isLoading = true;
          await this.loadAlbumMedia({
            albumId: this.$route.params.albumId,
            index: 0,
            limit: 1000,
          }).then(() => {
            for (const media of this.albumMedia) {
              const attachment = {
                id: media.id,
                guid: media.id,
                creator: media.creator,
                albumId: this.albumId,
                media: {
                  file: media.file,
                  mediaType: media.mediaType,
                  tags: media.tags,
                  title: media.title,
                  description: media.description,
                  duration: media.duration,
                  currentUserCanDelete: media.currentUserCanDelete,
                  currentUserCanEditMetaData: media.currentUserCanEditMetaData,
                  currentUserCanEditTags: media.currentUserCanEditTags,
                  extraSmallThumbnailUrl: media.extraSmallThumbnailUrl,
                  smallThumbnailUrl: media.smallThumbnailUrl,
                  mediumThumbnailUrl: media.mediumThumbnailUrl,
                  largeThumbnailUrl: media.largeThumbnailUrl,
                },
              };
              this.medias.push(attachment);
            }
            this.medias.sort((a, b) => a.id - b.id);
            this.medias = this.medias.filter(media => media.media.currentUserCanEditMetaData);
            this.isLoading = false;
          });
        }
      }
      this.setInitialGroups();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.album-description {
  height: 50px;
  @include breakpoint-sm-down() {
    height: unset;
  }
}

.thumb {
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.aula-circle {
  margin: auto;

  &.disabled {
    opacity: 0.4;
  }
}

.icon-Album {
  font-size: 26px;
  margin-right: 10px;
}

.aula-spinner {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 30px;
  top: -12px;

  &.mobile {
    right: -10px;
  }
}

.media-upload-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include breakpoint-lg() {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.media-upload-preview {
  padding-bottom: 110px;
}

.scroller.direction-vertical {
  overflow: visible !important;

  & > /deep/ .vue-recycle-scroller__item-wrapper {
    overflow: visible !important;

    & > .vue-recycle-scroller__item-view {
      &:is(:has(ul.dropdown-menu.show), :focus-within) {
        z-index: 1;
      }
    }
  }
}

.aula-circle-small {
  display: flex;
}
</style>
