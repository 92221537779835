







































































import { PropType } from 'vue';
import AulaPopover from '../../../shared/components/AulaPopover.vue';
import uniqueId from 'lodash/uniqueId';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { MessageRecipientModel } from '../../../shared/models/messageRecipient.model';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import AulaButton from '../../../shared/components/AulaButton.vue';
import IconContainer from '../../../shared/components/IconContainer.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import AvatarGroup from '../../../shared/components/AvatarGroup.vue';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import button from '../../../shared/directives/button.js';

export default {
  components: { AvatarGroup, Icon, IconContainer, AulaButton, AulaPopover },
  directives: { button },
  props: {
    recipients: { type: Array as PropType<MessageRecipientModel[]>, required: true },
    visibleRecipientsAvatar: { type: Array as PropType<MessageRecipientModel[]>, required: true },
    isCollapsed: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    messageRecipientsPopoverContainer() {
      return `relatedProfilesPopover${this.buttonId}`;
    },
    buttonId() {
      return `popover-button-${uniqueId()}`;
    },
    children() {
      return this.recipients.filter(
        r =>
          r.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.INSTITUTION_PROFILE &&
          r.mailBoxOwner.portalRole == portalRoles.CHILD
      );
    },
    guardians() {
      return this.recipients.filter(
        r =>
          r.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.INSTITUTION_PROFILE &&
          r.mailBoxOwner.portalRole == portalRoles.GUARDIAN
      );
    },
    employees() {
      return this.recipients.filter(
        r =>
          r.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.INSTITUTION_PROFILE &&
          r.mailBoxOwner.portalRole == portalRoles.EMPLOYEE
      );
    },
    commonInboxes() {
      return this.recipients.filter(r => r.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX);
    },
    otpInboxes() {
      return this.recipients.filter(r => r.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.OTP_INBOX);
    },
  },
  methods: {
    handleShowPopover() {
      this.$root.$emit('bv::show::popover', this.buttonId);
    },
  },
};
