<template>
  <div class="notification-settings page-layout-padding">
    <div id="notification-clear-all-module" class="margin-bottom-40">
      <h1 class="page-title margin-bottom-20">
        {{ 'NOTIFICATION_CLEAR_ALL_TITLE' | fromTextKey }}
      </h1>
      <b-row>
        <b-col>
          <div class="settings-label large mb-2">
            {{ 'NOTIFICATION_CLEAR_FROM' | fromTextKey }}
          </div>
        </b-col>
      </b-row>
      <b-row align-h="start">
        <b-col cols="4">
          <aula-select
            id="notification-module-type-select"
            :value="notificationSettingModel.getSelectedAreas()"
            multiple
            @change="updateSelectedAreas"
          >
            <aula-option
              v-for="(module, index) in availableAreas"
              :key="index"
              :label="module.name"
              :value="module.value"
              :class="{ 'with-checkbox': true }"
            ></aula-option>
          </aula-select>
        </b-col>
        <b-col cols="2">
          <AulaButton
            class="text-uppercase"
            :disabled="notificationSettingModel.getSelectedAreas().length === 0"
            variant="primary"
            @click.native="confirmationModalOpen"
          >
            {{ 'NOTIFICATION_CLEAR_ALL_BUTTON' | fromTextKey }}
          </AulaButton>
        </b-col>
      </b-row>
    </div>
    <div class="mb-1">
      <h1 class="page-title" tabindex="0">
        {{ 'NOTIFICATION_SETTINGS_TITLE' | fromTextKey }}
      </h1>
      <div class="d-flex">
        <div class="settings-label large mb-2">
          {{ 'NOTIFICATION_SETTINGS_FROM' | fromTextKey }}
        </div>
        <div id="notification-setting-edit-button" class="ml-auto">
          <aula-form-footer
            :is-loading="isLoading"
            :show-cancel="!isViewMode"
            :show-line="false"
            :submit-text="isViewMode ? 'BUTTON_EDIT' : 'BUTTON_SAVE'"
            class="m-0 pb-1"
            :class="'edit-mode'"
            @cancelClicked="cancelModalOkClicked()"
            @submitClicked="isViewMode ? initEditMode() : saveChanges()"
          />
        </div>
      </div>
    </div>
    <div class="notification-settings-body" :class="isViewMode ? 'view-mode' : 'edit-mode'">
      <!--      MESSAGES-->
      <template v-if="notificationSettingModel.getEmailAvailable() || notificationSettingModel.getAppAvailable()">
        <div class="settings-label">
          {{ 'NOTIFICATION_SETTINGS_MESSAGES' | fromTextKey }}
        </div>
        <notification-checkbox
          id="id-employees"
          class="notification-option"
          :checked="notificationSettingModel.getMessagesFromEmployees()"
          group-text-key-name="NOTIFICATION_SETTINGS_MESSAGES"
          text-key-name="NOTIFICATION_SETTINGS_EMPLOYEES"
          :is-view-mode="isViewMode"
          @change="updateMessagesFromEmployees"
        />
        <notification-checkbox
          id="id-guardians"
          class="notification-option"
          :checked="notificationSettingModel.getMessagesFromGuardians()"
          group-text-key-name="NOTIFICATION_SETTINGS_MESSAGES"
          text-key-name="NOTIFICATION_SETTINGS_GUARDIANS"
          :is-view-mode="isViewMode"
          @change="updateMessagesFromGuardians"
        />
        <notification-checkbox
          id="id-children"
          class="notification-option"
          :checked="notificationSettingModel.getMessagesFromChildren()"
          group-text-key-name="NOTIFICATION_SETTINGS_MESSAGES"
          text-key-name="NOTIFICATION_SETTINGS_CHILDREN"
          :is-view-mode="isViewMode"
          @change="updateMessagesFromChildren"
        />
        <!--        CALENDAR-->
        <div class="settings-label">
          {{ 'NOTIFICATION_SETTINGS_CALENDAR' | fromTextKey }}
        </div>
        <notification-checkbox
          id="id-events"
          class="notification-option"
          :class="{ 'single-option': profile.role !== portalRoles.EMPLOYEE }"
          :checked="notificationSettingModel.getCalendar()"
          group-text-key-name="NOTIFICATION_SETTINGS_CALENDAR"
          text-key-name="NOTIFICATION_SETTINGS_CALENDAR_EVENTS"
          :is-view-mode="isViewMode"
          @change="updateCalendar"
        />
        <notification-checkbox
          v-if="profile.role === portalRoles.EMPLOYEE"
          id="id-teacher"
          class="notification-option"
          :checked="notificationSettingModel.getNotifyAssignedAsSubstituteTeacher()"
          group-text-key-name="NOTIFICATION_SETTINGS_CALENDAR"
          text-key-name="NOTIFICATION_SETTINGS_SUBSTITUTE_TEACHER"
          :is-view-mode="isViewMode"
          @change="updateNotifyAssignedAsSubstituteTeacher"
        />
        <notification-checkbox
          v-if="profile.role === portalRoles.EMPLOYEE"
          id="id-lesson-note"
          class="notification-option"
          :checked="notificationSettingModel.getNotifyLessonNoteChanged()"
          group-text-key-name="NOTIFICATION_SETTINGS_CALENDAR"
          text-key-name="NOTIFICATION_SETTINGS_LESSON_NOTE_CHANGED"
          :is-view-mode="isViewMode"
          @change="updateNotifyLessonNoteChanged"
        />
        <!--        GALLERY-->
        <div class="settings-label">
          {{ 'NOTIFICATION_SETTINGS_GALLERY' | fromTextKey }}
        </div>
        <notification-checkbox
          id="id-media"
          class="notification-option single-option"
          :checked="notificationSettingModel.getGallery()"
          group-text-key-name="NOTIFICATION_SETTINGS_GALLERY"
          text-key-name="NOTIFICATION_SETTINGS_MEDIA"
          :is-view-mode="isViewMode"
          @change="updateGallery"
        />
        <!--        OVERVIEW-->
        <div class="settings-label">
          {{ 'NOTIFICATION_SETTINGS_OVERVIEW' | fromTextKey }}
        </div>
        <notification-checkbox
          id="id-post"
          class="notification-option single-option"
          :checked="notificationSettingModel.getPost()"
          group-text-key-name="NOTIFICATION_SETTINGS_OVERVIEW"
          text-key-name="NOTIFICATION_SETTINGS_POST"
          :is-view-mode="isViewMode"
          @change="updatePost"
        />
        <!--        COME AND GO-->
        <template v-if="profile.role === portalRoles.GUARDIAN || profile.role === portalRoles.OTP">
          <div class="settings-label">
            {{ 'PRESENCE_PAGETITLE' | fromTextKey }}
          </div>
          <notification-checkbox
            id="id-activities"
            class="notification-option"
            :checked="notificationSettingModel.getPickUpActivity()"
            group-text-key-name="PRESENCE_PAGETITLE"
            text-key-name="NOTIFICATION_SETTINGS_PICKUP_ACTIVITIES"
            :is-view-mode="isViewMode"
            @change="updatePickUpActivity"
          />
          <notification-checkbox
            id="id-requests"
            class="notification-option second-option"
            :checked="notificationSettingModel.getVacationRequest()"
            group-text-key-name="PRESENCE_PAGETITLE"
            text-key-name="NOTIFICATION_SETTINGS_VACATION_REQUESTS"
            :is-view-mode="isViewMode"
            @change="updateVacationRequest"
          />
        </template>
        <!--        WIDGETS-->
        <template v-for="widgetSetting in notificationSettingModel.getWidgetSettings()">
          <div :key="widgetSetting.widgetId" class="settings-label">
            {{ widgetSetting.title }}
          </div>
          <notification-checkbox
            :id="`id-widget-${widgetSetting.widgetId}`"
            :key="widgetSetting.widgetId"
            class="notification-option single-option"
            :checked="widgetSetting.isActive"
            group-text-key-name="NOTIFICATION_SETTINGS_WIDGET"
            text-key-name="NOTIFICATION_SETTINGS_WIDGET_ACTIVATED"
            :is-view-mode="isViewMode"
            @change="updateWidget(widgetSetting.widgetId, $event)"
          />
        </template>
        <br /><br />
      </template>
    </div>

    <div class="settings-label large">
      {{ 'NOTIFICATION_SETTINGS_ON' | fromTextKey }}
    </div>
    <div class="notification-setting-areas">
      <!--        MOBILE APP-->
      <div class="settings-label">
        {{ 'NOTIFICATION_SETTINGS_APP' | fromTextKey }}
      </div>
      <template v-if="!notificationSettingModel.getAppAvailable()">
        <div class="settings-content">
          {{ 'NOTIFICATION_SETTINGS_NO_APP' | fromTextKey }}
        </div>
        <div class="settings-content">
          {{ 'NOTIFICATION_SETTINGS_DOWNLOAD' | fromTextKey }}
        </div>
        <a href="https://itunes.apple.com/" :aria-label="'ARIA_LABEL_APP_STORE' | fromTextKey" class="settings-content">
          <img class="image-link" src="../../../static/appstores/app_store.svg" alt="" />
        </a>
        <a
          href="https://play.google.com/store/apps/"
          :aria-label="'ARIA_LABEL_GOOGLE_PLAY' | fromTextKey"
          class="settings-content"
        >
          <img class="image-link" src="../../../static/appstores/app_google_play.svg" alt="" />
        </a>
      </template>
      <template v-if="notificationSettingModel.getAppAvailable()">
        <b-form-radio-group
          class="settings-content"
          :checked="notificationSettingModel.getAppDisabled()"
          :disabled="isViewMode"
          @input="updateAppDisabled"
        >
          <b-form-radio :value="true" :class="isViewMode ? 'no-disabled-style' : ''">
            {{ 'NOTIFICATION_SETTINGS_YES' | fromTextKey }}
          </b-form-radio>
          <b-form-radio :value="false" :class="isViewMode ? 'no-disabled-style' : ''">
            {{ 'NOTIFICATION_SETTINGS_NO' | fromTextKey }}
          </b-form-radio>
        </b-form-radio-group>
      </template>

      <!--        EMAIL-->
      <div class="settings-label">
        {{ 'NOTIFICATION_SETTINGS_EMAIL' | fromTextKey }}
      </div>
      <template v-if="!notificationSettingModel.getEmailAvailable()">
        <div class="settings-content">
          {{ 'NOTIFICATION_SETTINGS_NO_EMAIL' | fromTextKey }}<br />
          {{ 'NOTIFICATION_SETTINGS_NO_EMAIL_2' | fromTextKey }}
        </div>
      </template>
      <template v-if="notificationSettingModel.getEmailAvailable()">
        <b-form-radio-group
          :checked="notificationSettingModel.getEmailDisabled()"
          class="settings-content mt-2 mb-2"
          :disabled="isViewMode"
          @input="updateEmail"
        >
          <b-form-radio
            v-sr-only="emailYesSwitchedAriaText"
            :value="true"
            :class="isViewMode ? 'no-disabled-style' : ''"
            :aria-label="emailYesAriaText"
          >
            {{ 'NOTIFICATION_SETTINGS_YES' | fromTextKey }}
          </b-form-radio>
          <b-form-radio
            v-sr-only="emailNoSwitchedAriaText"
            :value="false"
            :class="isViewMode ? 'no-disabled-style' : ''"
            :aria-label="emailNoAriaText"
          >
            {{ 'NOTIFICATION_SETTINGS_NO' | fromTextKey }}
          </b-form-radio>
        </b-form-radio-group>
        <template v-if="notificationSettingModel.getEmailDisabled()">
          <div class="settings-content font-weight-bold my-2">
            {{ 'NOTIFICATION_SETTINGS_FREQUENCY' | fromTextKey }}
          </div>
          <b-form-radio-group
            :checked="notificationSettingModel.getInstant()"
            class="settings-content mb-3"
            :disabled="isViewMode"
            @input="updateInstant"
          >
            <b-form-radio
              v-sr-only:pre="receiveEmailAriaText"
              v-sr-only="selectedOnOffAriaText(notificationSettingModel.getInstant())"
              :value="true"
              :class="isViewMode ? 'no-disabled-style' : ''"
              :aria-label="emailAlwaysAriaText"
            >
              {{ 'NOTIFICATION_SETTINGS_ALWAYS' | fromTextKey }}
            </b-form-radio>
            <b-form-radio
              v-sr-only:pre="receiveEmailAriaText"
              v-sr-only="selectedOnOffAriaText(!notificationSettingModel.getInstant())"
              :value="false"
              :class="isViewMode ? 'no-disabled-style' : ''"
              :aria-label="emailIntervalAriaText"
            >
              {{ 'NOTIFICATION_SETTINGS_INTERVAL' | fromTextKey }}
            </b-form-radio>
          </b-form-radio-group>
          <template v-if="!notificationSettingModel.getInstant()">
            <div class="settings-content font-weight-bold mt-2">
              {{ 'NOTIFICATION_SETTINGS_TIME' | fromTextKey }}
            </div>
            <table class="settings-content weekday-settings" :class="showNoDaySelected ? 'red-border' : ''">
              <tr>
                <td>
                  <b-form-checkbox
                    v-sr-only:pre="receiveEmailAriaText"
                    v-sr-only="selectedOnOffAriaText(everyDay)"
                    :checked="everyDay"
                    :disabled="isViewMode"
                    :class="isViewMode ? 'no-disabled-style' : ''"
                    :aria-label="emailAllDaysAriaText"
                    @input="updateEveryDay"
                    @change="updateModelDays"
                  >
                    {{ 'NOTIFICATION_SETTINGS_EVERYDAY' | fromTextKey }}
                  </b-form-checkbox>
                </td>
                <td>
                  <notification-email-day
                    id="id-monday"
                    :checked="notificationSettingModel.getMonday()"
                    text-key-name="NOTIFICATION_SETTINGS_MONDAY"
                    :is-view-mode="isViewMode"
                    @change="updateMonday"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <notification-email-day
                    id="id-tuesday"
                    :checked="notificationSettingModel.getTuesday()"
                    text-key-name="NOTIFICATION_SETTINGS_TUESDAY"
                    :is-view-mode="isViewMode"
                    @change="updateTuesday"
                  />
                </td>
                <td>
                  <notification-email-day
                    id="id-wednesday"
                    :checked="notificationSettingModel.getWednesday()"
                    text-key-name="NOTIFICATION_SETTINGS_WEDNESDAY"
                    :is-view-mode="isViewMode"
                    @change="updateWednesday"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <notification-email-day
                    id="id-thursday"
                    :checked="notificationSettingModel.getThursday()"
                    text-key-name="NOTIFICATION_SETTINGS_THURSDAY"
                    :is-view-mode="isViewMode"
                    @change="updateThursday"
                  />
                </td>
                <td>
                  <notification-email-day
                    id="id-friday"
                    :checked="notificationSettingModel.getFriday()"
                    text-key-name="NOTIFICATION_SETTINGS_FRIDAY"
                    :is-view-mode="isViewMode"
                    @change="updateFriday"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <notification-email-day
                    id="id-saturday"
                    :checked="notificationSettingModel.getSaturday()"
                    text-key-name="NOTIFICATION_SETTINGS_SATURDAY"
                    :is-view-mode="isViewMode"
                    @change="updateSaturday"
                  />
                </td>
                <td>
                  <notification-email-day
                    id="id-sunday"
                    :checked="notificationSettingModel.getSunday()"
                    text-key-name="NOTIFICATION_SETTINGS_SUNDAY"
                    :is-view-mode="isViewMode"
                    @change="updateSunday"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="font-weight-bold mb-2">
                    {{ 'MOBILE_NOTIFICATION_SETTINGS_TIME_LABEL' | fromTextKey }}
                  </div>
                  <AulaTimepicker
                    id="startTime"
                    class="notification-timepicker"
                    :value="notificationSettingModel.getScheduledTime()"
                    drop-direction="up"
                    format="HH:mm"
                    hour-label="Time"
                    minute-label="Minut"
                    :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                    :aria-label="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
                    hide-clear-button
                    :minute-interval="timepickerSettings.HALF_HOUR_INTERVAL"
                    advanced-keyboard
                    lazy
                    :class="isViewMode ? 'no-disabled-style' : ''"
                    :disabled="isViewMode"
                    @input="updateScheduledTime"
                  />
                </td>
              </tr>
            </table>
            <br />
            <b-alert variant="danger" :show="showNoDaySelected">
              {{ 'NOTIFICATION_SETTINGS_SAVE_FAILED' | fromTextKey }}
            </b-alert>
          </template>
        </template>
      </template>
    </div>
    <aula-modal
      ref="cancelModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.cancelModal.hide()"
      @okClicked="cancelModalOkClicked()"
    >
      {{ 'CONSENT_CANCEL_MODAL_TEXT1' | fromTextKey }}<br />
      {{ 'CONSENT_CANCEL_MODAL_TEXT2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="confirmationModal"
      header-text="WARNING_MODAL"
      ok-text="BUTTON_YES"
      cancel-text="CANCEL"
      @cancelClicked="$refs.confirmationModal.hide()"
      @okClicked="deleteNotificationsFromModule"
    >
      {{ 'NOTIFICATION_CLEAR_ALL_WARNING_1' | fromTextKey }}<br />
      {{ 'NOTIFICATION_CLEAR_ALL_WARNING_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { portalRoles } from '../../enums/portalRoles';
import srOnly from '../../directives/sr-only';
import { timepickerSettings } from '../../enums/timepickerSettings';
import { notificationProviderKeyEnum } from '../../enums/notificationProviderKeyEnum';
import AulaButton from '../../components/AulaButton';
import AulaTimepicker from '../../components/Timepicker';
import NotificationCheckbox from './NotificationCheckbox';
import NotificationEmailDay from './NotificationEmailDay';

export default {
  name: 'NotificationSettingBrowser',
  directives: {
    srOnly,
  },
  components: {
    AulaButton,
    AulaTimepicker,
    NotificationCheckbox,
    NotificationEmailDay,
  },
  inject: {
    getNotificationSettingModel: notificationProviderKeyEnum.NOTIFICATION_SETTING_MODEL,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    isViewMode: { type: Boolean, default: false },
    availableAreas: { type: Array, default: () => [] },
    profile: { type: Object, default: null },
    everyDay: { type: Boolean, default: null },
    showNoDaySelected: { type: Boolean, default: null },
  },
  data: function () {
    return {
      portalRoles,
      timepickerSettings,
    };
  },
  computed: {
    notificationSettingModel() {
      return this.getNotificationSettingModel();
    },
    emailYesAriaText() {
      const ariaTexts = [
        this.$options.filters.fromTextKey('NOTIFICATION_SETTINGS_YES'),
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_FOR_EMAIL'),
      ];
      return ariaTexts.join(' ');
    },
    emailNoAriaText() {
      const ariaTexts = [
        this.$options.filters.fromTextKey('NOTIFICATION_SETTINGS_NO'),
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_FOR_EMAIL'),
      ];
      return ariaTexts.join(' ');
    },
    emailYesSwitchedAriaText() {
      const ariaTexts = [
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_FOR_EMAIL'),
        this.selectedOnOffAriaText(this.email),
      ];
      return ariaTexts.join(' ');
    },
    emailNoSwitchedAriaText() {
      const ariaTexts = [
        this.$options.filters.fromTextKey('ARIA_NOTIFICATION_FOR_EMAIL'),
        this.selectedOnOffAriaText(!this.email),
      ];
      return ariaTexts.join(' ');
    },
    emailAlwaysAriaText() {
      const ariaTexts = [this.receiveEmailAriaText, this.$options.filters.fromTextKey('NOTIFICATION_SETTINGS_ALWAYS')];
      return ariaTexts.join(' ');
    },
    emailIntervalAriaText() {
      const ariaTexts = [
        this.receiveEmailAriaText,
        this.$options.filters.fromTextKey('NOTIFICATION_SETTINGS_INTERVAL'),
      ];
      return ariaTexts.join(' ');
    },
    receiveEmailAriaText() {
      return this.$options.filters.fromTextKey('ARIA_NOTIFICATION_RECEIVE_EMAIL');
    },
    emailAllDaysAriaText() {
      const ariaTexts = [
        this.receiveEmailAriaText,
        this.$options.filters.fromTextKey('NOTIFICATION_SETTINGS_EVERYDAY'),
      ];
      return ariaTexts.join(' ');
    },
  },
  mounted() {},
  methods: {
    confirmationModalOpen() {
      this.$refs.confirmationModal.show();
    },
    selectedOnOffAriaText(value) {
      return value
        ? this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_SELECTED_ON')
        : this.$options.filters.fromTextKey('ARIA_NOTIFICATION_SETTINGS_SELECTED_OFF');
    },
    cancelModalOkClicked() {
      this.updateViewMode(true);
      this.$refs.cancelModal.hide();
      this.$emit('resetTable');
    },
    deleteNotificationsFromModule() {
      this.$emit('deleteNotificationsFromModule');
      this.$refs.confirmationModal.hide();
    },
    initEditMode() {
      this.$emit('initEditMode');
    },
    saveChanges() {
      this.$emit('saveChanges');
    },
    updateViewMode(value) {
      this.$emit('updateViewMode', value);
    },
    updateEveryDay(value) {
      this.$emit('updateEveryDay', value);
    },
    updateModelDays() {
      this.$emit('updateModelDays');
    },
    updateAppDisabled(value) {
      this.notificationSettingModel.setAppDisabled(value);
    },
    updateCalendar(value) {
      this.notificationSettingModel.setCalendar(value);
    },
    updateEmail(value) {
      this.notificationSettingModel.setEmailDisabled(value);
    },
    updateGallery(value) {
      this.notificationSettingModel.setGallery(value);
    },
    updateWidget(widgetId, value) {
      this.notificationSettingModel.setWidgetSettingById(widgetId, value);
    },
    updateInstant(value) {
      this.notificationSettingModel.setInstant(value);
    },
    updateMessagesFromChildren(value) {
      this.notificationSettingModel.setMessagesFromChildren(value);
    },
    updateMessagesFromEmployees(value) {
      this.notificationSettingModel.setMessagesFromEmployees(value);
    },
    updateMessagesFromGuardians(value) {
      this.notificationSettingModel.setMessagesFromGuardians(value);
    },
    updateNotifyAssignedAsSubstituteTeacher(value) {
      this.notificationSettingModel.setNotifyAssignedAsSubstituteTeacher(value);
    },
    updateNotifyLessonNoteChanged(value) {
      this.notificationSettingModel.setNotifyLessonNoteChanged(value);
    },
    updatePickUpActivity(value) {
      this.notificationSettingModel.setPickUpActivity(value);
    },
    updatePost(value) {
      this.notificationSettingModel.setPost(value);
    },
    updateScheduledTime(value) {
      this.notificationSettingModel.setScheduledTime(value);
    },
    updateSelectedAreas(value) {
      this.notificationSettingModel.setSelectedAreas(value);
    },
    updateVacationRequest(value) {
      this.notificationSettingModel.setVacationRequest(value);
    },
    updateMonday(value) {
      this.notificationSettingModel.setMonday(value);
    },
    updateTuesday(value) {
      this.notificationSettingModel.setTuesday(value);
    },
    updateWednesday(value) {
      this.notificationSettingModel.setWednesday(value);
    },
    updateThursday(value) {
      this.notificationSettingModel.setThursday(value);
    },
    updateFriday(value) {
      this.notificationSettingModel.setFriday(value);
    },
    updateSaturday(value) {
      this.notificationSettingModel.setSaturday(value);
    },
    updateSunday(value) {
      this.notificationSettingModel.setSunday(value);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
@import '../../assets/scss/elements/notificationSetting';

footer {
  @include breakpoint-lg-down() {
    position: fixed !important;
    bottom: 80px;
    top: unset;
  }
}

.settings-label {
  display: block;
  &.large {
    margin-bottom: 15px;
  }
}

.red-border {
  .notification-settings-body-mobile & {
    display: block;
  }
}

.weekday-settings {
  margin-top: -8px;
}
.notification-timepicker {
  max-width: 320px;
}
</style>
