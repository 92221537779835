













import AulaResizableContainter from '../../libs/resizable-container/components/AulaResizableContainter.vue';
import { mapGetters } from 'vuex';
import { types } from '../../../src/store/types/types.js';

export default {
  name: 'Sidebar',
  components: { AulaResizableContainter },
  props: {
    defaultWidth: { type: String, default: '280px' },
  },
  emits: ['resized', 'hidden'],
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
    }),
  },
  methods: {
    emitResized(width) {
      this.$emit('resized', width);
    },
    emitHidden() {
      this.$emit('hidden');
    },
  },
};
