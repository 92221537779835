








import NotificationSettings from '../settings/NotificationSettings.vue';

export default {
  components: { NotificationSettings },
  methods: {
    saveChanges() {
      this.$refs.settings.saveChanges();
    },
  },
};
