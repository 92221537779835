interface DuplicateNamesCounter {
  getNonDuplicatedName(fileName: string): string;
  appendFileNameAndRemainExtension(originalFileName: string, appendingName: string): string;
}
class FileUtil {
  createDuplicateNamesCounter(): DuplicateNamesCounter {
    const duplicatedNamesCounter = {};
    return {
      getNonDuplicatedName(fileName: string): string {
        if (duplicatedNamesCounter[fileName]) {
          duplicatedNamesCounter[fileName] += 1;
          return this.appendFileNameAndRemainExtension(fileName, '_' + (duplicatedNamesCounter[fileName] - 1));
        }
        duplicatedNamesCounter[fileName] = 1;
        return fileName;
      },
      appendFileNameAndRemainExtension(originalFileName: string, appendingName: string): string {
        const parts = originalFileName.split('.');
        const baseName = parts.slice(0, -1).join('.');
        const extension = parts[parts.length - 1];
        if (baseName.length === 0) {
          // folder name
          return `${extension}${appendingName}`;
        }
        return `${baseName}${appendingName}.${extension}`;
      },
    };
  }
}

export const fileUtil = new FileUtil();
