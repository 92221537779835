<template>
  <div>
    <b-row v-if="!isMobile" class="mt-3">
      <b-col cols="6" xl="4" lg="5">
        <label>{{ 'PRESENCE_FILTERING_STATUS' | fromTextKey }}</label>
        <aula-search
          id="weekOverviewStatusFilter"
          popper-class="week-overview-filter-popper"
          :results="weekOverviewFilterOptions"
          :remote-search="false"
          placeholder-textkey="PRESENCE_FILTERING_ALL"
          @emitSelected="updateWeekOverviewOptions"
        />
      </b-col>
    </b-row>
    <div v-if="isMobile" class="mobile-statistic d-flex justify-content-between align-items-center position-relative">
      <b-btn-group>
        <b-btn
          variant="link"
          class="presence-change-week-button"
          :aria-label="'ARIA_LABEL_PRESENCE_PREVIOUS_DAY' | fromTextKey"
          @click="goToPrevDay"
        >
          <span class="icon-Aula_back-triangle" />
        </b-btn>
        <div class="d-flex align-items-center mx-3">
          <div class="chosen-week-label mr-2">
            {{ chosenDateLabel }}
          </div>
          <el-date-picker
            ref="chooseWeekDatepicker"
            v-model="chosenDate"
            :clearable="false"
            :picker-options="datePickerOptions"
            type="date"
            popper-class="presence-week-overview-popper"
            class="presence-week-picker"
            @focus="datePickerModifier()"
          />
          <b-btn
            variant="link"
            class="presence-choose-week-icon"
            :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_WEEK' | fromTextKey"
            @click="openDatePicker"
          >
            <i class="icon-Aula_down-arrow" />
          </b-btn>
        </div>
        <b-btn
          variant="link"
          class="presence-change-week-button"
          :aria-label="'ARIA_LABEL_PRESENCE_NEXT_DAY' | fromTextKey"
          @click="goToNextDay"
        >
          <span class="icon-Aula_forward-triangle" />
        </b-btn>
      </b-btn-group>
      <div
        v-if="weekOverviewData && weekOverviewData.presenceDays"
        tabindex="0"
        role="button"
        class="cursor-pointer"
        @keyup.enter="openPresenceDistribution(weekOverviewData.presenceDays[0].date)"
        @keyup.space="openPresenceDistribution(weekOverviewData.presenceDays[0].date)"
        @click="openPresenceDistribution(weekOverviewData.presenceDays[0].date)"
      >
        {{ getNumberOfPresenceChildrenLabel(weekOverviewData.presenceDays[0]) }}
        <i class="icon-Aula_forward-arrow mx-2" />
      </div>
    </div>
    <div v-if="!isMobile" class="d-flex align-items-center mb-sm-4 my-lg-4 position-relative">
      <div class="chosen-week-label">{{ 'PRESENCE_WEEK' | fromTextKey }} {{ chosenWeekNumber }}</div>
      <el-date-picker
        ref="chooseWeekDatepicker"
        v-model="chosenWeek"
        :clearable="false"
        :picker-options="datePickerOptions"
        type="week"
        popper-class="presence-week-overview-popper"
        class="presence-week-picker"
        @focus="datePickerModifier()"
      />
      <b-btn
        variant="link"
        class="presence-choose-week-icon"
        :aria-label="'ARIA_LABEL_PRESENCE_CHOOSE_WEEK' | fromTextKey"
        @click="openDatePicker"
      >
        <i class="icon-Aula_down-arrow" />
      </b-btn>
      <b-btn-group class="ml-2">
        <b-btn
          variant="link"
          class="presence-change-week-button"
          :aria-label="'ARIA_LABEL_PRESENCE_PREVIOUS_WEEK' | fromTextKey"
          @click="goToPrevWeek"
        >
          <span class="icon-Aula_back-triangle" />
        </b-btn>
        <b-btn
          variant="link"
          class="presence-change-week-button"
          :aria-label="'ARIA_LABEL_PRESENCE_NEXT_WEEK' | fromTextKey"
          @click="goToNextWeek"
        >
          <span class="icon-Aula_forward-triangle" />
        </b-btn>
      </b-btn-group>
      <div class="ml-2 text-uppercase">
        {{ chosenWeekDatesLabel }}
      </div>
      <b-btn
        variant="link"
        class="current-week-button d-flex px-2 mx-3"
        data-test="weekOverviewCurrentWeekBtn"
        @click="goToCurrentWeek"
      >
        {{ 'PRESENCE_CURRENT_WEEK' | fromTextKey }}
      </b-btn>
    </div>
    <div class="presence-week-overview">
      <div class="activity-container">
        <div id="weekOverviewList" class="activity-list">
          <div class="children-activities">
            <div v-if="!isMobile" class="activity-data header">
              <div class="user-icon child-icon-col header mr-0" />
              <div class="user-name child-name-col header">
                {{ 'ACTIVITY_CHILDREN' | fromTextKey }}
              </div>
              <div
                v-for="(dateData, index) in chosenWeekDates"
                :key="index"
                :class="{ 'today-highlight': checkDateIsToday(dateData.date) }"
                class="activity-weekday header"
              >
                {{ (index + 1) | dateNumberToName }}<br />
                {{ dateData.dateLabel }}
              </div>
            </div>
            <aula-spinner v-if="isLoading" />
            <div v-if="weekOverviewData" v-show="!isLoading">
              <div v-if="!isMobile" class="activity-data">
                <div class="cell user-icon empty-cell border-right-0" />
                <div class="cell user-name child-name-col empty-cell" />
                <template v-for="(presenceData, index) in weekOverviewData.presenceDays">
                  <div
                    v-if="isClosedDay(presenceData.date)"
                    :key="index"
                    class="cell closed-day-cell child-presence-number d-flex align-items-center"
                  >
                    <strong
                      >{{ 'PRESENCE_CLOSED_DAY_DAILY_OVERVIEW' | fromTextKey }}:
                      {{ closedDayName(presenceData.date) }}</strong
                    >
                  </div>
                  <div
                    v-else
                    :key="index"
                    tabindex="0"
                    role="button"
                    class="cell child-presence-number d-flex align-items-center"
                    @keyup.enter="openPresenceDistribution(presenceData.date)"
                    @keyup.space="openPresenceDistribution(presenceData.date)"
                    @click="openPresenceDistribution(presenceData.date)"
                  >
                    <strong>{{ getNumberOfPresenceChildrenLabel(presenceData) }}</strong>
                    <i class="icon-Aula_forward-arrow mx-2" />
                  </div>
                </template>
              </div>
              <div v-if="isClosedDay(chosenDate) && isMobile" class="cell closed-day-cell">
                <strong
                  >{{ 'PRESENCE_CLOSED_DAY_DAILY_OVERVIEW' | fromTextKey }}: {{ closedDayName(chosenDate) }}</strong
                >
              </div>
              <div
                v-for="childData in weekOverviewData.childActivities"
                :key="`child_${childData.child.id}`"
                class="activity-data vacation"
              >
                <div
                  :class="{
                    'not-present-child-icon': checkChildNotPresentForAllDays(childData),
                  }"
                  class="cell user-icon"
                >
                  <template v-if="childData.child.profilePicture">
                    <AulaImg class="user-img" :img-url="childData.child.profilePicture.url" />
                  </template>
                  <template v-else>
                    <div class="initial-name">
                      {{ childData.child.shortName }}
                    </div>
                  </template>
                </div>
                <div
                  :class="{
                    'grayed-out-cell-mobile':
                      (childData.activities[0].type === weekOverviewPresenceStates.VACATION ||
                        childData.activities[0].type === weekOverviewPresenceStates.SICK ||
                        isClosedDay(chosenDate)) &&
                      isMobile,
                  }"
                  class="cell user-name child-name-col"
                  @click="
                    isMobile
                      ? openEditingDrawer(childData.activities[0], childData.child, childData.presenceRegistrationId)
                      : null
                  "
                >
                  <strong>{{ childData.child.name }}</strong>
                  ({{ childData.child.metadata }})
                  <div
                    v-if="isMobile"
                    :class="{
                      'passed-activity': checkDateIsBeforeToday(childData.activities[0].date),
                    }"
                  >
                    <template v-if="childData.activities[0].type === weekOverviewPresenceStates.VACATION">
                      {{ 'PRESENCE_STATE_VACATION' | fromTextKey }}
                    </template>
                    <template v-else-if="childData.activities[0].type === weekOverviewPresenceStates.SICK">
                      {{ 'PRESENCE_STATE_SICK' | fromTextKey }}
                    </template>
                    <template
                      v-else-if="
                        childData.activities[0].type !== weekOverviewPresenceStates.NONE &&
                        (getPresenceTimestampLabel(childData.activities[0].presenceDetails.startTime, true) !== '' ||
                          getPresenceTimestampLabel(childData.activities[0].presenceDetails.endTime, false) !== '')
                      "
                    >
                      {{ getPresenceTimestampLabel(childData.activities[0].presenceDetails.startTime, true) }}
                      -
                      {{ getPresenceTimestampLabel(childData.activities[0].presenceDetails.endTime, false) }}
                    </template>
                  </div>
                </div>
                <template v-if="!isMobile">
                  <div
                    v-for="(activity, activityIndex) in childData.activities"
                    :key="`presence_${activityIndex}`"
                    :class="{
                      'grayed-out-cell':
                        activity.type === weekOverviewPresenceStates.VACATION ||
                        activity.type === weekOverviewPresenceStates.SICK ||
                        isClosedDay(activity.date),
                      'passed-activity': checkDateIsBeforeToday(activity.date),
                      'cursor-pointer clickable-cell':
                        checkDateIsToday(activity.date) ||
                        (!checkDateIsBeforeToday(activity.date) && canEditTemplateOnInstitution),
                    }"
                    class="cell week-activity-status"
                    :tabindex="checkDateIsBeforeToday(activity.date) || !canEditTemplateOnInstitution ? -1 : 0"
                    :title="getTitleText(activity)"
                    :role="
                      checkDateIsBeforeToday(activity.date) || !canEditTemplateOnInstitution ? undefined : 'button'
                    "
                    @click="openEditingDrawer(activity, childData.child, childData.presenceRegistrationId)"
                    @keyup.enter="openEditingDrawer(activity, childData.child, childData.presenceRegistrationId)"
                    @keyup.space="openEditingDrawer(activity, childData.child, childData.presenceRegistrationId)"
                  >
                    <div v-if="activity.type === weekOverviewPresenceStates.VACATION">
                      {{ 'PRESENCE_STATE_VACATION' | fromTextKey }}
                    </div>
                    <div v-else-if="activity.type === weekOverviewPresenceStates.SICK">
                      {{ 'PRESENCE_STATE_SICK' | fromTextKey }}
                    </div>
                    <div v-else-if="activity.type !== weekOverviewPresenceStates.NONE">
                      {{ getPresenceTimestampLabel(activity.presenceDetails.startTime, true) }}
                      <br />
                      <span
                        :class="{
                          'missing-checkout': activity.presenceDetails.endTime.tense === tenses.MISSING_CHECKOUT,
                        }"
                        >{{ getPresenceTimestampLabel(activity.presenceDetails.endTime, false) }}</span
                      >
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div
              v-if="departments.length > 0 && (!weekOverviewData || weekOverviewData.childActivities.length === 0)"
              class="mt-2 ml-2"
            >
              {{ 'PRESENCE_WARNING_NO_RESULTS_FOUND' | fromTextKey }}
            </div>
            <div v-if="departments.length === 0" class="px-2 px-lg-0">
              {{ 'PRESENCE_WARNING_NOT_MEMBER_OF_PRESENCE' | fromTextKey }}
            </div>
            <aula-spinner v-if="isLoadingMore" />
          </div>
        </div>
      </div>
    </div>
    <presence-distribution ref="presenceDistribution" />

    <update-child-presence-template-drawer ref="updateChildPresenceTemplateDrawer" @onClosed="getWeekOverview(false)" />
    <update-children-status-form ref="updateChildStatusDrawer" @onClosed="getWeekOverview(false)" />
  </div>
</template>
<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import $ from 'jquery';
import moment from 'moment-timezone';
import { weekOverviewPresenceStates } from '../../../shared/enums/weekOverviewPresenceStates';
import { weekOverviewPresenceOptions } from '../../../shared/enums/weekOverviewPresenceOptions';
import { isoWeekdayEnum } from '../../../shared/enums/isoWeekdayEnum';
import UpdateChildPresenceTemplateDrawer from './UpdateChildPresenceTemplateDrawer';
import UpdateChildrenStatusForm from './UpdateChildrenStatusForm';
import PresenceDistribution from './PresenceDistribution';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import { presenceOpenHours } from '../../../shared/enums/presenceOpenHours';
import AulaImg from '../../../shared/components/AulaImg';

export default {
  components: {
    AulaImg,
    UpdateChildrenStatusForm,
    UpdateChildPresenceTemplateDrawer,
    PresenceDistribution,
  },
  mixins: [requestCancelTokenMixin],
  data: function () {
    return {
      isLoading: false,
      isLoadingMore: false,
      pageSize: 20,
      chosenWeek: this.currentWorkDay(),
      chosenDate: this.currentWorkDay(),
      weekOverviewPresenceStates,
      weekOverviewFilterOptions: [],
      datePickerOptions: {
        firstDayOfWeek: isoWeekdayEnum.MONDAY,
        disabledDate: date => [isoWeekdayEnum.SATURDAY, isoWeekdayEnum.SUNDAY].includes(new moment(date).isoWeekday()),
      },
      tenses: {
        PAST: 'past',
        PRESENT: 'present',
        MISSING_CHECKOUT: 'missing-checkout',
        NOT_SPECIFIED: 'not-specified',
      },
      weekOverviewCancelTokenSource: null,
      presenceOpenHours: presenceOpenHours,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      moreWeekOverviewChildren: types.GET_HAS_MORE_WEEK_OVERVIEW,
      weekOverviewData: types.GET_WEEK_OVERVIEW_DATA,
      profile: types.GET_CURRENT_PROFILE,
      departments: types.GET_DEPARTMENTS,
      selectedDepartment: types.GET_SELECTED_DEPARTMENT,
      filterOptions: types.GET_FILTER_OPTIONS,
      institutionOpenHours: types.GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
    }),
    weekViewStartDate() {
      if (this.isMobile) {
        return moment(this.chosenDate).format('YYYY-MM-DD');
      } else {
        return moment(this.chosenWeek).isoWeekday(isoWeekdayEnum.MONDAY).format('YYYY-MM-DD');
      }
    },
    weekViewEndDate() {
      if (this.isMobile) {
        return moment(this.chosenDate).format('YYYY-MM-DD');
      } else {
        return moment(this.chosenWeek).isoWeekday(isoWeekdayEnum.FRIDAY).format('YYYY-MM-DD');
      }
    },
    canEditTemplateOnInstitution() {
      return this.hasPermissionOnInstitution(
        permissionEnum.EDIT_PRESENCE_TEMPLATES,
        this.selectedDepartment.institutionCode
      );
    },
    chosenWeekNumber() {
      return moment(this.chosenWeek).format('W');
    },
    chosenDateLabel() {
      const date = moment(this.chosenDate);
      const dateNumberToName = Vue.filter('dateNumberToName')(date.isoWeekday());
      return `${dateNumberToName.slice(0, 3)} D. ${date.format('DD MMM')}`;
    },
    chosenWeekDatesLabel() {
      const fromDate = moment(this.chosenWeek).isoWeekday(isoWeekdayEnum.MONDAY).format('DD');
      const fromMonth = moment(this.chosenWeek).isoWeekday(isoWeekdayEnum.MONDAY).format('MMMM');
      const toDate = moment(this.chosenWeek).isoWeekday(isoWeekdayEnum.FRIDAY).format('DD');
      const toMonth = moment(this.chosenWeek).isoWeekday(isoWeekdayEnum.FRIDAY).format('MMMM');
      return `${fromMonth} ${fromDate} - ${toMonth !== fromMonth ? toMonth : ''} ${toDate}`;
    },
    chosenWeekDates() {
      const weekDates = [];
      for (let i = isoWeekdayEnum.MONDAY; i <= isoWeekdayEnum.FRIDAY; i++) {
        const date = moment(this.chosenWeek).isoWeekday(i);
        weekDates.push({
          date,
          dateLabel: date.format('DD. MMMM'),
        });
      }
      return weekDates;
    },
  },
  methods: {
    ...mapMutations({
      updateFilterOptions: types.MUTATE_FILTER_OPTIONS,
      setSelectedRegistrations: types.MUTATE_SELECTED_REGISTRATION_TO_EDIT,
    }),
    ...mapActions({
      loadWeekOverview: types.LOAD_WEEK_OVERVIEW,
      loadOpenHoursByInstitution: types.LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD,
    }),
    getTitleText(activity) {
      if (!this.checkDateIsBeforeToday(activity.date) && this.canEditTemplateOnInstitution) {
        return Vue.filter('fromTextKey')('PRESENCE_WEEK_OVERVIEW_PRESS_TO_EDIT');
      }
      return undefined;
    },
    loadOpenHours() {
      this.loadOpenHoursByInstitution({
        institutionCodes: [this.selectedDepartment.institutionCode],
        startDate: this.weekViewStartDate,
        endDate: this.weekViewEndDate,
      });
    },
    isClosedDay(date) {
      if (this.institutionOpenHours) {
        const openHour = this.institutionOpenHours.openingHoursOverviewDto[0].openingHoursDto.find(
          openHours => moment(openHours.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')
        );

        if (openHour) {
          return openHour.type === presenceOpenHours.CLOSE_OPEN_HOURS;
        }
      }
    },
    closedDayName(date) {
      if (this.institutionOpenHours) {
        const openHour = this.institutionOpenHours.openingHoursOverviewDto[0].openingHoursDto.find(
          openHours => moment(openHours.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')
        );

        return openHour.name;
      }
    },
    goToNextWeek() {
      this.chosenWeek = moment(this.chosenWeek).add(1, 'w');
    },
    goToPrevWeek() {
      this.chosenWeek = moment(this.chosenWeek).subtract(1, 'w');
    },
    goToNextDay() {
      const addedNumber = moment(this.chosenDate).isoWeekday() === isoWeekdayEnum.FRIDAY ? 3 : 1;
      this.chosenDate = moment(this.chosenDate).add(addedNumber, 'd');
    },
    goToPrevDay() {
      const subtractedNumber = moment(this.chosenDate).isoWeekday() === isoWeekdayEnum.MONDAY ? 3 : 1;
      this.chosenDate = moment(this.chosenDate).subtract(subtractedNumber, 'd');
    },
    goToCurrentWeek() {
      this.chosenWeek = new Date();
    },
    checkChildNotPresentForAllDays(childData) {
      for (const activity of childData.activities) {
        if (activity.type === weekOverviewPresenceStates.PRESENT) {
          return false;
        }
      }
      return true;
    },
    getNumberOfPresenceChildrenLabel(presenceData) {
      const today = moment(new Date()).format('YYYY-MM-DD');
      const isBeforeToday = moment(presenceData.date).isBefore(today);
      let label;
      if (this.isMobile) {
        const textKey = isBeforeToday
          ? 'PRESENCE_WEEK_OVERVIEW_COME_CHILDREN_MOBILE'
          : 'PRESENCE_WEEK_OVERVIEW_COMING_CHILDREN_MOBILE';
        label = Vue.filter('fromTextKey')(textKey, {
          numberOfChildren: presenceData.numberOfChildren,
          totalNumberOfChildren: presenceData.totalNumberOfChildren,
        });
      } else {
        const textKey = isBeforeToday
          ? 'PRESENCE_WEEK_OVERVIEW_COME_CHILDREN'
          : 'PRESENCE_WEEK_OVERVIEW_COMING_CHILDREN';
        label = Vue.filter('fromTextKey')(textKey, {
          numberOfChildren: presenceData.numberOfChildren,
          totalNumberOfChildren: presenceData.totalNumberOfChildren,
        });
      }
      return label;
    },
    getPresenceTimestampLabel(time, isStartTime) {
      let label;
      if (time.tense === this.tenses.NOT_SPECIFIED) {
        label = '';
      } else if (time.tense !== this.tenses.MISSING_CHECKOUT) {
        let textKey;
        if (isStartTime) {
          textKey =
            time.tense === this.tenses.PRESENT
              ? 'PRESENCE_WEEK_OVERVIEW_CHECK_IN_FUTURE'
              : 'PRESENCE_WEEK_OVERVIEW_CHECK_IN_PAST';
        } else {
          textKey =
            time.tense === this.tenses.PRESENT
              ? 'PRESENCE_WEEK_OVERVIEW_CHECK_OUT_FUTURE'
              : 'PRESENCE_WEEK_OVERVIEW_CHECK_OUT_PAST';
        }

        const status = Vue.filter('fromTextKey')(textKey);
        const preposition = Vue.filter('fromTextKey')('PRESENCE_STATUS_PREPOSITION');

        if (this.isMobile) {
          label = `${status} ${preposition} ${time && time.timestamp ? time.timestamp : ''}`;
        } else {
          label = `${time && time.timestamp ? time.timestamp : ''} ${status}`;
        }
      } else {
        label = Vue.filter('fromTextKey')('PRESENCE_MISSING_CHECK_OUT_TIME');
      }

      return label;
    },
    checkDateIsToday(date) {
      return moment(date).isSame(new Date(), 'day');
    },
    checkDateIsBeforeToday(date) {
      return moment(date).isBefore(new Date(), 'day');
    },
    openDatePicker() {
      if (this.$refs.chooseWeekDatepicker) {
        if ($('.presence-week-overview-popper').is(':visible')) {
          this.$refs.chooseWeekDatepicker.blur();
        } else {
          this.$refs.chooseWeekDatepicker.handleFocus();
        }
      }
    },
    openPresenceDistribution(presenceDate) {
      this.$refs.presenceDistribution.show(presenceDate);
    },
    handleScroll() {
      if (this.moreWeekOverviewChildren && !this.isLoadingMore && !this.isLoading) {
        const scrollDiv = $('#weekOverviewList');
        if (scrollDiv.length > 0) {
          const scrollHeight = $(window).scrollTop() + $(window).height();
          const contentHeight = scrollDiv.offset().top + scrollDiv.height();
          if (scrollHeight >= contentHeight) {
            this.getWeekOverview(true);
          }
        }
      }
    },
    updateWeekOverviewOptions(selectedOptions) {
      const statusFilters = selectedOptions.map(option => option.value);
      this.updateFilterOptions({ statusFilters });
    },
    openEditingDrawer(activity, child, presenceRegistrationId) {
      const isBeforeToday = this.checkDateIsBeforeToday(activity.date);

      if (!isBeforeToday) {
        const isToday = this.checkDateIsToday(activity.date);

        if (isToday) {
          const presenceRegistration = {
            presenceRegistrationId,
            uniStudent: child,
          };
          this.setSelectedRegistrations({
            selectedRegistrations: [presenceRegistration],
          });
          this.$refs.updateChildStatusDrawer.show();
        } else if (this.canEditTemplateOnInstitution) {
          this.$refs.updateChildPresenceTemplateDrawer.show(child, activity.date);
        }
      }
    },
    initWeekOverviewOptions() {
      this.weekOverviewFilterOptions = [
        {
          value: weekOverviewPresenceOptions.PRESENT,
          label: Vue.filter('fromTextKey')('PRESENCE_STATE_PRESENT'),
          canSelect: true,
          options: [
            {
              value: weekOverviewPresenceOptions.PRESENT,
              label: Vue.filter('fromTextKey')('PRESENCE_STATE_PRESENT'),
              isHidden: true,
            },
          ],
        },
        {
          value: weekOverviewPresenceOptions.NOT_PRESENT,
          label: Vue.filter('fromTextKey')('PRESENCE_STATE_NOT_PRESENT'),
          canSelect: true,
          options: [
            {
              value: weekOverviewPresenceOptions.VACATION,
              label: Vue.filter('fromTextKey')('PRESENCE_STATE_VACATION'),
            },
            {
              value: weekOverviewPresenceOptions.SICK,
              label: Vue.filter('fromTextKey')('PRESENCE_STATE_SICK'),
            },
          ],
        },
      ];
    },
    getWeekOverview(loadMore) {
      this.cancelAxiosRequest(this.weekOverviewCancelTokenSource);
      this.createWeekOverviewCancelTokenSource();

      const statusFilters = this.filterOptions.statusFilters;
      const cancelToken = this.weekOverviewCancelTokenSource.token;
      const params = {
        startDate: this.weekViewStartDate,
        endDate: this.weekViewEndDate,
        statusFilters,
        limit: this.pageSize,
      };

      this.isLoading = !loadMore;
      this.isLoadingMore = loadMore;

      this.loadWeekOverview({ params, loadMore, cancelToken })
        .then(() => {
          this.isLoading = false;
          this.isLoadingMore = false;
          this.resetWeekOverviewTokenSource();
          this.loadOpenHours();
        })
        .catch(() => null);
    },
    initWeekOverviewByFilters() {
      if (this.filterOptions.departmentId) {
        this.getWeekOverview(false);
      }
    },
    createWeekOverviewCancelTokenSource() {
      this.weekOverviewCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetWeekOverviewTokenSource() {
      this.weekOverviewCancelTokenSource = null;
    },
    currentWorkDay() {
      let dayIncrement = 0;
      if (moment().isoWeekday() === isoWeekdayEnum.SATURDAY) {
        dayIncrement = 2;
      } else if (moment().isoWeekday() === isoWeekdayEnum.SUNDAY) {
        dayIncrement = 1;
      }
      return moment().add(dayIncrement, 'd').format('YYYY-MM-DD');
    },
  },
  watch: {
    chosenWeek() {
      this.getWeekOverview(false);
    },
    chosenDate() {
      this.getWeekOverview(false);
    },
    isMobile() {
      this.getWeekOverview(false);
    },
    filterOptions: {
      handler() {
        this.initWeekOverviewByFilters();
      },
      deep: true,
    },
  },
  mounted() {
    this.initWeekOverviewOptions();
    this.initWeekOverviewByFilters();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    this.updateFilterOptions({ statusFilters: null });
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables';
@import '../../../shared/assets/scss/core/breakpoints';
@import '../../../shared/assets/scss/components/presence/presence-employee';
.activity-data {
  &.vacation {
    .user-name {
      @include breakpoint-lg-down() {
        padding: 10px !important;
      }
    }
  }
  .user-icon {
    @include breakpoint-lg-down() {
      width: unset !important;
    }
  }
}
.icon-Aula_forward-arrow {
  font-size: 0.8rem;
  line-height: unset;
}
</style>
