<template>
  <div class="system-message" :data-expanded="isExpanded">
    <div class="system-message-header">
      <Icon :name="iconClassEnum.ARROW_FORWARD" />
      {{ $t('messages.system_message_forwarded_thread') }}
    </div>
    <p>
      {{ $t('messages.system_message_description', { date: sendDateTime, recipients: recipientList }) }}
      <AulaButton v-if="originalRecipients.length > 1" variant="link" class="px-1" @click="toggleRecipientList">
        <Icon :name="iconClassEnum.DOWN_ARROW" class="expand-icon" />
      </AulaButton>
    </p>
  </div>
</template>

<script>
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import AulaButton from '../../../shared/components/AulaButton.vue';

export default {
  components: { AulaButton, Icon },
  props: {
    message: { type: Object, default: () => {} },
    originalDateTime: { type: String, default: '' },
  },
  data() {
    return {
      showListOfParticipants: false,
      isExpanded: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    originalRecipients() {
      return this.message.originalRecipients || [];
    },
    recipientList() {
      let recipients = this.message.originalRecipients || [];
      let otherRecipientText = '';
      if (!this.isExpanded && recipients.length > 1) {
        const hiddenRecipientCount = recipients.length - 1;
        recipients = recipients.slice(0, 1);
        otherRecipientText = ` ${this.$t('label.and')} ${this.$tc('label.other', hiddenRecipientCount)}`;
      }
      return (
        recipients.map(recipient => `${recipient.fullName} (${recipient.metadata})`).join(', ') + otherRecipientText
      );
    },
    sendDateTime() {
      return DateTimeUtil.formatDate(this.message.sendDateTime, dateFormatEnum.SHORT_DATE);
    },
  },
  methods: {
    toggleRecipientList() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.system-message {
  padding: 0 64px 20px 64px;
  border-bottom: 1px solid var(--color-read-message);
  &[data-expanded] {
    .expand-icon {
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  .system-message-header {
    font-weight: bold;
  }
  .message-forwarded-time {
    font-size: 15px;
  }
}

.forward {
  border-bottom: 1px solid var(--color-read-message);
}
</style>
