<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    ref="subscription"
    v-button
    v-pan:horizontal="handlePanning"
    v-swipe:left="handleSwipingLeft"
    v-press="handlePressed"
    class="subscription"
    :class="subscriptionClasses"
    :data-dragging="isDragging"
    @click="onSubscriptionClicked(subscription, subscriptionQuery)"
    @blur="handleBlur"
  >
    <ThreadStandardItem
      v-if="selectedThreadView === threadViewEnum.STANDARD"
      class="mb-1"
      :thread="threadItemProps"
      :selecting="isMultipleItemsSelected"
      @delete="deleteBtnClicked"
      @moveToFolder="changeMoveToFolderVisibility(true)"
      @toggleImportant="markSubscriptionToggle"
      @toggleReadStatus="onChangeSubscriptionStatus"
      @select="onCheckBoxClicked"
      @toggleMuteStatus="setThreadMutedStatus"
    />
    <ThreadCompactItem
      v-if="selectedThreadView === threadViewEnum.COMPACT"
      v-bind="threadItemProps"
      @onCheckBoxClicked="onCheckBoxClicked"
      @onMarkImportantClicked="markSubscriptionToggle"
      @onMarkAsReadClicked="onChangeSubscriptionStatus"
      @onMoveToFolderClicked="changeMoveToFolderVisibility(true)"
      @onDeleteClicked="deleteBtnClicked"
    />
    <SubscriptionTouchActions
      :thread="threadItemProps"
      aria-hidden="true"
      @delete="deleteBtnClicked"
      @moveToFolder="changeMoveToFolderVisibility(true)"
      @toggleImportant="markSubscriptionToggle"
      @toggleReadStatus="onChangeSubscriptionStatus"
      @toggleMuteStatus="setThreadMutedStatus"
      @forward="forwardThread"
    />
    <move-to-folder-modal
      v-if="folderButtonClicked"
      :subscription-ids="[subscription.subscriptionId]"
      :chosen-folder-and-mail-owner="chosenFolderAndMailOwner"
      :folders="!!chosenFolderAndMailOwner.mailOwnerId ? commonInboxFolders : folders"
      @hide="changeMoveToFolderVisibility"
    />
    <aula-modal
      ref="deleteModal"
      header-text="MESSAGE_DELETE_MORE_THREADS_HEADER"
      ok-text="MESSAGE_DELETE_OK"
      data-size="small"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="onDeleteThreadClicked"
    >
      <span v-if="isPrimary" v-html="$t('messages.warning_deletion_of_primary_thread')" />
      <template v-else-if="subscription.subscriptionType === subscriptionTypes.BUNDLE">
        {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_1' | fromTextKey }} <br />
        {{ deletedThreadWarningMessage }} <br />
        {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_2' | fromTextKey }}
      </template>
      <template v-else>
        {{ 'MESSAGE_DELETE_THREAD_INFO_1' | fromTextKey }}<br />
        {{ deletedThreadWarningMessage }} <br />
        {{ 'MESSAGE_DELETE_THREAD_INFO_3' | fromTextKey }}
      </template>
    </aula-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import MessageMoveToFolderModal from './MessageMoveToFolderModal.vue';
import throttle from 'lodash/throttle';
import escapeRegExp from 'lodash/escapeRegExp';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { subscriptionStatus } from '../../../shared/enums/subscriptionStatus';
import { subscriptionTypes } from '../../../shared/enums/subscriptionTypes';
import messageSubscriptionMixin from '../../../shared/mixins/messageSubscriptionMixin';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageUtil } from '../../../shared/utils/messageUtil';
import { threadViewEnum } from '../../../shared/enums/threadViewEnum';
import ThreadCompactItem from '../../../shared/PageFragments/ThreadViews/ThreadCompactItem';
import Button from '../../../shared/directives/button';
import ThreadStandardItem from '../../../shared/PageFragments/ThreadViews/ThreadStandardItem.vue';
import SubscriptionTouchActions from './SubscriptionTouchActions.vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { MessageFormActionEnum } from './enums/messageFormActionEnum';
import { messagingService } from '../../../shared/services/api/messaging.service';

const TOUCH_ACTION_CONTAINER_SIZE = 150;

export default {
  mixins: [messageSubscriptionMixin],
  inject: {
    onSubscriptionClicked: messageProviderKeyEnum.ON_SUBSCRIPTION_CLICKED,
    getSelectedThreadView: messageProviderKeyEnum.SELECTED_THREAD_VIEW,
    getCanMoveToDeletedMessageFolder: messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER,
    setMessageFormData: messageProviderKeyEnum.SET_MESSAGE_FORM_DATA,
    setSelectedMessage: messageProviderKeyEnum.SET_SELECTED_MESSAGE,
  },
  props: {
    subscription: Object,
    isBundleOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['deleted'],
  data: function () {
    return {
      moment: moment,
      institutionRole: institutionRole,
      portalRoles: portalRoles,
      messageOwnerTypes: messageOwnerTypes,
      showMoveToFolder: false,
      folderButtonClicked: false,
      isRead: false,
      isDragging: false,
      isVisibleTouchActions: false,
      offsetDistance: 0,
      threadSubjectIdPrefix: 'thread-last-text-',
      participantNamesPrefix: 'participant-names-',
      cardBlockPrefix: 'card-block-',
      threadSubjectPrefix: 'thread-subject-',
      threadViewEnum,
      subscriptionTypes,
    };
  },
  computed: {
    ...mapGetters({
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
      folders: types.MESSAGES_GET_FOLDERS,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      hasPermission: types.HAS_PERMISSION,
      institutions: types.GET_INSTITUTIONS,
      notifications: types.GET_NOTIFICATIONS,
      profile: types.GET_CURRENT_PROFILE,
      inSearch: types.MESSAGES_GET_IN_SEARCH,
      messageSearchQuery: types.MESSAGES_GET_ACTIVE_SEARCH_QUERY,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      selectedSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      selectedThreadIds: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
    }),
    isPrimary() {
      return this.subscriptions.some(
        subscription => subscription.primarySubscriptionId === this.subscription.subscriptionId
      );
    },
    isSelected() {
      return this.subscription.checked;
    },
    isCreator() {
      if (this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
        return this.profile.profileId === this.subscription.creator?.mailBoxOwner.profileId;
      }
      return (
        this.chosenFolderAndMailOwner.mailOwnerType === this.subscription.creator?.mailBoxOwner.mailBoxOwnerType &&
        this.chosenFolderAndMailOwner.mailOwnerId === this.subscription.creator?.mailBoxOwner.id
      );
    },
    canMoveToDeletedMessageFolder() {
      return this.getCanMoveToDeletedMessageFolder();
    },
    deletedThreadWarningMessage() {
      let message =
        this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
          ? this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2')
          : this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2');

      if (this.chosenFolderAndMailOwner?.mailOwnerId) {
        message =
          this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
            ? this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2_COMMON')
            : this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2_COMMON');
      }
      if (this.canMoveToDeletedMessageFolder && !this.subscription.draft) {
        message +=
          this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
            ? ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_FROM_NORMAL_FOLDER')}`
            : ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_FROM_NORMAL_FOLDER')}`;
      }
      return message;
    },
    threadItemProps() {
      let message = this.latestMessageText;
      let relatedProfiles = this.subscription.regardingChildren || [];
      if (this.profile.role === portalRoles.EMPLOYEE && this.institutions.length > 1) {
        relatedProfiles = this.institutions.filter(
          institution => institution.institutionCode === this.subscription.institutionCode
        );
      }
      if (this.subscription.leaveTime) {
        message = this.$options.filters.fromTextKey('MESSAGE_LEAVE_CONVERSATION');
      }
      if (this.subscription.draft) {
        message = this.subscription.text;
      }
      if (this.requiresStepUp) {
        message = this.$options.filters.fromTextKey('MESSAGE_MESSAGE_SENSITIVE');
      }

      return {
        date: this.latestMessageDatetime,
        title: this.subscription.subject,
        recipients: this.allRecipients,
        firstRecipient: this.firstDisplayedParticipantText,
        extraRecipientsCount: this.extraRecipientsCount,
        message,
        selected: this.isSelected,
        marked: this.subscriptionMarked,
        read: this.subscriptionRead,
        searchQuery: this.messageSearchQueryByWords,
        numberOfBundleItems: this.numberOfBundleItems,
        relatedProfiles,
        active: this.activeThread,
        draft: this.subscription.draft,
        muted: this.subscriptionMuted,
        sensitive: this.isSubscriptionSensitive,
        bundleOpen: this.isBundleOpen,
        type: this.subscription.subscriptionType,
        creator: this.subscription.creator,
        requiresStepUp: this.requiresStepUp,
        isCreator: this.isCreator,
        hasLeft: this.subscription.leaveTime != null,
        hasPrimarySubscription: this.subscription.primarySubscriptionId != null,
        threadType: this.subscription.threadEntityLinkDto?.threadType ?? this.subscription.thread?.threadType,
      };
    },
    subscriptionClasses() {
      return {
        selected: this.subscription.checked,
        'multiple-selected': this.isMultipleItemsSelected,
        'in-search': this.inSearch,
        bundle: this.subscription.subscriptionType === subscriptionTypes.BUNDLE,
        'bundle-open': this.isBundleOpen,
        draft: this.draft,
        unread: !this.subscriptionRead,
        'active-thread': this.activeThread,
        sensitive: this.subscription.sensitive,
        'has-draft': this.subscription.draft,
        muted: this.subscriptionMuted,
      };
    },
    selectedThreadView() {
      return this.getSelectedThreadView();
    },
    firstDisplayedParticipantText() {
      const recipient = messageUtil.getFirstParticipant(
        this.allRecipients,
        this.chosenFolderAndMailOwner,
        this.profile
      );
      if (recipient) {
        return this.$options.filters.displayProfileNameWithMetadata(recipient);
      }
      return '';
    },
    extraRecipientsCount() {
      if (this.draft) {
        const limit = this.selectedThreadView === threadViewEnum.COMPACT ? 1 : 3;
        return Math.max(this.allRecipients.length - limit, 0);
      }
      if (this.selectedThreadView === threadViewEnum.COMPACT) {
        return Math.max(this.subscription.extraRecipientsCount + this.allRecipients.length - 1, 0);
      }
      return this.subscription.extraRecipientsCount;
    },
    isMultipleItemsSelected() {
      return this.selectedThreadIds.length + this.selectedBundleIds.length >= 1;
    },
    numberOfBundleItems() {
      if (this.subscription.subscriptionType !== subscriptionTypes.BUNDLE) {
        return null;
      }

      const bundleData = this.threadBundles.find(bundle => bundle.bundleId === this.subscription.subscriptionId);
      if (bundleData) {
        return bundleData.numberOfBundleItems;
      }

      return this.subscription.numberOfBundleItems;
    },
    allRecipients() {
      const recipients = this.subscription.recipients;
      const bccRecipients = this.subscription.bccRecipients || [];
      const extraRecipients = this.subscription.extraRecipients || [];
      return [...new Set([...recipients, ...bccRecipients, ...extraRecipients])];
    },
    isAllBundleItemsSelected() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE) {
        const threadIdsInBundle = this.threadsInBundle.map(thread => thread.id);
        return threadIdsInBundle.every(id => this.selectedThreadIds.includes(id)) && threadIdsInBundle.length > 0;
      }
      return false;
    },
    threadsInBundle() {
      const bundle = this.threadBundles.find(bundle => bundle.bundleId === this.subscription.subscriptionId);
      if (bundle) {
        return bundle.threads.filter(thread => !thread.deleted);
      }
      return [];
    },
    messageSearchQueryByWords() {
      return escapeRegExp(this.messageSearchQuery)
        .split(' ')
        .map(word => {
          if (word) {
            // Match æøå characters to align with the backend result
            word = word.replace(/(æ|ø|å|oe|o|aa|ae)/gi, match => {
              const uppercaseMatch = match.toUpperCase();
              let replacedMatch = match;
              switch (uppercaseMatch) {
                case 'AA':
                case 'Å':
                  replacedMatch = '(aa|å)';
                  break;
                case 'AE':
                case 'Æ':
                  replacedMatch = '(ae|æ)';
                  break;
                case 'O':
                case 'OE':
                case 'Ø':
                  replacedMatch = '(o|oe|ø)';
                  break;
              }
              if (match === uppercaseMatch) {
                return replacedMatch.toUpperCase();
              } else {
                return replacedMatch;
              }
            });
            // Word boundary doesn't work for unicode characters https://stackoverflow.com/posts/10590516/revisions
            return new RegExp(`(?:^|\\s)${word}`, 'gi');
          }
          return word;
        })
        .filter(Boolean);
    },
    activeThread() {
      return (
        this.$route.params.id == this.subscription.id && this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
      );
    },
    latestMessageText() {
      return this.$options.filters.stripHTML(this.subscriptionLatestMessage?.text?.html || '');
    },
    draft() {
      const draft = String(this.subscription.id);
      return draft.match(/^DRAFT/);
    },
    subscriptionRead() {
      if (this.subscription.draft) {
        return true;
      }
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.every(thread => thread.read === true);
      }
      return this.subscription.read;
    },
    subscriptionMarked() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.some(thread => thread.marked === true);
      }
      return this.subscription.marked;
    },
    subscriptionMuted() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.some(thread => thread.muted === true);
      }
      return this.subscription.muted;
    },
    subscriptionLatestMessage() {
      return this.subscription.latestMessage;
    },
    latestMessageDatetime() {
      return this.subscriptionLatestMessage?.sendDateTime;
    },
    isSubscriptionSensitive() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.some(thread => thread.sensitive);
      }
      return this.subscription.sensitive;
    },
    requiresStepUp() {
      return this.subscription.sensitive && this.isSteppedUp === false;
    },
    subscriptionQuery() {
      let query = {};
      if (this.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX) {
        query = {
          folderId: this.chosenFolderAndMailOwner.folderId,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
          mailBoxOwnerType: messageOwnerTypes.COMMON_INBOX,
        };
      } else if (this.profile.role === portalRoles.OTP) {
        if (!this.subscription.draft) {
          query = {
            otpInboxId: this.subscription.mailBoxOwner.id,
          };
        }
      }
      return query;
    },
  },
  methods: {
    ...mapActions({
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      deleteSubscription: types.DELETE_SUBSCRIPTIONS,
      switchMarkMessages: types.SWITCH_MARK_SUBSCRIPTIONS,
      resetSelectedSubscription: types.RESET_SELECT_SUBSCRIPTION,
      updateSubscriptionStatus: types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      moveThreadsToFolder: types.MOVE_THREADS_TO_FOLDER,
      leaveThread: types.MESSAGES_LEAVE_THREADS,
    }),
    ...mapMutations({
      mutateDeleteDraftThread: types.MUTATE_DELETE_DRAFT_THREAD,
      deleteSubscriptionById: types.MUTATE_DELETE_SUBSCRIPTION_BY_ID,
      updateSubscriptionCheckedStatus: types.MUTATE_SUBSCRIPTIONS_CHECKED_STATUS,
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
      mutateDeleteSubscription: types.MUTATE_DELETE_SUBSCRIPTIONS,
      mutateSubscriptionMutedState: types.MUTATE_MESSAGES_MUTE,
    }),
    forwardThread() {
      if (!this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        return;
      }
      const threadId = this.subscription.id;
      this.setSelectedMessage(null);
      this.setMessageFormData({
        institutionCode: this.subscription.institutionCode,
        threadId,
        commonInboxId: this.commonInboxId,
        otpInboxId: this.otpInboxId,
        action: MessageFormActionEnum.FORWARD,
        subject: this.subscription.subject,
      });
      this.$router.push({
        name: 'newMessage',
      });
    },
    handleBlur() {
      this.$refs.subscription.style.setProperty('--drag-distance', '0');
      this.isDragging = false;
      this.isVisibleTouchActions = false;
      this.offsetDistance = 0;
    },
    handleSwipingLeft() {
      this.isVisibleTouchActions = true;
      this.$refs.subscription.style.setProperty('--drag-distance', `-${TOUCH_ACTION_CONTAINER_SIZE}px`);
      this.offsetDistance = TOUCH_ACTION_CONTAINER_SIZE;
    },
    handlePanning(event) {
      if (event.isFinal) {
        this.handlePanningEnd();
        return;
      }

      const offsetLeft = Math.min(0, event.deltaX - this.offsetDistance);
      const dragDistance = `${offsetLeft}px`;
      this.$refs.subscription.style.setProperty('--drag-distance', dragDistance);
      this.$refs.subscription.focus();
      this.isVisibleTouchActions = Math.abs(offsetLeft) > TOUCH_ACTION_CONTAINER_SIZE;
      this.isDragging = true;
    },
    handlePanningEnd() {
      const dragDistance = this.isVisibleTouchActions ? `${-TOUCH_ACTION_CONTAINER_SIZE}px` : '0';
      this.$refs.subscription.style.setProperty('--drag-distance', dragDistance);
      this.offsetDistance = this.isVisibleTouchActions ? TOUCH_ACTION_CONTAINER_SIZE : 0;
    },
    handlePressed() {
      this.onCheckBoxClicked(true);
    },
    async setThreadMutedStatus() {
      const payload = {
        muted: !this.subscriptionMuted,
        subscriptionIds: [this.subscription.subscriptionId],
        commonInboxId:
          this.subscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
            ? this.subscription.mailBoxOwner.id
            : null,
        otpInboxId:
          this.subscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
            ? this.subscription.mailBoxOwner.id
            : null,
      };
      await messagingService.setThreadsMuted(payload);

      const toastMsg = payload.muted ? 'SUCCESS_TOAST_MESSAGES_MUTED' : 'SUCCESS_TOAST_MESSAGES_UNMUTED';
      this.setSuccessMessage(toastMsg);

      this.mutateSubscriptionMutedState({ isMuted: payload.muted, subscriptionId: this.subscription.subscriptionId });
    },
    changeMoveToFolderVisibility(value) {
      this.showMoveToFolder = value;
      this.folderButtonClicked = value;
    },
    async moveToDeletedFolder() {
      const leaveThreadResponse = await this.leaveThread({
        subscriptionIds: [this.subscription.subscriptionId],
      });
      const moveToFolderResponse = await this.moveThreadsToFolder({
        subscriptionIds: [this.subscription.subscriptionId],
        folderId: this.deletedFolderId,
      });
      this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER');
      return Promise.allSettled([leaveThreadResponse, moveToFolderResponse]);
    },
    deleteThread() {
      this.setOtpMailOwner();
      if (this.draft) {
        this.mutateDeleteDraftThread({
          id: this.profile.id,
          key: this.subscription.id,
        });
        return Promise.resolve();
      }

      if (this.canMoveToDeletedMessageFolder) {
        return this.moveToDeletedFolder();
      }

      return this.deleteSubscription({
        subscriptionIds:
          this.subscription.subscriptionType === subscriptionTypes.BUNDLE ? [this.subscription.subscriptionId] : [],
        threadIds: this.subscription.subscriptionType !== subscriptionTypes.BUNDLE ? [this.subscription.id] : [],
      });
    },
    setOtpMailOwner() {
      if (this.profile.role === portalRoles.OTP) {
        this.chosenFolderAndMailOwner.mailOwnerId = this.subscription.mailBoxOwner.id;
        this.chosenFolderAndMailOwner.mailOwnerType = this.subscription.mailBoxOwner.mailBoxOwnerType;
      }
    },
    onDeleteThreadClicked() {
      const shouldRefreshThreadView = this.isPrimary;
      this.$refs.deleteModal.hide();

      this.deleteThread().then(() => {
        this.$emit('deleted', this.subscription);
        this.loadNotifications({
          activeChildrenIds: this.activeChildren,
          activeInstitutionCodes: this.activeInstitutions,
        });

        if (this.$route.params.id === this.subscription.id) {
          this.resetSelectedSubscription();
          this.$router.push({ name: 'messages' });
        }
        if (shouldRefreshThreadView) {
          this.updateThreadViewAction({
            folderId: this.chosenFolderAndMailOwner.folderId,
            filterType: this.chosenFolderAndMailOwner.filter,
            mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
            sort: this.chosenFolderAndMailOwner.sort,
            order: this.chosenFolderAndMailOwner.order,
          });
        }
        this.mutateDeleteSubscription({
          threadIds: this.subscription.subscriptionType !== subscriptionTypes.BUNDLE ? [this.subscription.id] : [],
          subscriptionIds:
            this.subscription.subscriptionType === subscriptionTypes.BUNDLE ? [this.subscription.subscriptionId] : [],
          id: this.profile.id,
        });
      });
    },
    markSubscriptionToggle() {
      this.switchMarkMessages({
        subscriptionIds: [this.subscription.subscriptionId],
        isMarked: !this.subscriptionMarked,
      });
    },
    async onChangeSubscriptionStatus() {
      await this.updateSubscriptionStatus({
        subscriptionIds: [this.subscription.subscriptionId],
        status: this.subscriptionRead ? subscriptionStatus.UNREAD : subscriptionStatus.READ,
      });

      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    deleteBtnClicked() {
      this.$refs.deleteModal.show();
    },
    /**
     * This method below addresses a bug in Chrome to do with floats not being rendered until the float is unchecked and recheked though devtools
     * See: https://www.reddit.com/r/web_design/comments/2c2xmp/when_a_bug_in_your_site_might_actually_a_bug_in/
     **/
    setDraftPrefixFloat: throttle(function () {
      const draftLabel = this.$el.getElementsByClassName('subscription-draft-label-prefix')[0];
      if (draftLabel) {
        draftLabel.style.cssFloat = 'none';
        setTimeout(() => {
          draftLabel.style.cssFloat = 'left';
        }, 100);
      }
    }, 200),
  },
  watch: {
    isAllBundleItemsSelected(value) {
      this.updateSubscriptionCheckedStatus({
        subscriptionId: this.subscription.subscriptionId,
        checked: value,
      });
      this.updateBundleIdToStore(value);
    },
    numberOfBundleItems(value) {
      if (value === 0) {
        this.deleteSubscriptionById({ subscriptionId: this.subscription.subscriptionId });
      }
    },
  },
  created: function () {
    window.addEventListener('resize', this.setDraftPrefixFloat);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.setDraftPrefixFloat);
  },
  components: {
    SubscriptionTouchActions,
    ThreadStandardItem,
    ThreadCompactItem,
    'move-to-folder-modal': MessageMoveToFolderModal,
  },
  directives: {
    Button,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/messages/_subscriptions.scss';

.subscription {
  --translateX: var(--drag-distance, 0);
  --touch-actions-width: calc(var(--drag-distance, 0) * -1);
  position: relative;
  @include breakpoint-lg-down() {
    transition: transform 150ms linear;
    transform: none;
  }

  &[data-dragging] {
    transform: translateX(var(--translateX));
    box-shadow: none;
  }
  &:not([data-dragging]) .subscription-touch-actions {
    width: 150px;
  }
  .subscription-touch-actions {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: var(--touch-actions-width);
    transition: width 150ms linear;
  }
}
</style>
