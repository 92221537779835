






















































































import { types } from '../../store/types/types.js';
import { mapGetters } from 'vuex';
import { portalRoles } from '../../../shared/enums/portalRoles';
import OnboardingUpdateInstProfile from './OnboardingUpdateInstProfile.vue';
import ContactInformationPrimaryInstitution from '../contacts/ContactInformationPrimaryInstitution.vue';
import { PropType } from 'vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { ProfileDetailedModel } from '../../../shared/models/profileDetailed.model';

export default {
  components: {
    ContactInformationPrimaryInstitution,
    OnboardingUpdateInstProfile,
  },
  props: {
    relatedProfiles: { type: Array as PropType<InstitutionProfileModel[]>, default: () => [] },
    profileMasterData: { type: Object as PropType<ProfileDetailedModel>, default: null },
  },
  emits: ['emitAvatarUpload'],
  data: function () {
    return {
      portalRoles: portalRoles,
      primaryInstitutionProfileId: null,
      initPrimaryInstitutionProfileId: null,
      holderPrimaryInstitutionProfileId: null,
      isChangedPrimaryInstProfile: false,
      disableButton: {},
      aulaContactInfo: null,
      institutionProfiles: [],
    };
  },
  computed: {
    ...mapGetters({
      userProfiles: types.GET_PROFILES_BY_LOGIN,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
    }),
    roleLabel() {
      if (this.profile.role === portalRoles.EMPLOYEE) {
        return this.$options.filters.fromTextKey('INSTITUTION_ROLE_EMPLOYEE');
      }
      if (this.profile.role === portalRoles.GUARDIAN) {
        return this.$options.filters.fromTextKey('INSTITUTION_ROLE_GUARDIAN');
      }
      return '';
    },
    canChangePrimaryInstitution() {
      const isRoleAllowed = [portalRoles.EMPLOYEE, portalRoles.GUARDIAN].includes(this.profile.role);
      return isRoleAllowed && this.institutions.length > 1;
    },
  },
  mounted() {
    this.institutionProfiles = this.getInstitutionProfiles();
  },
  methods: {
    handleUploadAvatar() {
      this.$emit('emitAvatarUpload');
    },
    getCurrentProfileMasterData(instProfileId) {
      return (
        this.profileMasterData.institutionProfiles.find(ip => ip.id === instProfileId) ??
        this.profileMasterData.institutionProfiles.flatMap(ip => ip.relations).find(ip => ip.id === instProfileId)
      );
    },
    getInstitutionProfiles() {
      const userProfiles = this.userProfiles;
      const activePortalRole = this.profile.role;
      const profile = userProfiles.find(p => p.portalRole === activePortalRole);
      if (!profile) {
        return [];
      }

      if (profile.institutionProfiles.length > 0) {
        for (const index in profile.institutionProfiles) {
          this.disableButton[profile.institutionProfiles[index].id] = false;
          if (profile.institutionProfiles[index].newInstitutionProfile) {
            profile.institutionProfiles[index].isEdit = true;
          }
          if (profile.institutionProfiles[index].isPrimary) {
            if (this.primaryInstitutionProfileId == null) {
              this.primaryInstitutionProfileId = profile.institutionProfiles[index].id;
            }
          } else {
            this.holderPrimaryInstitutionProfileId = profile.institutionProfiles[index].id;
          }
        }
      }
      if (profile.children != null && profile.children.length > 0) {
        for (const child of profile.children) {
          this.disableButton[child.institutionProfile.id] = false;
        }
      }

      if (this.primaryInstitutionProfileId != null) {
        if (this.initPrimaryInstitutionProfileId == null) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.initPrimaryInstitutionProfileId = this.primaryInstitutionProfileId;
        }
      } else if (this.holderPrimaryInstitutionProfileId != null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.primaryInstitutionProfileId = this.holderPrimaryInstitutionProfileId;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.initPrimaryInstitutionProfileId = this.primaryInstitutionProfileId;
      }
      return [profile];
    },
    updateContactInfo() {
      const updateProfiles = [];

      for (const profile of this.userProfiles) {
        if (profile.portalRole === portalRoles.OTP) {
          continue;
        }

        for (const instProfile of profile.institutionProfiles) {
          if (instProfile.isEdit) {
            updateProfiles.push({
              profileId: profile.profileId,
              institutionProfileId: instProfile.id,
              homePhoneNumber: instProfile.homePhoneNumber,
              mobilePhoneNumber: instProfile.mobilePhoneNumber,
              workPhoneNumber: instProfile.workPhoneNumber,
              email: instProfile.email,
            });
          }
        }
      }

      for (const child of this.relatedProfiles) {
        if (child.currentUserCanEditContactInformation) {
          updateProfiles.push({
            profileId: child.profileId,
            institutionProfileId: child.id,
            homePhoneNumber: child.homePhoneNumber,
            mobilePhoneNumber: child.mobilePhoneNumber,
            email: child.email,
          });
        }
      }

      let primaryInstitutionProfileId = null;
      if ([portalRoles.EMPLOYEE, portalRoles.GUARDIAN].includes(this.profile.role)) {
        primaryInstitutionProfileId = this.primaryInstitutionProfileId;
      }
      return {
        institutionProfilesMasterData: updateProfiles,
        primaryInstitutionProfileId,
        isChangedPrimaryInstProfile: this.initPrimaryInstitutionProfileId != this.primaryInstitutionProfileId,
      };
    },
    disableSubmitButton(val, institutionProfileId) {
      this.disableButton[institutionProfileId] = val;
      let disabledSubmit = false;
      for (const key of Object.keys(this.disableButton)) {
        if (this.disableButton[key]) {
          disabledSubmit = true;
        }
      }
      const stepIndex = this.$parent.stepStatus.findIndex(s => s.step == 'update');
      this.$parent.stepStatus[stepIndex].disabledSubmit = disabledSubmit;
    },
  },
};
