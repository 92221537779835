












































































































import { PropType } from 'vue';
import KeywordHighlight from 'vue-text-highlight';
import ThreadStandardAvatarGroup from './ThreadStandardAvatarGroup.vue';
import AvatarGroup from '../../components/AvatarGroup.vue';
import { ThreadPropsModel } from './threadProps.model';
import { getRelativeDateTime } from '../../utils/dateUtil.js';
import ThreadActionsStandard from './ThreadActionsStandard.vue';
import capitalize from 'lodash/capitalize';
import Icon from '../../components/Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import { subscriptionTypes } from '../../enums/subscriptionTypes';
import { ThreadType } from '../../enums/threadType';

export default {
  components: {
    Icon,
    ThreadActionsStandard,
    AvatarGroup,
    ThreadStandardAvatarGroup,
    KeywordHighlight,
  },
  props: {
    thread: { type: Object as PropType<ThreadPropsModel>, required: true },
    selecting: { type: Boolean, default: false },
  },
  emits: ['toggleMuteStatus', 'toggleImportant', 'toggleReadStatus', 'moveToFolder', 'delete', 'select'],
  computed: {
    showMute() {
      return (
        this.thread.threadType !== ThreadType.EVENT_REMINDER &&
        this.thread.threadType !== ThreadType.VACATION_REQUEST_REMINDER
      );
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    bundleDescription() {
      if (this.thread.hasPrimarySubscription) {
        const numberOfReplies = this.thread.numberOfBundleItems - 1;
        return this.$tc('messages.group_replies', numberOfReplies);
      }
      return this.$tc('messages.thread', this.thread.numberOfBundleItems);
    },
    isBundleItem() {
      return this.thread.type === subscriptionTypes.BUNDLE_ITEM;
    },
    title() {
      if (this.thread.requiresStepUp) {
        return this.$t('messages.sensitive_content');
      }
      return this.thread.title;
    },
    threadParticipant() {
      if (this.thread.draft) {
        return this.thread.firstRecipient;
      }
      if (this.thread.isCreator) {
        return this.$t('messages.created_by_you');
      }
      return `${this.thread.creator.fullName} (${this.thread.creator.metadata})`;
    },
    dateTime() {
      return capitalize(getRelativeDateTime(this.thread.date));
    },
    isBundle() {
      return this.thread.type === subscriptionTypes.BUNDLE;
    },
    isBccBundle() {
      return this.isBundle && !this.thread.hasPrimarySubscription;
    },
    canSeeLastMessage() {
      return !this.isBundle && (this.thread.draft || !this.requiresStepUp);
    },
  },
  methods: {
    emitToggleMuteStatus() {
      this.$emit('toggleMuteStatus');
    },
    emitToggleImportantStatus() {
      this.$emit('toggleImportant');
    },
    emitToggleReadStatus() {
      this.$emit('toggleReadStatus');
    },
    emitMoveToFolder() {
      this.$emit('moveToFolder');
    },
    emitDelete() {
      this.$emit('delete');
    },
    emitSelect(value) {
      this.$emit('select', value);
    },
  },
};
