




























































































import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { PropType } from 'vue';
import { FolderModel } from '../../../shared/models/folder.model';
import { folderTypes } from '../../../shared/enums/folderTypes';
import { NotificationModel } from '../../../shared/models/notification.model';
import AulaBadge from '../../../shared/components/AulaBadge.vue';
import Draggable from 'vuedraggable';
import { InboxFolderModel, InboxFolderType } from '../../../shared/models/inboxFolder.model';
import SidebarMenuButton from '../../../shared/components/Sidebar/SidebarMenuButton.vue';
import SidebarMenuItem from '../../../shared/components/Sidebar/SidebarMenuItem.vue';

export default {
  components: { SidebarMenuItem, SidebarMenuButton, Draggable, AulaBadge, Icon },
  props: {
    label: { type: String, required: true },
    folders: { type: Array as PropType<FolderModel[]>, required: true },
    commonInboxId: { type: Number, default: null },
    selectedFolderId: { type: Number, default: null },
    selectedCommonInboxId: { type: Number, default: null },
    selected: { type: Boolean, default: false },
    expanded: { type: Boolean, default: true },
    notifications: { type: Array as PropType<NotificationModel[]>, default: () => [] },
  },
  emits: ['select', 'selectFolder', 'createFolder', 'editFolder', 'deleteFolder', 'toggleExpanded'],
  computed: {
    folderTypes() {
      return folderTypes;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    inboxFolderIcon() {
      return this.selected ? iconClassEnum.INBOX_SOLID : iconClassEnum.INBOX;
    },
    mainInboxNotifications() {
      return this.notifications.filter(
        notification =>
          (!this.commonInboxId && !notification.commonInboxId) || notification.commonInboxId === this.commonInboxId
      );
    },
    draggableOptions() {
      const isDroppable = this.selectedCommonInboxId === this.commonInboxId;
      return {
        name: 'messages',
        pull: false,
        put: isDroppable,
      };
    },
    draggableInbox(): InboxFolderModel {
      return {
        type: InboxFolderType.INBOX,
        id: this.commonInboxId,
      };
    },
    draggableFolders(): InboxFolderModel[] {
      return this.folders.map(folder => ({
        type: folder.type === folderTypes.NORMAL ? InboxFolderType.FOLDER : InboxFolderType.DELETED_FOLDER,
        id: folder.id,
      }));
    },
  },
  methods: {
    toggleSubFolders() {
      this.$emit('toggleExpanded', this.commonInboxId, !this.expanded);
    },
    emitInboxSelect() {
      if (this.selected) {
        return;
      }
      this.$emit('select');
    },
    emitFolderSelect(folder: FolderModel) {
      if (this.getIsFolderSelected(folder.id)) {
        return;
      }
      this.$emit('selectFolder', { folder, commonInboxId: this.commonInboxId });
    },
    emitCreateFolder() {
      this.$emit('createFolder', { commonInboxId: this.commonInboxId });
    },
    emitEditFolder(folder: FolderModel) {
      this.$emit('editFolder', { folder, commonInboxId: this.commonInboxId });
    },
    emitDeleteFolder(folder: FolderModel) {
      this.$emit('deleteFolder', { folder, commonInboxId: this.commonInboxId });
    },
    getFolderIcon(folder: FolderModel) {
      switch (folder.type) {
        case folderTypes.NORMAL:
          return this.getIsFolderSelected(folder.id) ? iconClassEnum.FOLDER_SOLID : iconClassEnum.FOLDER;
        case folderTypes.DELETED:
          return iconClassEnum.BIN;
      }
    },
    getCanEditFolder(folderType) {
      return folderType !== folderTypes.DELETED;
    },
    getIsFolderSelected(folderId) {
      return this.selectedFolderId === folderId;
    },
    getFolderNotifications(folderId) {
      return this.notifications.filter(notification => notification.folderId === folderId);
    },
    handleSubFoldersHide() {
      const hasSubFolderSelected = this.folders.some(folder => folder.id == this.selectedFolderId);
      if (hasSubFolderSelected) {
        this.emitInboxSelect();
      }
    },
  },
};
