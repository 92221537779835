import JSZipUtils from 'jszip-utils';
import { browserUtil } from '../utils/browserUtil';
import saveAs from 'jszip/vendor/FileSaver';
import JSZip from 'jszip';

export class ZippingService {
  static getBinaryContent(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // Safari does not support sec-fetch-mode cors, so we avoid that by using fetch instead of XMLHttpRequest
      if (browserUtil.isSafari()) {
        fetch(url).then(
          response =>
            response.arrayBuffer().then(buffer => {
              resolve(buffer);
            }),
          error => {
            reject(error);
          }
        );
      } else {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      }
    });
  }

  static downloadFilesAsZip(
    zipStream: JSZip,
    zipFileName: string,
    successCallback: () => void,
    failCallback: () => void
  ): void {
    zipStream
      .generateAsync({ type: 'blob' })
      .then(blob => {
        saveAs(blob, `${zipFileName}.zip`);
        successCallback();
      })
      .catch(failCallback);
  }
  static createEmptyZipStream(): JSZip {
    return new JSZip();
  }
  static async addFileToZipStream(zipStream: JSZip, filePathName: string, fileUrl: any, failCallback: any) {
    try {
      const currentFile = await this.getBinaryContent(fileUrl);
      zipStream.file(filePathName, currentFile, {
        binary: true,
      });
    } catch {
      failCallback();
    }
    return zipStream;
  }
}
