<template>
  <div class="message-functionalities">
    <b-dropdown v-show="isDropdownVisible" v-button class="dropdown-select px-2" variant="link" no-caret @click.prevent>
      <template #button-content>
        <span class="sr-only"> {{ $t('messages.functionalities') }}</span>
        <Icon :name="iconClassEnum.ELIPSES" />
      </template>
      <b-dropdown-item-btn v-if="showDelete" @click="handleDelete">
        <Icon :name="iconClassEnum.BIN" />
        {{ $t('button.delete') }}
      </b-dropdown-item-btn>
      <b-dropdown-item-btn v-if="showEdit" @click="handleEdit">
        <Icon :name="iconClassEnum.EDIT_PEN" />
        {{ $t('button.edit') }}
      </b-dropdown-item-btn>
      <b-dropdown-item-btn v-if="showForward" @click="handleForward">
        <Icon :name="iconClassEnum.ARROW_FORWARD" />
        {{ $t('messages.forward_message') }}
      </b-dropdown-item-btn>
    </b-dropdown>
  </div>
</template>
<script>
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageTypes } from '../../../shared/enums/messageTypes';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import button from '../../../shared/directives/button.js';

export default {
  components: { Icon },
  directives: {
    button,
  },
  inject: {
    onDeleteMessage: messageProviderKeyEnum.ON_DELETE_MESSAGE,
    onEditMessage: messageProviderKeyEnum.ON_EDIT_MESSAGE,
    onForwardMessage: messageProviderKeyEnum.ON_FORWARD_MESSAGE,
  },
  props: {
    message: { type: Object, default: () => {} },
    showDelete: { type: Boolean, default: true },
    showEdit: { type: Boolean, default: true },
    showForward: { type: Boolean, default: true },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isDropdownVisible() {
      return this.showDelete || this.showEdit || this.showForward;
    },
    canEditMessage() {
      const allowedMessageTypes = [messageTypes.MESSAGE, messageTypes.MESSAGE_EDITED];
      return allowedMessageTypes.includes(this.message.messageType);
    },
  },
  methods: {
    handleDelete() {
      this.onDeleteMessage();
    },
    handleEdit() {
      this.onEditMessage(this.message);
    },
    handleForward() {
      this.onForwardMessage(this.message);
    },
  },
};
</script>
<style scoped lang="scss">
.message-functionalities {
  --dropdown-max-width: 200px;
  /deep/.dropdown-toggle {
    font-size: 1rem;
    height: 16px;
  }
  /deep/.dropdown-item {
    display: grid;
    align-items: center;
    grid-template-columns: 16px 1fr;
    gap: 6px;
  }
}
</style>
