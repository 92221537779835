<template>
  <Draggable
    group="messages"
    :list="threadsInBundle"
    :force-fallback="true"
    :move="handleDragMove"
    :data-with-primary="hasPrimarySubscription"
    :fallback-tolerance="5"
    :disabled="!draggable"
    ghost-class="ghost-subscription"
    drag-class="dragging-subscription"
    class="bundle-container"
    @start="handleDragStart"
    @end="handleDragEnd"
  >
    <div v-if="isLoading" class="bundle-spinner">
      <b-spinner />
    </div>
    <template v-for="thread in threadsInBundle" v-else>
      <subscription
        :key="thread.subscriptionId"
        class="thread"
        :subscription="thread"
        @click.native="onSubscriptionClicked(thread)"
        @keyup.native.enter="onSubscriptionClicked(thread)"
        @deleted="handleSubscriptionDeleted"
      />
    </template>
    <AulaModal
      ref="primarySubscriptionWarning"
      ok-text="YES"
      @cancelClicked="$refs.primarySubscriptionWarning.hide()"
      @okClicked="moveDraggedSubscription"
    >
      <p v-html="$t('messages.primary_thread_moving_warning')" />
    </AulaModal>
  </Draggable>
</template>

<script>
import Subscription from './Subscription';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import Draggable from 'vuedraggable';
import isEqual from 'lodash/isEqual';
import { InboxFolderType } from '../../../shared/models/inboxFolder.model';
import isNumber from 'lodash/isNumber';
import { folderTypes } from '../../../shared/enums/folderTypes';

export default {
  components: { Draggable, Subscription },
  props: {
    subscriptionData: {
      type: Object,
      required: true,
    },
    draggable: { type: Boolean, default: false },
  },
  emits: ['draggingChange'],
  data: function () {
    return {
      isLoading: true,
      targetInboxFolder: null,
      draggedSubscription: null,
    };
  },
  computed: {
    ...mapGetters({
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      chosenThreads: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      chosenSubscriptions: types.MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
    threadsInBundle() {
      const bundle = this.threadBundles.find(bundle => bundle.bundleId === this.subscriptionData.subscriptionId);
      if (bundle) {
        return bundle.threads.filter(thread => !thread.deleted);
      }
      return [];
    },
    hasPrimarySubscription() {
      return this.subscriptionData.primarySubscriptionId != null;
    },
    inboxFolder() {
      let inboxFolderType = InboxFolderType.INBOX;
      let inboxFolderId = this.chosenFolderAndMailOwner.mailOwnerId;
      if (isNumber(this.chosenFolderAndMailOwner.folderId)) {
        inboxFolderType =
          this.chosenFolderAndMailOwner.folderType === folderTypes.NORMAL
            ? InboxFolderType.FOLDER
            : InboxFolderType.DELETED_FOLDER;
        inboxFolderId = this.chosenFolderAndMailOwner.folderId;
      }
      return {
        type: inboxFolderType,
        id: inboxFolderId,
      };
    },
  },
  methods: {
    ...mapMutations({
      setSelectedBundleId: types.MUTATE_SELECTED_BUNDLE_ID,
      deleteSubscriptionById: types.MUTATE_DELETE_SUBSCRIPTION_BY_ID,
    }),
    ...mapActions({
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      loadThreadsInBundleById: types.LOAD_THREADS_IN_BUNDLE_BY_ID,
      updateChosenThreads: types.ACTION_UPDATE_SELECTED_THREAD_IDS,
      updateChosenSubscriptions: types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS,
      moveMessagesToFolderAction: types.MOVE_THREADS_TO_FOLDER,
    }),
    handleDragStart() {
      this.$emit('draggingChange', true);
    },
    handleDragMove(event) {
      this.targetInboxFolder = event.relatedContext.element;
      this.draggedSubscription = event.draggedContext.element;
      return false;
    },
    handleDragEnd(event) {
      this.$emit('draggingChange', false);

      if (this.getIsDroppable(event.originalEvent.target) === false) {
        return;
      }

      if (this.targetInboxFolder?.type === InboxFolderType.DELETED_FOLDER) {
        const subscriptionComponent = event.item.__vue__;
        subscriptionComponent?.deleteBtnClicked();
      } else {
        this.handleSubscriptionMoved();
      }
    },
    handleSubscriptionMoved() {
      const isPrimarySubscription =
        this.draggedSubscription.subscriptionId === this.subscriptionData.primarySubscriptionId;
      if (isPrimarySubscription) {
        this.$refs.primarySubscriptionWarning.show();
      } else {
        this.moveDraggedSubscription();
      }
    },
    getIsDroppable(target) {
      const selectedMessageInboxElement = document.querySelector('.message-inbox[data-selected="true"]');
      const isSameInboxFolder = isEqual(this.inboxFolder, this.targetInboxFolder);
      const isValidTarget = selectedMessageInboxElement.contains(target);
      return isValidTarget && !isSameInboxFolder;
    },
    async moveDraggedSubscription() {
      const folderId = this.targetInboxFolder?.type === InboxFolderType.FOLDER ? this.targetInboxFolder.id : null;
      const isPrimarySubscription =
        this.draggedSubscription.subscriptionId === this.subscriptionData.primarySubscriptionId;

      if (!this.draggedSubscription) {
        return;
      }

      this.deleteSubscriptionById({ subscriptionId: this.draggedSubscription.subscriptionId });
      await this.moveMessagesToFolderAction({
        subscriptionIds: [this.draggedSubscription.subscriptionId],
        folderId: folderId,
      });
      this.showToast(this.$t('messages.message_moved'));

      const shouldReloadView = this.threadsInBundle.length === 0 || isPrimarySubscription;
      if (shouldReloadView) {
        this.reloadThreadView();
      } else {
        this.reloadThreadsInBundle();
      }
    },
    reloadThreadView() {
      this.updateThreadViewAction({
        folderId: this.chosenFolderAndMailOwner.folderId,
        filterType: this.chosenFolderAndMailOwner.filter,
        mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
        sort: this.chosenFolderAndMailOwner.sort,
        order: this.chosenFolderAndMailOwner.order,
      });
    },
    showToast(text) {
      this.$bvToast.toast(text, {
        solid: true,
        autoHideDelay: 3000,
        variant: 'primary',
      });
    },
    onSubscriptionClicked() {
      this.setSelectedBundleId(this.subscriptionData.subscriptionId);
    },
    async getThreadsInBundle() {
      const bundleId = this.subscriptionData.subscriptionId;
      const bundleExists = this.threadBundles.some(bundle => bundle.bundleId === bundleId);
      this.isLoading = false;

      if (bundleExists) {
        return;
      }

      this.isLoading = true;
      await this.loadThreadsInBundleById({ bundleId });
      this.isLoading = false;

      if (!this.subscriptionData.checked) {
        return;
      }

      const chosenThreads = this.chosenThreads;
      const chosenSubscriptions = this.chosenSubscriptions;
      this.threadsInBundle.forEach(thread => {
        this.$set(thread, 'checked', true);
        if (chosenThreads.includes(thread.id) === false) {
          chosenThreads.push(thread.id);
        }
        if (chosenSubscriptions.includes(thread.subscriptionId) === false) {
          chosenSubscriptions.push(thread.subscriptionId);
        }
      });
      this.updateChosenThreads(chosenThreads);
      this.updateChosenSubscriptions(chosenSubscriptions);
    },
    reloadThreadsInBundle() {
      const bundleId = this.subscriptionData.subscriptionId;
      this.loadThreadsInBundleById({ bundleId });
    },
    handleSubscriptionDeleted(subscription) {
      const isPrimaryDeleted = this.subscriptionData.primarySubscriptionId === subscription.subscriptionId;
      if (!isPrimaryDeleted) {
        this.reloadThreadsInBundle();
      }
    },
  },
  mounted() {
    this.getThreadsInBundle();
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/components/messages/_subscriptions.scss';

.bundle-container {
  &[data-with-primary] {
    --indicator-size: 2px;
    --indicator-border-radius: 8px;
    --indicator-width: 14px;
    --indicator-top: 18px;
    --indicator-left: 40px;
    --indicator-height: 20px;
    --connected-line-height: calc(100% + 30px);
    --connected-line-top: 60px;
    --primary-line-height: 64px;

    /deep/ .subscription {
      .thread-compact-item {
        --indicator-width: 16px;
        --indicator-top: 2px;
        --indicator-left: 64px;
        --connected-line-height: calc(100% + 8px);
        --connected-line-top: 20px;
        --primary-line-height: 32px;
      }

      &:first-child {
        :is(.thread-standard-item, .thread-compact-item)::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: var(--connected-line-top);
          left: var(--indicator-left);
          height: var(--primary-line-height);
          border-left: var(--indicator-size) solid var(--color-grey-darker);
        }
        .thread-standard-item {
          height: 100px;
          padding: 12px 26px 12px 20px;
          gap: 20px;
          .thread-content {
            display: block;
            &.partial {
              display: none;
            }
          }
        }
      }

      &:not(:first-child) {
        :is(.thread-standard-item, .thread-compact-item)::before {
          content: '';
          position: absolute;
          top: var(--indicator-top);
          left: var(--indicator-left);
          width: 14px;
          height: var(--indicator-height);
          border-bottom-left-radius: var(--indicator-border-radius);
          border-left: var(--indicator-size) solid var(--color-grey-darker);
          border-bottom: var(--indicator-size) solid var(--color-grey-darker);
        }
        .thread-standard-item {
          height: 72px;
          padding: 10px 26px 10px 56px;
          .first-recipient {
            display: none;
          }
        }
        .thread-compact-item {
          .thread-session .recipient-name {
            padding-left: 26px;
          }
        }
      }
      &:not(:first-child):not(:last-child) {
        :is(.thread-standard-item, .thread-compact-item)::after {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: var(--indicator-left);
          height: var(--connected-line-height);
          border-left: var(--indicator-size) solid var(--color-grey-darker);
        }
      }
    }
  }

  &:not([data-with-primary]) {
    /deep/ .subscription .thread-standard-item {
      height: 72px;
      padding: 10px 26px 10px 36px;
      .thread-creator {
        display: none;
      }
    }

    /deep/.thread {
      :is(.thread-standard-item, .thread-compact-item) {
        // Connected line between sub threads
        &::before {
          content: '';
          z-index: 1;
          width: 1px;
          height: calc(100% + 4px);
          background-color: var(--color-grey-darkest);
          position: absolute;
          left: 35px;
          top: -1px;
          transform: translate(-50%, 0);
        }
        // Dot on the left of sub thread
        &::after {
          content: '';
          z-index: 1;
          width: 5px;
          height: 5px;
          background-color: var(--color-grey);
          position: absolute;
          left: 35px;
          border-radius: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:first-child {
        :is(.thread-standard-item, .thread-compact-item)::before {
          height: calc(50% + 4px);
          top: 50%;
        }
      }
      &:last-child {
        :is(.thread-standard-item, .thread-compact-item)::before {
          height: calc(50% + 4px);
          bottom: 50%;
        }
      }
      &:only-child {
        :is(.thread-standard-item, .thread-compact-item)::before {
          display: none;
        }
      }

      :is(.thread-standard-item) {
        position: relative;
        // Connected line between sub threads
        &::before {
          left: 20px;
        }
        // Dot on the left of sub thread
        &::after {
          left: 20px;
        }
      }
      :is(.thread-compact-item) {
        position: relative;
        // Connected line between sub threads
        &::before {
          left: 55px;
        }
        // Dot on the left of sub thread
        &::after {
          left: 55px;
        }
      }
    }
  }
}

/deep/.dragging-subscription {
  opacity: 0.6 !important;
  box-shadow: var(--box-shadow-base);
  .subscription-touch-actions {
    display: none;
  }
}
/deep/.ghost-subscription.subscription {
  pointer-events: none;
  background-color: var(--color-white);
  border-radius: 4px;
  outline: 1px dashed var(--color-primary-darker);
  outline-offset: -1px;
  * {
    opacity: 0.6;
  }
}
</style>
