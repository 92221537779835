<template>
  <div id="message-autoreply">
    <div class="autoreply-scrollbar">
      <div class="autoreply-title">
        <i class="icon icon-Aula_settings" />
        <span v-if="autoReply">
          {{ 'MESSAGE_EDIT_AUTOREPLY' | fromTextKey }}
        </span>
        <span v-else>
          {{ 'MESSAGE_CREATE_AUTOREPLY' | fromTextKey }}
        </span>
      </div>
      <div class="d-flex">
        <div class="mt-4 mr-2">
          <label>{{ 'MESSAGE_LABEL_AUTO_STARTDATE' | fromTextKey }}</label>
          <el-date-picker
            v-model="startDate"
            type="date"
            :format="defaultDateFormat"
            :value-format="defaultDateValueFormat"
            :picker-options="startDateOptions"
            :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
            :class="{ elPickerActive: isStartDateClicked, 'red-border': startDateError }"
            @focus="
              datePickerModifier();
              isStartDateClicked = true;
            "
            @blur="isStartDateClicked = false"
            @change="updateEndDate()"
          />
        </div>
        <div class="flex-grow-1 mt-4">
          <label>{{ 'MESSAGE_LABEL_AUTO_STARTTIME' | fromTextKey }}</label>
          <AulaTimepicker
            v-model="startTime"
            format="HH:mm"
            hour-label="Time"
            minute-label="Minut"
            :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
            :aria-label="'ARIA_LABEL_CALENDAR_START_TIME' | fromTextKey"
            :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
            :class="{ 'red-border': startTimeError }"
            hide-clear-button
            advanced-keyboard
          />
        </div>
      </div>
      <b-alert variant="danger" :show="startDateError || startTimeError">
        {{ 'CALENDAR_ALERT_START_DATETIME_INVALID' | fromTextKey }}
      </b-alert>
      <div class="d-flex">
        <div class="mt-4 mr-2">
          <label>{{ 'MESSAGE_LABEL_AUTO_ENDDATE' | fromTextKey }}</label>
          <el-date-picker
            v-model="endDate"
            type="date"
            :format="defaultDateFormat"
            :value-format="defaultDateValueFormat"
            :picker-options="endDateOptions"
            :placeholder="'MESSAGE_PLACEHOLDER_SELECT_DATE' | fromTextKey"
            :class="{ elPickerActive: isEndDateClicked, 'red-border': endDateError }"
            @focus="
              datePickerModifier();
              isEndDateClicked = true;
            "
            @blur="isEndDateClicked = false"
          />
        </div>
        <div class="flex-grow-1 mt-4">
          <label>{{ 'MESSAGE_LABEL_AUTO_ENDTIME' | fromTextKey }}</label>
          <AulaTimepicker
            v-model="endTime"
            format="HH:mm"
            hour-label="Time"
            minute-label="Minut"
            :placeholder="'MESSAGE_PLACEHOLDER_SELECT_TIME' | fromTextKey"
            :aria-label="'ARIA_LABEL_CALENDAR_END_TIME' | fromTextKey"
            :minute-interval="timepickerSettings.DEFAULT_INTERVAL"
            :class="{ 'red-border': endTimeError }"
            hide-clear-button
            advanced-keyboard
          />
        </div>
      </div>
      <b-alert variant="danger" :show="endDateError || endTimeError">
        {{ 'CALENDAR_ALERT_END_DATETIME' | fromTextKey }}
      </b-alert>
      <div class="mt-4 label">
        <label>{{ 'MESSAGE_CONTENT' | fromTextKey }} <span class="mandatory">*</span></label>
        <b-form-textarea
          id="message-textarea"
          v-model="replyText"
          :rows="8"
          maxlength="255"
          :placeholder="'MESSAGE_TYPING_PLACEHOLDER' | fromTextKey"
          :class="replyTextErrorWarning"
        />
        <b-alert variant="danger" :show="replyTextError">
          {{ 'MESSAGE_REQUIRE_TEXT' | fromTextKey }}
        </b-alert>
      </div>
      <div class="mt-2 mb-4 text-right">
        <b-btn class="cancel-link" variant="link" @click="cancelCreation()">
          <template v-if="autoReply">
            {{ 'MESSAGE_BUTTON_DELETE' | fromTextKey }}
          </template>
          <template v-else>
            {{ 'MESSAGE_BUTTON_CANCEL' | fromTextKey }}
          </template>
        </b-btn>
        <b-btn variant="primary" @click="createAutoReply()">
          <template v-if="autoReply">
            {{ 'BUTTON_SAVE' | fromTextKey }}
          </template>
          <template v-else>
            {{ 'BUTTON_CREATE' | fromTextKey }}
          </template>
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import AulaTimepicker from '../../../shared/components/Timepicker';
import moment from 'moment-timezone';
import { timepickerSettings } from '../../../shared/enums/timepickerSettings';
import { DateTimeUtil } from '../../../shared/utils/dateTimeUtil';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';

export default {
  data: function () {
    return {
      replyText: '',
      replyTextError: false,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: '',
      isStartDateClicked: false,
      isEndDateClicked: false,
      startDateError: false,
      startTimeError: false,
      endDateError: false,
      endTimeError: false,
      currentDateTime: null,
      timepickerSettings: timepickerSettings,
      startDateOptions: {},
      endDateOptions: {},
    };
  },
  computed: {
    ...mapGetters({
      defaultDateOptions: types.GET_DEFAULT_DATE_OPTIONS,
      autoReply: types.MESSAGES_GET_AUTOREPLY,
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
    }),
    replyTextErrorWarning() {
      if (this.replyTextError && !this.replyText) {
        return 'red-border';
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.replyTextError = false;
        return '';
      }
    },
    isCreateMode() {
      return !this.autoReply;
    },
  },
  methods: {
    ...mapActions({
      createAutoreplyAction: types.CREATE_AUTOREPLY,
      deleteAutoReply: types.DELETE_AUTOREPLY,
      loadAutoReply: types.INIT_AUTOREPLY,
    }),
    updateEndDate() {
      const startDate = this.startDate;
      this.endDateOptions = {
        disabledDate(time) {
          return moment(time).isBefore(moment(startDate));
        },
        firstDayOfWeek: 1,
      };
    },
    createAutoReply() {
      let endDateTime = null;
      let isStartTimeAfterCurrentTime = true;
      let isEndDateAfterStartDate = true;
      let isEndTimeAfterStartTime = true;

      const isStartDateEmpty = !this.startDate;
      const isEndDateEmpty = !this.endDate;
      const isReplyTextEmpty = !this.replyText;

      const startDateTime = DateTimeUtil.formatDate(
        this.startDate + ' ' + this.startTime + ':00',
        dateFormatEnum.COMPLETE_DATE_TIME
      );
      isStartTimeAfterCurrentTime = DateTimeUtil.isSameOrAfter(startDateTime, this.currentDateTime, 'minute');

      if (this.endDate || this.endTime) {
        this.endTime = !this.endTime ? '23:59' : this.endTime;

        endDateTime = DateTimeUtil.formatDate(
          this.endDate + ' ' + this.endTime + ':00',
          dateFormatEnum.COMPLETE_DATE_TIME
        );
        isEndDateAfterStartDate = DateTimeUtil.isSameOrAfter(endDateTime, startDateTime);
        isEndTimeAfterStartTime = DateTimeUtil.isSameOrAfter(endDateTime, startDateTime, 'minute');

        this.endDateError = !isStartDateEmpty && (isEndDateEmpty || !isEndDateAfterStartDate);
        this.endTimeError = !isStartDateEmpty && isEndDateAfterStartDate && !isEndTimeAfterStartTime;
      }

      this.startDateError = isStartDateEmpty;
      this.startTimeError = !isStartDateEmpty && !isStartTimeAfterCurrentTime;
      this.replyTextError = isReplyTextEmpty;

      if (isStartTimeAfterCurrentTime && isEndTimeAfterStartTime && !isReplyTextEmpty) {
        this.createAutoreplyAction({
          replyText: this.replyText,
          startDateTime: startDateTime,
          endDateTime: endDateTime != null ? endDateTime : null,
        }).then(() => {
          this.loadAutoReply();
        });
        this.replyTextError = false;
        this.goOnePageBack();
      }
    },
    cancelCreation() {
      if (this.autoReply && this.autoReply.id) {
        this.deleteAutoReply();
      }
      this.goOnePageBack();
    },
    goOnePageBack() {
      window.history.back();
    },
    setAutoReply() {
      this.replyText = this.autoReply.replyText;
      if (this.autoReply.endDateTime) {
        const endDate = moment(this.autoReply.endDateTime).format('YYYY-MM-DDTHH:mm:ss');
        this.endDate = moment(endDate).format('YYYY-MM-DD');
        this.endTime = moment(endDate).format('HH:mm');
      }
      const startDate = moment(this.autoReply.startDateTime).format('YYYY-MM-DDTHH:mm:ss');
      this.startDate = moment(startDate).format('YYYY-MM-DD');
      this.startTime = moment(startDate).format('HH:mm');
    },
  },
  watch: {
    autoReply(current, before) {
      if (before === null) {
        this.setAutoReply();
      }
    },
  },
  mounted() {
    this.startDateOptions = this.endDateOptions = this.defaultDateOptions;
    this.currentDateTime = moment().set({ second: 0, millisecond: 0 }).format('YYYY-MM-DD HH:mm:ss');
    if (this.isCreateMode) {
      this.startDate = moment().format('YYYY-MM-DD');
      this.startTime = moment().format('HH:mm');
    } else {
      this.setAutoReply();
    }
  },
  components: {
    AulaTimepicker,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

#message-autoreply {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0 auto;

  @include breakpoint-lg() {
    padding: 50px 50px 0;
  }

  @include breakpoint-lg-down() {
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 0;
  }

  /deep/ .el-date-editor {
    width: auto;
  }
}

#message-textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 15px 15px;
  border: none;

  &:focus-visible {
    box-shadow: inherit;
    border: inherit;
  }
}

.autoreply-title {
  font-size: 18px;
  font-weight: bold;
}

.btn-link {
  cursor: pointer;
  padding: 14px 44px;
}

label {
  display: block;
}

.el-input,
.timepicker-container {
  width: 100%;
}

.autoreply-scrollbar {
  height: 100%;
  overflow-y: auto;
  padding: 5px;
}
</style>
