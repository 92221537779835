import { render, staticRenderFns } from "./OnboardingUpdateContactInfo.vue?vue&type=template&id=5303580a&scoped=true&"
import script from "./OnboardingUpdateContactInfo.vue?vue&type=script&lang=ts&"
export * from "./OnboardingUpdateContactInfo.vue?vue&type=script&lang=ts&"
import style0 from "./OnboardingUpdateContactInfo.vue?vue&type=style&index=0&id=5303580a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5303580a",
  null
  
)

export default component.exports