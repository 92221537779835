import Vue, { Directive, DirectiveBinding } from 'vue';
import Hammer from 'hammerjs';

const panDirective: Directive = {
  bind(el: HTMLElement, binding: DirectiveBinding<any>) {
    const direction = getDirection(binding.arg);
    const hammer = new Hammer(el);
    hammer.get('pan').set({ direction });
    hammer.on('pan', value => {
      if (value.pointerType !== 'touch') {
        return;
      }
      return binding.value(value);
    });
  },
};

const swipeDirective: Directive = {
  bind(el: HTMLElement, binding: DirectiveBinding<any>) {
    const direction = getDirection(binding.arg);
    const hammer = new Hammer(el);
    hammer.get('swipe').set({ direction });
    hammer.on('swipe', value => {
      if (value.pointerType !== 'touch') {
        return;
      }
      return binding.value(value);
    });
  },
};

const pressDirective: Directive = {
  bind(el: HTMLElement, binding: DirectiveBinding<any>) {
    const hammer = new Hammer(el);
    hammer.get('press').set({ time: 500 });
    hammer.on('press', value => {
      if (value.pointerType !== 'touch') {
        return;
      }
      return binding.value(value);
    });
  },
};

const doubleTapDirective: Directive = {
  bind(el: HTMLElement, binding: DirectiveBinding<any>) {
    const hammer = new Hammer(el);
    hammer.get('doubletap').set({ taps: 2 });
    hammer.on('doubletap', value => {
      if (value.pointerType !== 'touch') {
        return;
      }
      return binding.value(value);
    });
  },
};

const getDirection = (direction: string): number => {
  switch (direction) {
    case 'left':
      return Hammer.DIRECTION_LEFT;
    case 'right':
      return Hammer.DIRECTION_RIGHT;
    case 'up':
      return Hammer.DIRECTION_UP;
    case 'down':
      return Hammer.DIRECTION_DOWN;
    case 'vertical':
      return Hammer.DIRECTION_VERTICAL;
    case 'horizontal':
      return Hammer.DIRECTION_HORIZONTAL;
    default:
      return Hammer.DIRECTION_ALL;
  }
};

Vue.directive('pan', panDirective);
Vue.directive('swipe', swipeDirective);
Vue.directive('press', pressDirective);
Vue.directive('doubleTap', doubleTapDirective);
