








import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';

export default {
  components: { Icon },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
  },
};
