// Onboarding flow
export const FILTER_ALL = 'Alle:';
export const NEXT_STEP = 'NÆSTE';
export const PREVIOUS_STEP = 'TILBAGE';
export const PRIVATE_POLICY = 'privatlivspolitik';
export const AULA_DATA_EHTICS = 'Aulas dataetik';
export const ONBOARD_INSTITUTION_NAME = 'Privatlivspolitik';
export const PLACEHOLDER_TEXT = 'Skriv her';
export const ARIA_LABEL_ENTER_TEXT = 'Skriv svar';
export const PLACEHOLDER_PHONE = '+45 22 22 22 22';
export const READ_AND_AGREE = 'Jeg er informeret om ovenstående';
export const WARNING_ACCEPT_THE_POLICY = 'Du mangler at acceptere betingelserne';
export const WARNING_ANSWER_ADDITIONAL = 'Du mangler at udfylde nogle oplysninger';
export const WARNING_ANSWER_CONSENT = 'Du mangler at udfylde nogle oplysninger';
export const POLICY_TITLE = 'I Aula passer vi godt på dine data';
export const POLICY_TITLE_DATAEHTICS = 'Dataetik';
export const ONBOARDING_STEP_POLICY = 'Aulas privatlivspolitik';
export const ONBOARDING_STEP_UPDATE = 'Personlig information';
export const ONBOARDING_STEP_CONSENT = 'Samtykker';
export const ONBOARDING_STEP_ADDITIONAL = 'Stamkort';

export const ARIA_LABEL_INPUT_HOME_PHONENUMBER = 'Indtast hjemme telefonnummer til {profileName} på {institutionName}';
export const ARIA_LABEL_INVALID_HOME_PHONENUMBER =
  'Hjemme telefonnummer af {profileName} på {institutionName} er ugyldig';
export const ARIA_LABEL_INPUT_MOBILE_PHONENUMBER = 'Indtast mobilnummer til {profileName} på {institutionName}';
export const ARIA_LABEL_INVALID_MOBILE_PHONENUMBER = 'Mobilnummer af {profileName} på {institutionName} er ugyldig';
export const ARIA_LABEL_INPUT_WORK_PHONENUMBER = 'Indtast arbejde telefonnummer til {profileName} på {institutionName}';
export const ARIA_LABEL_INVALID_WORK_PHONENUMBER =
  'Arbejde telefonnummer af {profileName} på {institutionName} er ugyldig';
export const ARIA_LABEL_INPUT_EMAIL = 'Indtast email til {profileName} på {institutionName}';
export const ARIA_LABEL_INVALID_EMAIL = 'Email af {profileName} på {institutionName} er ugyldig';
export const ARIA_LABEL_VACATION_REQUEST_SEE_ALL_PENDING = 'Se alle der mangler at svare';
export const ARIA_LABEL_VACATION_REQUEST_SEE_LESS_PENDING = 'Skjul alle der mangler at svare';
export const ARIA_LABEL_BACK_PREVIOUS_STEP = 'Tilbage til forrige trin';
export const ARIA_LABEL_GO_TO_STEP = 'Gå til trin {stepNumber} {stepName}';

export const WARNING_INVALID = 'Ugyldig';
export const POLICY_SUMMARY_1_1 =
  'Aula er ejet af kommunerne og giver børn, elever, forældre og medarbejdere sikker adgang til information og mulighed for at kommunikere og samarbejde.';
export const POLICY_SUMMARY_2_1 =
  'Kommunerne har som mål, at alle, der bruger Aula skal føle sig godt informeret og vide, at al behandling af personoplysninger sker med respekt for brugernes privatliv. ';
export const POLICY_SUMMARY_3_1 = 'I ';
export const POLICY_SUMMARY_3_2 =
  ' kan du læse om, hvordan vi behandler og anvender dine data. Hvis du har spørgsmål eller vil gøre indsigelse mod Aulas behandling af dine personoplysninger, skal du kontakte kommunens databeskyttelsesrådgiver (DPO).';
export const POLICY_SUMMARY_4_1 = 'I ';
export const POLICY_SUMMARY_4_2 =
  ' kan du læse om begrænsningerne for deling af data, og om hvordan leverandører til Aula skal arbejde for at beskytte brugernes data og om, hvordan leverandørerne forpligter sig til ikke at udnytte informationer i Aula kommercielt.';

export const CHOOSE_PRIMARY_INSTITUTION = 'Vælg din primære institution som medarbejder:';
export const STEP_TITLE_POLICY = 'Hvilke personoplysninger indsamles og behandles i Aula?';
export const STEP_DESCRIPTION_NOTIFICATION_1 =
  '<p>Her kan du angive notifikationsindstillinger. Du kan altid ændre dem under din profil.</p>';
export const STEP_DESCRIPTION_POLICY_1 =
  'Aula indeholder personoplysninger, som er information om dig som person. Almindelige personoplysninger er for eksempel navn, adresse, mailadresse, telefonnummer mv. Der er tale om personoplysninger, som kommunen, forældre eller børn selv lægger i Aula.';
export const STEP_DESCRIPTION_POLICY_2 =
  'Aula kan i nogle tilfælde også komme til at indeholde følsomme personoplysninger om helbred, behov eller ønsker om fx særlig kost eller andre hensyn. Det kan fx ske, hvis nogen sender beskeder med information om fx kost eller særlige behov, eller når en forælder fx indtaster oplysninger om et barns allergi i barnets ‘stamdata’. I Aula vil du skulle anvende login med ekstra sikkerhed, når du skal have adgang til følsomme oplysninger. Det kan fx være oplysninger om helbred, behov eller ønsker om fx særlig kost eller andre hensyn.';

export const STEP_DESCRIPTION_CONSENT_1 =
  'I Aula kan du vælge, om dine/dine børns kontaktoplysninger må deles med andre forældre og børn i Aula. ';
export const STEP_DESCRIPTION_CONSENT_2 =
  'Du skal derfor tage stilling til, om kontaktoplysningerne må vises for andre brugere i Aula ved at svare ja/nej til de enkelte samtykker.';
export const STEP_TITLE_UPDATE = 'Personlig information';
export const ONBOARDING_WARNING_ERROR = 'Der gik noget galt. Prøv igen senere.';
export const STEP_DESCRIPTION_UPDATE_1 =
  'Opdater dine kontaktoplysninger og vælg et profilbillede. Du kan til enhver tid redigere dem på din profil.';
export const STEP_DESCRIPTION_UPDATE_2 =
  'Vær opmærksom på, at det kun er andre medarbejdere, der kan se dit arbejdstelefonnummer. Hvis du har angivet en e-mail, kan den ikke ses af andre brugere i Aula. E-mailen bruges kun i forbindelse med e-mail-notifikationer.';
export const ONBOARDING_EMPLOYEE_PROFILES = 'MEDARBEJDERPROFILER';
export const ONBOARDING_CHOOSE_PRIMARY_INSTITUTION = 'Vælg din primære institution som';

export const STEP_TITLE_CONSENT = 'Deling af kontaktoplysninger';

export const STEP_TITLE_ADDITIONAL = 'Stamkort';
export const STEP_TITLE_NOTIFICATION = 'Notifikationer';
export const STEP_DESCRIPTION_ADDITIONAL_1 =
  'Stamkort består af supplerende stamdata og tilladelser, hvilket er personlige oplysninger, som din institution har brug for.';
export const STEP_DESCRIPTION_ADDITIONAL_2 = 'Du kan til hver en tid ændre dem fra din profil.';

export const AULA_PRIVACY_POLICY_DETAILS =
  "<h1 class='step1-title'>Aulas privatlivspolitik</h1>" +
  'For, at du kan bruge Aula skal du bekræfte, at du er informeret om vores privatlivspolitik, kommunens datapolitik og Aulas dataetik. Endvidere skal du tage stilling til en række konkrete samtykker vedrørende hvordan Aula kan behandle data om dig og dine børn (bl.a. hvordan kontaktoplysninger og foto skal kunne deles med andre brugere af Aula). Du kan til enhver tid ændre de konkrete samtykker du har afgivet.<br/>' +
  '<br/>' +
  '<h2>Aula indsamler, behandler og opbevarer dine personoplysninger til bestemte formål</h2>' +
  'Formålet med Aula og behandlingen af personoplysninger i Aula er, at børn, elever, forældre og pædagogisk personale har en sikker og brugervenlig:<br/>' +
  '<br/>' +
  '<ul><li>adgang til informationer fra skoledagen og livet i dagtilbuddet.</li>' +
  '<li>fælles kanal til at kommunikere og samarbejde i.</li></ul><br/>' +
  'Dette sker i Aula, som er en digital platform, der er ejet af kommunerne.<br/>' +
  '<br/>' +
  '<h2>Dataansvar</h2>' +
  'Aula behandler personoplysninger for både børn, elever, forældre og medarbejdere, og det ansvar tager vi på os, og vi tager det alvorligt. Alle skal føle sig trygge, når de samarbejder og kommunikerer i Aula.<br/>' +
  '<br/>' +
  'Vi har som mål, at alle der anvender Aula skal føle sig godt informeret og vide, at vi har respekt for deres privatliv. Vi tilstræber derfor at være så transparente som muligt. Derfor har vi denne privatlivspolitik, hvor vi fortæller dig, hvordan vi behandler og beskytter dine personoplysninger, samt oplyser dig om dine rettigheder.<br/>' +
  '<br/>' +
  'Kommunen er ansvarlig for data i Aula og i kommunens datapolitik kan du læse om, hvordan vi behandler og anvender dine data i kommunen.<br/>' +
  '<br/>' +
  'I Aulas dataetik kan du læse om, begrænsninger i datadeling, og hvordan leverandører til Aula skal arbejde for at beskytte brugernes data og forpligte sig til ikke at udnytte informationer i Aula kommercielt.<br/>' +
  '<br/>' +
  '<h2>Vi er den dataansvarlige - hvordan kontakter du os?</h2>' +
  'Kommunen er dataansvarlig for behandlingen af de personoplysninger, som vi har modtaget om dig. Hvis du har spørgsmål eller vil gøre indsigelse mod Aulas behandling af dine personoplysninger, skal du kontakte kommunens DPO (databeskyttelsesrådgiver). Du finder kontaktoplysningerne i datapolitikken .<br/>' +
  '<br/>' +
  'Kommunen er ansvarlig for, hvordan og i hvilket omfang Aula bruges i dagtilbud og skole.<br/>' +
  '<br/>' +
  'Kommunen er ifølge lovgivningen ansvarlig for at sikre, at personoplysninger bliver håndteret efter gældende lovgivning.<br/>' +
  '<br/>' +
  '<h2>Behandling af personoplysninger i Aula</h2><br/>' +
  'Behandlingen af personoplysning i Aula sker på baggrund af Databeskyttelsesforordningens regler i artikel 6 (stk. 1, litra e) og artikel 9 (stk. 2, litra b). De danner grundlag for behandling i forbindelse med offentlig myndighedsudøvelse og for at overholde den dataansvarliges eller den registreredes arbejds- sundheds- og socialretlige forpligtelser og specifikke rettigheder. Den offentlige myndighedsudøvelse er opgaver som kommunerne er pålagt at udføre på skole-, dagtilbuds- og SFO-området og som er reguleret i Bekendtgørelse af lov om Folkeskole og i Bekendtgørelse af lov om dag-, fritids- og klubtilbud til børn og unge.<br/>' +
  '<br/>' +
  'Behandlingen af personoplysninger og andre data i Aula indebærer, at' +
  '<br/>' +
  '<ul><li>Aula behandler data om dig og dine eventuelle børn i forbindelse med samarbejde og kommunikation i Aula.</li>' +
  '<li>Kommunens medarbejdere i dagtilbud og skole har adgang til at se dine og dine eventuelle børns personoplysninger.</li>' +
  '<li>Aula indhenter personoplysninger gennem UNI•Login, der styrer, hvilke brugere, som har adgang til Aula.</li>' +
  '<li>Aula indsamler oplysninger om brugernes anvendelse i anonymiseret form til brug for generel statistik om, hvordan Aula bliver anvendt på den enkelte institution og i kommunen.</li>' +
  '<li>Du gennem Aula kan sende beskeder til brugere på andre institutioner, der også anvender Aula.</li>' +
  '<li>Aula deler relevante data med vores samarbejdspartnere (se nedenfor).</li>' +
  '<li>Aula får behandlet data hos vores samarbejdspartnere (se nedenfor).</li></ul>' +
  'I det omfang, at der er tale om behandling af personoplysninger, der ligger udenfor ovenstående, vil der forinden blive indsamlet konkrete samtykker (fx hvis dine og dine eventuelle børns kontaktoplysninger og foto skal kunne deles med andre forældre og brugere af Aula). Indsamling af konkret samtykke kan både ske i Aula eller på anden vis hos kommunen, der er dataansvarlig. Denne behandling vil ske på baggrund af Databeskyttelsesforordningens regler i artikel 6 (stk. 1, litra a) og artikel 9 (stk. 2, litra a).<br/>' +
  '<br/>' +
  '<h2>Hvilke typer af personoplysninger anvender Aula?</h2>' +
  '<strong>Oplysninger indsamlet fra brugeren i Aula</strong><br/>' +
  'I Aula har vi brug for kontaktoplysninger på forældre, medarbejdere, børn og elever. Disse oplysninger bliver indsamlet i Aula. Der vil også være mulighed for at tilføje supplerende oplysninger om børn og elever vedrørende forhold der er væsentlige at kende for institutionen. Det kan fx være følsomme oplysninger om helbred, race/etnicitet og religiøs overbevisning, som fx særlig kost eller andre hensyn.<br/>' +
  '<br/>' +
  'I forbindelse med første login til Aula bliver brugerne bedt om at afgive disse oplysninger og senere kan man ændre og opdatere dem i Aula.<br/>' +
  '<br/>' +
  '<strong>Oplysninger dannet hos brugeren i Aula</strong><br/>' +
  'Når brugerne i Aula skriver beskeder til hinanden, bliver der dannet oplysninger. Oplysningerne kan vedrøre specifikke personer, fx en elev, og kan være følsomme eller fortrolige. Det vil fx være tilfældet, hvis de vedrører sygdom eller, hvis de vedrører familiære forhold.<br/>' +
  '<br/>' +
  '<strong>Oplysninger indsamlet via UNI•Login fra Styrelsen for It og Læring</strong><br/>' +
  'Aula modtager oplysninger om personer – herunder cpr.-nummer fra UNI•Login. Oplysningerne har til formål at identificere brugere og knytte brugeren til et dagtilbud eller en skole og eventuelt klasse. Oplysninger fra UNI•Login indeholder ikke følsomme personoplysninger, men kan indeholde fortrolige oplysninger om navne- og adressebeskyttelse.<br/>' +
  '<br/>' +
  'I Aula anvendes login med ekstra sikkerhed, når du skal have adgang til følsomme eller fortrolige personoplysninger.<br/>' +
  '<br/>' +
  '<h2>Videregivelse af data</h2>' +
  'Aula deler kun data med vores samarbejdspartnere i det omfang, det er sikkert og relevant.<br/>' +
  '<br/>' +
  'Aula anvender ikke "SoMe plugins", da vi ikke ønsker at dele brugernes data med sociale medier.<br/>' +
  '<br/>' +
  'Kommunen eller vores samarbejdspartnere sælger under ingen omstændigheder dine personlige oplysninger til 3. part.<br/>' +
  '<br/>' +
  '<h2>Samarbejdspartnere</h2>' +
  'Kommunen og Aula benytter sig af følgende samarbejdspartnere, der behandler data:<br/>' +
  '<br/>' +
  '<strong>KOMBIT</strong><br/>' +
  'KOMBIT er databehandler for kommunen vedrørende Aula. Dette omfatter, at KOMBIT har opgaven med forvaltning af Aula i forhold til it-driftsafvikling og videreudvikling. KOMBIT har indgået aftale med underleverandører om den praktiske udførelse af it-driftsafvikling og videreudvikling.<br/>' +
  '<br/>' +
  '<strong>Netcompany</strong><br/>' +
  'Netcompany er underdatabehandler til KOMBIT og varetager den praktiske udførelse af it-driftsafvikling og videreudvikling af Aula.<br/>' +
  '<br/>' +
  '<strong>Systematic</strong><br/>' +
  'Systematic er underdatabehandler til KOMBIT. De varetager den praktiske udførelse af it-driftsafvikling og videreudvikling af Serviceplatformen. Denne anvendes til opsamling og lagring af anonymiserede data om brugernes anvendelse af Aula til brug for generelle statistikker. Kommunen kan hente data om brugernes anvendelse af Aula på Serviceplatformen.<br/>' +
  '<br/>' +
  '<strong>KMD</strong><br/>' +
  'KMD er underdatabehandler til KOMBIT. De varetager den praktiske udførelse af it-driftsafvikling og videreudvikling af Støttesystemerne, der i forbindelse med Aula bliver anvendt til styring af adgang for kommunens medarbejdere.<br/>' +
  '<br/>' +
  '<h2>Sådan beskytter vi dine personoplysninger</h2>' +
  'Kommunen og Aulas samarbejdspartnere har vedtaget et sæt interne regler om informationssikkerhed (databehandleraftale). Disse indeholder instrukser og foranstaltninger, der beskytter dine personoplysninger mod at blive tilintetgjort, gå tabt eller blive ændret; mod uautoriseret offentliggørelse og mod at uvedkommende får adgang eller kendskab til dem.<br/>' +
  '<br/>' +
  'Aula har retningslinjer for dataetik, som skal sikre, at Aula er en tryg digital platform for sine brugere – børn, elever, medarbejdere og forældre. Du finder Aulas dataetiske regelsæt her.<br/>' +
  '<br/>' +
  'For at sikre dine oplysninger på Aula er det vigtigt, at du har et stærkt password, og at du ikke deler det med andre.<br/>' +
  '<br/>' +
  '<h2>Opbevaring af dine personoplysninger</h2>' +
  'Aula opbevarer dine personoplysninger så længe dit barn er indskrevet i kommunalt dagtilbud eller grundskole. Personoplysningerne bliver slettet 15 måneder efter, at dit barn ikke længere er indmeldt i kommunalt dagtilbud eller grundskole.<br/>' +
  '<br/>' +
  '<h2>Dine rettigheder</h2>' +
  'Du har i henhold til databeskyttelseslovgivningen en række rettigheder i forhold til Aulas behandling af oplysninger om dig. Hvis du vil gøre brug af dine rettigheder skal du kontakte kommunens DPO (databeskyttelsesrådgiver).<br/>' +
  '<br/>' +
  '<strong>Ret til at se oplysninger (indsigtsret)</strong><br/>' +
  'Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.<br/>' +
  '<br/>' +
  '<strong>Ret til berigtigelse (rettelse)</strong><br/>' +
  'Du har ret til at få urigtige oplysninger om dig selv rettet.<br/>' +
  '<br/>' +
  '<strong>Ret til sletning</strong><br/>' +
  'I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden tidspunktet for vores almindelige generelle sletning indtræffer.<br/>' +
  '<br/>' +
  '<strong>Ret til begrænsning af behandling</strong><br/>' +
  'Du har i visse tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis du har ret til at få begrænset behandlingen, må vi fremover kun behandle oplysningerne – bortset fra opbevaring – med dit samtykke, eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares, eller for at beskytte en person eller vigtige samfundsinteresser.<br/>' +
  '<br/>' +
  '<strong>Ret til indsigelse</strong><br/>' +
  'Du har i visse tilfælde ret til at gøre indsigelse mod vores ellers lovlige behandling af dine personoplysninger.<br/>' +
  '<br/>' +
  'Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte markedsføring.<br/>' +
  '<br/>' +
  '<strong>Ret til at transmittere oplysninger (dataportabilitet)</strong><br/>' +
  'Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret, almindeligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden hindring.<br/>' +
  '<br/>' +
  "Du kan læse mere om dine rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder på <a href='https://www.datatilsynet.dk' target='_blank'>www.datatilsynet.dk</a>.<br/>" +
  'Klage til Datatilsynet<br/>' +
  "Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, vi behandler dine personoplysninger på. Du finder Datatilsynets kontaktoplysninger på <a href='https://www.datatilsynet.dk' target='_blank'>www.datatilsynet.dk</a>.";

export const AULA_DATA_ETHICS_DETAILS =
  "<h1 class='step1-title'>Aulas dataetiske regelsæt</h1>" +
  'Aula indeholder personoplysninger om både børn, forældre og lærere, som trygt skal kunne bruge Aula i forvisning om at deres data er i sikre hænder.' +
  '<br/>' +
  '<br/>' +
  'Den enkelte kommune er ansvarlig for den behandling af personoplysninger, der sker i Aula.' +
  '<br/>' +
  '<br/>' +
  'Kommunen har fastlagt en privatlivspolitik specifikt for Aula. I tillæg til denne privatlivspolitik har hver enkelt kommune en separat datapolitik, som beskriver den specifikke behandling, kommunen foretager i forbindelse med Aula. Disse specifikke politikker for Aula gælder ved siden af kommunens generelle privatlivspolitik, som omfatter kommunens generelle behandlingsaktiviteter.' +
  '<br/>' +
  '<br/>' +
  'I Aula er der fokus på dataetik. Det er et mål, at brugere skal føle sig trygge, når de bruger Aula. I praksis betyder det, at Aula er en platform, der ikke tillader overvågning af brugernes adfærd med det formål at udnytte denne information kommercielt.' +
  '<br/>' +
  '<br/>' +
  'Aulas formål er, at børn, elever, forældre og pædagogisk personale har en sikker og brugervenlig adgang til informationer fra skoledagen og livet i dagtilbuddet samt en fælles kanal til at kommunikere og samarbejde i. Brugernes personoplysninger i Aula er derfor beskyttet mod enhver anvendelse, der går imod disse formål.' +
  '<br/>' +
  '<br/>' +
  'Brugerne vil via Aula også kunne få adgang til løsninger ”widgets” fra andre leverandører, der forsyner kommuner, skoler og dagtilbud med it-løsninger rettet mod undervisning, administration, skemaplanlægning m.m. Aulas dataetiske retningslinjer gælder derfor også for leverandører, der leverer disse “widgets” til Aula. For at få adgang til Aula som widgetleverandør, skal Aulas governanceboard forsikres om, at widgetleverandøren lever op til Aulas dataetiske retningslinjer.' +
  '<br/>' +
  '<br/>' +
  "Du finder <a href='https://aulainfo.dk/foraeldreogelever/aula-beslutter-dataetisk-regelsaet' target='_blank'>regelsættet for leverandører her.</a>" +
  '<br/>' +
  '<br/>' +
  'Aulas dataetiske retningslinjer er:' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og datadeling</strong><br/>' +
  'Aula sælger ikke under nogen omstændigheder personoplysninger videre.' +
  '<br/>' +
  '<br/>' +
  'Aula deler ikke personoplysninger med 3-part medmindre de er leverandører til Aula (Se dataetiske retningslinjer for leverandører).' +
  '<br/>' +
  '<br/>' +
  'Aula tillader ikke 3-parts cookies, herunder cookies fra sociale medier. Aula kan godt henvise til eksterne hjemmesider, der anvender cookies.' +
  '<br/>' +
  '<br/>' +
  'Brugerne kan selv vælge at eksportere personoplysninger og dele disse med andre.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og dataadgang</strong><br/>' +
  'Udover brugerne selv har sikkerhedsgodkendte systemadministratorer med tavshedspligt adgang til personoplysninger, hvis dette er nødvendigt.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og samtykke</strong><br/>' +
  'Aulas arkitektur er opbygget således, at behandling af personoplysninger kun foretages enten med et specifikt hjemmelsgrundlag eller med samtykke fra den enkelte bruger' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og databrug</strong><br/>' +
  'Aula indsamler kun de personoplysninger, der er absolut nødvendige, (jf. privatlivspolitikken) og efter gældende lovgivning. Aula bruger anonymiserede personoplysninger om brugeradfærd til f.eks. at forbedre Aulas funktionalitet.' +
  '<br/>' +
  '<br/>' +
  'Aula foretager ikke profilering af børn eller andre brugere.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og genanvendelse af personoplysninger</strong><br/>' +
  'Aulas personoplysninger vil i nogle tilfælde kunne genanvendes af kommunen til brug for statistiske undersøgelser. Såfremt det er tilfældet, vil det fremgå af kommunens datapolitik.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og algoritmer</strong><br/>' +
  'Aula anvender som udgangspunkt ikke kunstig intelligens. Såfremt det tages i brug, vil vi sikre, at det forklares, og at det sker til gavn for brugerne.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og kommunikation</strong><br/>' +
  'Aulas kommunikation mellem brugerne er fortrolig.' +
  '<br/>' +
  '<br/>' +
  'Aula er en ikke-kommerciel platform, der ønsker at begrænse mobning og individuel konkurrence og har derfor ikke “likes”-funktionalitet indbygget.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og moderering af indhold</strong><br/>' +
  'Aulas indhold modereres af fagprofessionelle mennesker.' +
  '<br/>' +
  '<br/>' +
  '<strong>Aula og etisk ansvar</strong><br/>' +
  'Aula får et Governance board, som løbende godkender funktionalitet og tager stilling til etiske dilemmaer.';
