<template>
  <div class="action-buttons-container">
    <AulaButtons>
      <AulaButton
        v-if="canShowQuickAction"
        class="action-button"
        :aria-label="'MESSAGE_DELETE_THREAD' | fromTextKey"
        variant="link"
        @click.stop="emitDeleteThread()"
      >
        <IconContainer>
          <Icon :name="iconClassEnum.BIN" />
        </IconContainer>
      </AulaButton>

      <AulaButton
        v-if="selectedThread && canShowQuickAction"
        class="action-button"
        :aria-label="
          selectedThread.read ? 'MESSAGE_ACTION_MARK_AS_UNREAD' : 'MESSAGE_ACTION_MARK_AS_READ' | fromTextKey
        "
        variant="link"
        @click.stop="emitToggleSubscriptionStatus()"
      >
        <IconContainer>
          <Icon v-if="selectedThread.read" :name="iconClassEnum.ENVELOPE_CLOSED" />
          <Icon v-else :name="iconClassEnum.ENVELOPE_OPEN" />
        </IconContainer>
      </AulaButton>

      <AulaButton
        v-if="canMoveToFolder && (canShowQuickAction || isReminderThread)"
        class="action-button"
        :aria-label="'MESSAGE_MOVE_FOLDER' | fromTextKey"
        variant="link"
        @click.stop="emitMoveToFolderVisibility(true)"
      >
        <IconContainer>
          <Icon :name="iconClassEnum.FOLDER" />
        </IconContainer>
      </AulaButton>

      <b-dropdown
        v-if="canHandleMoreActions"
        variant="link"
        class="more-actions-dropdown"
        no-caret
        dropleft
        menu-class="action-menu"
        toggle-class="action-button"
        boundary="window"
        :popper-opts="{ positionFixed: true }"
      >
        <template #button-content>
          <Icon :aria-label="'MESSAGE_DROPDOWN_MORE' | fromTextKey" :name="iconClassEnum.ELIPSES" />
        </template>

        <b-dropdown-item-btn v-if="selectedThread" @click.stop="emitToggleSubscriptionStatus()">
          <IconContainer>
            <Icon v-if="selectedThread.read" :name="iconClassEnum.ENVELOPE_CLOSED" />
            <Icon v-else :name="iconClassEnum.ENVELOPE_OPEN" />
          </IconContainer>
          <span v-if="selectedThread.read">{{
            !canShowQuickAction ? 'MESSAGE_MARK_AS_UNREAD_SHORT' : 'MESSAGE_ACTION_MARK_AS_UNREAD' | fromTextKey
          }}</span>
          <span v-else>{{
            !canShowQuickAction ? 'MESSAGE_MARK_AS_READ_SHORT' : 'MESSAGE_ACTION_MARK_AS_READ' | fromTextKey
          }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn @click.stop="emitToggleMutedStatus()">
          <IconContainer>
            <Icon
              v-if="shownSubscription.muted"
              :disabled="Boolean(shownSubscription.leaveTime)"
              :name="iconClassEnum.BELL"
            />
            <Icon v-else :name="iconClassEnum.BELL_2" />
          </IconContainer>
          <span v-if="shownSubscription.muted">{{ 'MESSAGE_DROPDOWN_UNMUTE' | fromTextKey }}</span>
          <span v-else>{{ 'MESSAGE_DROPDOWN_MUTE' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn :disabled="!canForwardThread" @click.stop="emitHandleSharingActions(true)">
          <IconContainer>
            <Icon :name="iconClassEnum.ARROW_FORWARD" />
          </IconContainer>
          <span>{{ $t('messages.forward_thread') }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn
          v-if="canMoveToFolder && (canShowQuickAction || isReminderThread)"
          @click.stop="emitMoveToFolderVisibility(true)"
        >
          <IconContainer>
            <Icon :name="iconClassEnum.FOLDER" />
          </IconContainer>
          <span>{{ 'MESSAGE_MOVE_FOLDER' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn
          :disabled="Boolean(shownSubscription.leaveTime) || !hasPermissionToWriteMessage"
          @click.stop="emitHandleSharingActions(false)"
        >
          <IconContainer>
            <Icon :name="iconClassEnum.PLUS" />
          </IconContainer>
          <span>{{ 'MESSAGE_DROPDOWN_ADD_RECIPIENTS' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn @click.stop="emitToggleThreadImportant()">
          <IconContainer>
            <Icon v-if="shownSubscription.marked" :name="iconClassEnum.STAR_SOLID" class="colored" />
            <Icon v-else :name="iconClassEnum.STAR_OUTLINE" />
          </IconContainer>
          <span>{{ shownSubscription.marked ? 'MESSAGE_REMOVE_MARK_LONG' : 'MESSAGE_MARK_LONG' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn
          v-if="canShowToggleSensitive"
          :disabled="!hasPermissionToWriteMessage"
          :title="'MESSAGE_DROPDOWN_SENSITIVE_HELP' | fromTextKey"
          @click.stop="emitToggleThreadSensitiveStatus()"
        >
          <IconContainer>
            <Icon :name="iconClassEnum.LOCK" />
          </IconContainer>
          <span v-if="!shownSubscription.sensitive">{{ 'MESSAGE_DROPDOWN_SENSITIVE' | fromTextKey }}</span>
          <span v-else>{{ 'MESSAGE_DROPDOWN_UNSENSITIVE' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn v-if="canAttachToDocument" @click.stop="emitSelectMessages()">
          <IconContainer>
            <Icon :name="iconClassEnum.PAPER" />
          </IconContainer>
          <span>{{ 'MESSAGE_DROPDOWN_CONNECT' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn v-if="canAttachToDocument" @click.stop="emitSaveAsSecureFile()">
          <IconContainer>
            <Icon :name="iconClassEnum.NEW_FILE" />
          </IconContainer>
          <span>{{ $t('messages.save_as_secure_file') }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn
          v-if="showLeaveButton"
          :title="'MESSAGE_DROPDOWN_LEAVE_HELP' | fromTextKey"
          @click.stop="emitLeaveThread()"
        >
          <IconContainer>
            <Icon :name="iconClassEnum.USER_LEAVE" />
          </IconContainer>
          <span>{{ 'MESSAGE_DROPDOWN_LEAVE' | fromTextKey }}</span>
        </b-dropdown-item-btn>

        <b-dropdown-item-btn @click.stop="emitDeleteThread()">
          <IconContainer>
            <Icon :name="iconClassEnum.BIN" />
          </IconContainer>
          <span>{{ 'MESSAGE_DELETE_THREAD' | fromTextKey }}</span>
        </b-dropdown-item-btn>
      </b-dropdown>
    </AulaButtons>
  </div>
</template>

<script>
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum.js';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import IconContainer from '../../../shared/components/IconContainer.vue';

export default {
  components: { IconContainer, Icon, AulaButtons, AulaButton },
  inject: {
    getIsReminderThread: messageProviderKeyEnum.GET_IS_REMINDER_THREAD,
  },
  props: {
    selectedThread: { type: Object, default: null },
    shownSubscription: { type: Object, default: null },
    canShowQuickAction: { type: Boolean, default: false },
    canShowToggleSensitive: { type: Boolean, default: false },
  },
  emits: [
    'toggleThreadImportant',
    'toggleSubscriptionStatus',
    'moveToFolderVisibility',
    'deleteThread',
    'toggleMutedStatus',
    'toggleMarkThreadSensitiveStatus',
    'toggleUnMarkThreadSensitiveStatus',
    'handleSharingActions',
    'leaveThread',
    'selectMessages',
    'saveAsSecureFile',
  ],
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      hasPermission: types.HAS_PERMISSION,
      institutions: types.GET_INSTITUTIONS,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
    isReminderThread() {
      return this.getIsReminderThread();
    },
    canMoveToFolder() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    hasPermissionToWriteMessage() {
      return this.hasPermissionOnInstitution(permissionEnum.WRITE_MESSAGE, this.shownSubscription.institutionCode);
    },
    canHandleMoreActions() {
      return !this.isReminderThread;
    },
    canForwardThread() {
      return this.hasPermissionToWriteMessage === true && this.shownSubscription.isArchived !== true;
    },
    canAttachToDocument() {
      return this.hasPermission(permissionEnum.HANDLE_SECURE_FILES);
    },
    showLeaveButton() {
      let isShown = true;
      if (
        this.chosenFolderAndMailOwner &&
        this.chosenFolderAndMailOwner.mailOwnerId &&
        this.chosenFolderAndMailOwner.mailOwnerType !== messageOwnerTypes.OTP_INBOX
      ) {
        isShown = false;
      }
      if (this.shownSubscription.leaveTime) {
        isShown = false;
      }
      return isShown;
    },
  },
  methods: {
    emitToggleThreadImportant() {
      this.$emit('toggleThreadImportant');
    },
    emitToggleSubscriptionStatus() {
      this.$emit('toggleSubscriptionStatus');
    },
    emitMoveToFolderVisibility() {
      this.$emit('moveToFolderVisibility');
    },
    emitDeleteThread() {
      this.$emit('deleteThread');
    },
    emitToggleMutedStatus() {
      this.$emit('toggleMutedStatus');
    },
    emitToggleThreadSensitiveStatus() {
      if (this.shownSubscription.sensitive) {
        this.$emit('toggleUnMarkThreadSensitiveStatus');
      } else {
        this.$emit('toggleMarkThreadSensitiveStatus');
      }
    },
    emitHandleSharingActions(value) {
      this.$emit('handleSharingActions', value);
    },
    emitLeaveThread() {
      this.$emit('leaveThread');
    },
    emitSelectMessages() {
      this.$emit('selectMessages');
    },
    emitSaveAsSecureFile() {
      this.$emit('saveAsSecureFile');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/modal.scss';

.colored {
  color: var(--color-alert);
}

.action-buttons-container {
  display: flex;
  gap: 8px;
  flex-flow: nowrap;
  height: 100%;

  .buttons {
    flex-wrap: nowrap;
    gap: 4px;
    height: 100%;
  }

  .icon-container {
    background-color: transparent;

    .aula-icon {
      --font-size: 18px;
    }
  }
}

.more-actions-dropdown {
  /deep/ &.show .dropdown-toggle.dropdown-toggle-no-caret {
    z-index: 1001;
    color: var(--color-primary-base-employee);
  }

  /deep/ &.show .dropdown-menu {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    .dropdown-item {
      display: flex;
      flex-direction: row;
      font-size: 14px;

      .icon-container {
        background-color: transparent;
      }

      span {
        padding-left: 8px;
        align-self: center;
      }
    }
  }
}

/deep/ .dropdown-menu.action-menu {
  margin-top: -8px;
  margin-right: -28px;
  padding-top: 48px;
  padding-bottom: 24px;
  min-width: 300px;
}

/deep/ .action-button {
  padding: 2px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: var(--color-white);
  }
}
</style>
