import { portal } from '../../assets/plugins/axios/axios.js';
import { AxiosError } from 'axios';
import { HttpError } from '../../../src/business/httpError';
import { GetMessagesForThreadRequest } from '../../models/requests/getMessagesForThread.request';
import { ThreadWithMessagesModel } from '../../models/threadWithMessages.model';
import { SetThreadsMutedRequest } from '../../models/requests/setThreadsMuted.request';
import { GetMessageInfoLightRequest } from '../../models/requests/getMessageInfoLight.request';
import { MessageInfoLightModel } from '../../models/messageInfoLight.model';

class MessagingService {
  public getMessagesForThread = async (payload: GetMessagesForThreadRequest): Promise<ThreadWithMessagesModel> => {
    try {
      const { data } = await portal.get('?method=messaging.getMessagesForThread', { params: payload });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getMessageInfoLight = async (payload: GetMessageInfoLightRequest): Promise<MessageInfoLightModel> => {
    try {
      const { data } = await portal.get('?method=messaging.getMessageInfoLight', { params: payload });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public setThreadsMuted = async (payload: SetThreadsMutedRequest): Promise<null> => {
    try {
      const { data } = await portal.post('?method=messaging.setThreadsMuted', payload);
      return data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public createFolder = async (payload: { commonInboxId: number; folderName: string }): Promise<null> => {
    try {
      const { data } = await portal.post('?method=messaging.createFolder', payload);
      return data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public updateFolder = async (payload: { folderId: number; folderName: string }): Promise<null> => {
    try {
      const { data } = await portal.post('?method=messaging.updateFolder', payload);
      return data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public deleteFolder = async (payload: { folderId: number }): Promise<null> => {
    try {
      const { data } = await portal.post('?method=messaging.deleteFolder', payload);
      return data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const messagingService = new MessagingService();
