var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"aula-onboarding"},[_c('b-container',[_c('div',{staticClass:"header"},[(_vm.isMobile && !_vm.stepStatus.findIndex(function (s) { return s.step == _vm.$route.params.step; }) == 0)?_c('i',{staticClass:"go-back icon-Aula_arrow_new_item",attrs:{"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_BACK_PREVIOUS_STEP')},on:{"click":_vm.goBackStep}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"aula-logo"},[_c('div',{staticClass:"logo"},[_c('i',{staticClass:"icon-Logo",attrs:{"aria-hidden":"true"}})])]),_vm._v(" "),(_vm.isMobile)?_c('b-btn',{staticClass:"next-link",attrs:{"variant":"link","disabled":_vm.disabledNextButton || _vm.isLoading},on:{"click":function($event){return _vm.stepSubmit()}}},[_vm._v("\n          "+_vm._s(_vm._f("fromTextKey")('NEXT_STEP'))+"\n          "),(_vm.isLoading)?_c('aula-spinner',{staticClass:"aula-spinner"}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"onboarding-steps-mobile"},[(_vm.isMobile)?_c('ul',{staticClass:"steps"},_vm._l((_vm.stepStatus),function(step,index){return _c('li',{key:index,class:_vm.$route.params.step == step.step ? 'active' : '',attrs:{"tabindex":"1","aria-current":_vm.$route.params.step == step.step ? 'step' : null,"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_GO_TO_STEP',{
                    stepNumber: index + 1,
                    stepName: _vm.getStepName(step.step),
                  })},on:{"click":function($event){return _vm.goToStep(step.step)}}},[_vm._v("\n              "+_vm._s(index + 1)+"\n            ")])}),0):_vm._e()])],1),_vm._v(" "),_c('b-row',{staticClass:"onboarding"},[_c('b-col',{staticClass:"p-0 onboarding-wrapper",attrs:{"cols":"12","lg":"9"}},[(_vm.initLoading)?_c('aula-spinner'):_c('div',{staticClass:"onboard-content"},[(_vm.isMobile)?_c('div',{staticClass:"intro-step margin-bottom-40"},[_c('h1',{staticClass:"step-title"},[_vm._v("\n                "+_vm._s(_vm.getStepText(true))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"step-desc scrollbar",domProps:{"innerHTML":_vm._s(_vm.getStepText())}})]):_vm._e(),_vm._v(" "),(_vm.$route.params.step == 'policy' && _vm.stepStatus.findIndex(function (s) { return s.step == 'policy'; }) > -1)?_c('onboarding-policy'):_vm._e(),_vm._v(" "),(_vm.$route.params.step == 'update' && _vm.stepStatus.findIndex(function (s) { return s.step == 'update'; }) > -1)?_c('onboarding-update-contact-info',{ref:"onboardingUpdate",attrs:{"profile-master-data":_vm.profileMasterData,"related-profiles":_vm.custodialChildren},on:{"emitAvatarUpload":_vm.getProfileMasterData}}):_vm._e(),_vm._v(" "),_c('onboarding-consent',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.step == 'consent' && _vm.stepStatus.findIndex(function (s) { return s.step == 'consent'; }) > -1),expression:"$route.params.step == 'consent' && stepStatus.findIndex(s => s.step == 'consent') > -1"}],ref:"onboardingConsent"}),_vm._v(" "),_c('onboarding-additional-data',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.params.step == 'additional' && _vm.stepStatus.findIndex(function (s) { return s.step == 'additional'; }) > -1),expression:"$route.params.step == 'additional' && stepStatus.findIndex(s => s.step == 'additional') > -1"}],ref:"onboardingAdditionalData"}),_vm._v(" "),_c('onboarding-notification',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$route.params.step == 'notification' && _vm.stepStatus.findIndex(function (s) { return s.step == 'notification'; }) > -1
              ),expression:"\n                $route.params.step == 'notification' && stepStatus.findIndex(s => s.step == 'notification') > -1\n              "}],ref:"onboardingNotification"})],1)],1),_vm._v(" "),(!_vm.isMobile)?_c('b-col',{staticClass:"onboarding-steps",attrs:{"lg":"3"}},[_c('ul',{staticClass:"steps"},_vm._l((_vm.stepStatus),function(step,index){return _c('li',{key:index,class:_vm.$route.params.step == step.step ? 'active' : '',attrs:{"tabindex":"1","aria-selected":_vm.$route.params.step == step.step ? true : false,"aria-label":_vm._f("fromTextKey")('ARIA_LABEL_GO_TO_STEP',{
                    stepNumber: index + 1,
                    stepName: _vm.getStepName(step.step),
                  })},on:{"click":function($event){return _vm.goToStep(step.step)}}},[_vm._v("\n              "+_vm._s(index + 1)+"\n            ")])}),0),_vm._v(" "),_c('h2',{staticClass:"step-title scrollbar"},[_vm._v("\n            "+_vm._s(_vm.getStepText(true))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"step-desc scrollbar",domProps:{"innerHTML":_vm._s(_vm.getStepText())}}),_vm._v(" "),_c('div',{staticClass:"step-button"},[_c('b-btn',{staticClass:"send-btn",attrs:{"variant":"primary","disabled":_vm.disabledNextButton || _vm.isLoading},on:{"click":function($event){return _vm.stepSubmit()}}},[_vm._v("\n              "+_vm._s(_vm.getNextButtonText())+"\n              "),(_vm.isLoading)?_c('aula-spinner',{staticClass:"aula-spinner"}):_vm._e()],1),_vm._v(" "),(!_vm.stepStatus.findIndex(function (s) { return s.step == _vm.$route.params.step; }) == 0)?_c('b-btn',{staticClass:"previous-link",attrs:{"variant":"link"},on:{"click":_vm.goBackStep}},[_vm._v("\n              "+_vm._s(_vm._f("fromTextKey")('PREVIOUS_STEP'))+"\n            ")]):_vm._e()],1)]):_vm._e()],1)],1),_vm._v(" "),_c('aula-modal',{ref:"declinedSpecialConsentWarning",on:{"cancelClicked":function($event){_vm.$refs.declinedSpecialConsentWarning.hide(), (_vm.isLoading = false)},"okClicked":function($event){return _vm.submitConsent()}}},[_vm._l((_vm.declinedSpecialConsents),function(consent,i){return [_vm._v("\n        "+_vm._s(_vm.getDeclinedConsentWarningMessage(consent))),_c('br',{key:i})]}),_vm._v("\n      "+_vm._s(_vm._f("fromTextKey")('CONSENT_WARNING_DECLINED_2'))+"\n    ")],2),_vm._v(" "),_c('aula-modal',{ref:"onboardingError",attrs:{"show-cancel":false},on:{"okClicked":function($event){return _vm.onboardingRefresh()}}},[_vm._v("\n      "+_vm._s(_vm._f("fromTextKey")('ONBOARDING_WARNING_ERROR'))+"\n    ")]),_vm._v(" "),_c('step-up-notification'),_vm._v(" "),_vm._l((_vm.openModals),function(modalId){return _c('portal-target',{key:modalId,attrs:{"name":'modal-' + modalId}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }