
































































































import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { PropType } from 'vue';
import { FolderModel } from '../../../shared/models/folder.model';
import { CommonInboxModel } from '../../../shared/models/commonInbox.model';
import MessageInbox from './MessageInbox.vue';
import { NotificationModel } from '../../../shared/models/notification.model';
import { messagingService } from '../../../shared/services/api/messaging.service';
import Sidebar from '../../../shared/components/Sidebar/Sidebar.vue';
import SidebarContent from '../../../shared/components/Sidebar/SidebarContent.vue';
import SidebarGroup from '../../../shared/components/Sidebar/SidebarGroup.vue';
import SidebarGroupContent from '../../../shared/components/Sidebar/SidebarGroupContent.vue';
import SidebarGroupLabel from '../../../shared/components/Sidebar/SidebarGroupLabel.vue';
import SidebarMenu from '../../../shared/components/Sidebar/SidebarMenu.vue';

const MAX_LENGTH_FOLDER_NAME = 100;
export default {
  components: {
    SidebarMenu,
    SidebarGroupLabel,
    SidebarGroupContent,
    SidebarGroup,
    SidebarContent,
    Sidebar,
    MessageInbox,
  },
  props: {
    folders: { type: Array as PropType<FolderModel[]>, required: true },
    commonInboxes: { type: Array as PropType<CommonInboxModel[]>, required: true },
    selectedFolderId: { type: Number, default: null },
    selectedCommonInboxId: { type: Number, default: null },
    notifications: { type: Array as PropType<NotificationModel[]>, default: () => [] },
    collapsedInboxIds: { type: Array as PropType<number[]>, default: () => [] },
    defaultWidth: { type: String, default: '280px' },
  },
  emits: ['selectInbox', 'selectFolder', 'folderUpdated', 'inboxExpanded', 'resized'],
  data() {
    return {
      folderName: '',
      handlingFolderCommonInboxId: null,
      handlingFolderId: null,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    hasCommonInboxes() {
      return this.commonInboxes.length > 0;
    },
    isSelectedMainInbox() {
      return !this.selectedFolderId && !this.selectedCommonInboxId;
    },
    folderModalHeader() {
      if (this.handlingFolderId) {
        return this.$t('messages.rename_folder');
      }
      return this.$t('messages.new_folder');
    },
    isValidFolderName() {
      return this.folderName.length && this.folderName.length <= MAX_LENGTH_FOLDER_NAME;
    },
  },
  methods: {
    emitResized(width) {
      this.$emit('resized', width);
    },
    emitHidden() {
      this.$emit('hidden');
    },
    selectFolder({ folder, commonInboxId }) {
      this.$emit('selectFolder', { folder, commonInboxId });
    },
    selectInbox(commonInbox) {
      this.$emit('selectInbox', { commonInbox });
    },
    handleCreateFolderClick({ commonInboxId }) {
      this.handlingFolderCommonInboxId = commonInboxId;
      this.folderName = '';
      this.handlingFolderId = null;
      this.$refs.folderModal.show();
    },
    handleEditFolderClick({ folder, commonInboxId }) {
      this.handlingFolderCommonInboxId = commonInboxId;
      this.folderName = folder.name;
      this.handlingFolderId = folder.id;
      this.$refs.folderModal.show();
    },
    handleDeleteFolderClick({ folder, commonInboxId }) {
      this.handlingFolderCommonInboxId = commonInboxId;
      this.handlingFolderId = folder.id;
      this.$refs.folderDeletionModal.show();
    },
    handleInboxExpanded(commonInboxId, isExpanded) {
      this.$emit('inboxExpanded', commonInboxId, isExpanded);
    },
    confirmSavingFolderName() {
      if (this.handlingFolderId) {
        this.updateFolder();
      } else {
        this.createFolder();
      }
    },
    async createFolder() {
      if (!this.isValidFolderName) {
        return;
      }

      await messagingService.createFolder({
        folderName: this.folderName,
        commonInboxId: this.handlingFolderCommonInboxId,
      });
      this.$refs.folderModal.hide();
      this.$emit('folderUpdated', { commonInboxId: this.handlingFolderCommonInboxId });
      this.showToast(this.$t('messages.folder_created'));
    },
    async updateFolder() {
      if (!this.isValidFolderName) {
        return;
      }

      await messagingService.updateFolder({
        folderName: this.folderName,
        folderId: this.handlingFolderId,
      });
      this.$refs.folderModal.hide();
      this.$emit('folderUpdated', { commonInboxId: this.handlingFolderCommonInboxId });
      this.showToast(this.$t('messages.folder_renamed'));
    },
    async deleteFolder() {
      await messagingService.deleteFolder({ folderId: this.handlingFolderId });
      this.$refs.folderDeletionModal.hide();
      this.$emit('folderUpdated', { commonInboxId: this.handlingFolderCommonInboxId });
      this.showToast(this.$t('messages.folder_deleted'));
    },
    showToast(text) {
      this.$bvToast.toast(text, {
        solid: true,
        autoHideDelay: 3000,
        variant: 'primary',
      });
    },
  },
};
