<template>
  <section class="conversation">
    <div
      id="conversation-content"
      class="conversation-content-with-unread"
      :class="{ messagesSelectable: messagesSelectable }"
    >
      <div>
        <div v-if="messagesSelectable" class="message-multiselect">
          <b-row>
            <b-col class="select-all">
              <b-form-checkbox v-model="selectAll" @change="onSelectAllClicked">
                {{ 'MESSAGE_ATTACH_DOCUMENT_SELECT_ALL' | fromTextKey }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="actions">
              <b-btn variant="link" class="text-uppercase" @click="closeSelectAllMode">
                {{ $t('button.regret') }}
              </b-btn>
              <b-btn
                v-if="isCreatingSecureFile"
                variant="primary"
                class="text-uppercase"
                :disabled="selectedMessages.length === 0"
                @click="handleCreatingSecureFileClicked"
              >
                {{ $t('button.continue') }}
              </b-btn>
              <b-btn
                v-else
                variant="primary"
                class="text-uppercase"
                :disabled="selectedMessages.length === 0"
                @click="attachSelectedMessages"
              >
                {{ $t('button.associate') }}
              </b-btn>
            </b-col>
          </b-row>
        </div>
        <div class="clearfix" />
        <div class="more-unread-messages-container">
          <div
            v-show="showUnreadToaster && amountOfUnreadMessages > 4"
            ref="unreadToaster"
            class="more-unread-messages"
            @click="clickUnreadMessages()"
          >
            <i class="icon-Aula_arrow_new_item" />
            <template v-if="amountOfUnreadMessages >= 40">
              {{ 'MESSAGE_MANY_UNREAD_MESSAGES' | fromTextKey }}
            </template>
            <template v-else>
              {{ 'MESSAGE_UNREAD_MESSAGES_PART_1' | fromTextKey }}
              {{ amountOfUnreadMessages }}
              {{ 'MESSAGE_UNREAD_MESSAGES_PART_2' | fromTextKey }}.
            </template>
          </div>
        </div>
        <div class="conversation-content">
          <div v-if="sortedMessages.length > 0" class="text-left message-info">
            <div v-for="(message, index) in sortedMessages" :key="message.id" class="message-type-container">
              <component
                :is="getMessageComponentName(message.messageType)"
                :key="index"
                :ref="'message' + message.id"
                :message-selectable="messagesSelectable"
                :message="message"
                :shown-subscription="shownSubscription"
                :original-creator="sortedMessages[0].sender"
                :original-date-time="sortedMessages[0].sendDateTime"
                @openDocumentDrawer="openDocumentDrawer"
              />
              <div v-if="savedLastReadMessageId == message.id && index < sortedMessages.length - 1" class="container">
                <b-row class="mb-2 unreadFromHere">
                  <b-col cols="2" />
                  <b-col cols="8" class="p-0">
                    <span>{{ 'MESSAGE_TOOLBAR_FILTER_UNREAD' | fromTextKey }}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DocumentDrawer
      v-if="isDocumentFormShown"
      :id="selectedDocumentId"
      :type="selectedDocumentType"
      :is-edit-mode="editDrawer"
      @toggleEdit="editDrawer = true"
      @hidden="isDocumentFormShown = false"
      @save="handleDocumentSaved"
    />
    <documents-secure-list
      v-if="isShownDocumentsSecureList"
      :parent="parentTypes.EDITOR"
      :only-write-permisson="true"
      can-select-all-documents
      @onSelectDocuments="attachToSelectedFiles"
      @onCanceled="isShownDocumentsSecureList = false"
    />
    <aula-modal ref="fileErrorModal" :show-cancel="false" @okClicked="$refs.fileErrorModal.hide()">
      {{ 'UPLOAD_MODAL_ERROR_EXT_BODY' | fromTextKey }}
      <div v-for="(fileFormat, i) in authorizedFileFormats" :key="i">
        {{ fileFormat.fileFormat }}
      </div>
      <br />
      {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
      <div v-for="(fileName, i) in extFilesNotImported" :key="i">
        {{ fileName }}
      </div>
      <hr />
    </aula-modal>
    <AulaModal
      ref="documentTypeModal"
      :header-text-from-textkey="false"
      :header-text="$t('messages.save_as_secure_file')"
      @cancelClicked="$refs.documentTypeModal.hide()"
      @okClicked="confirmCreatingDocument"
    >
      <p>{{ $t('messages.choose_document_type') }}</p>
      <b-radio-group v-model="selectedDocumentType" stacked>
        <b-radio :value="documentTypes.RICHDOCUMENT" class="my-1">{{ $t('document.document') }}</b-radio>
        <b-radio :value="documentTypes.NOTE" class="my-1">{{ $t('document.note') }}</b-radio>
      </b-radio-group>
    </AulaModal>
  </section>
</template>

<script>
import { messageTypes } from '../../../shared/enums/messageTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { types } from '../../store/types/types';
import MessageTypeMessage from './MessageTypeMessage.vue';
import MessageTypeAutoReply from './MessageTypeAutoReply.vue';
import MessageTypeForward from './MessageTypeForward.vue';
import MessageTypeSystemForward from './MessageTypeSystemForward.vue';
import MessageTypeRecipientsAdded from './MessageTypeRecipientsAdded.vue';
import MessageTypeRecipientsRemoved from './MessageTypeRecipientsRemoved.vue';
import DocumentSecureList from '../documents/DocumentsPicker.vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import DocumentsFolderPicker from '../documents/DocumentsFolderPicker.vue';
import { documentTypes } from '../../../shared/enums/documentTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import $ from 'jquery';
import moment from 'moment-timezone';
import MessageTypeDeleted from './MessageTypeDeleted';
import { messageUtil } from '../../../shared/utils/messageUtil';
import DocumentDrawer from '../documents/DocumentDrawer.vue';
import MessageTypeSystemForwardSingleMessage from './MessageTypeSystemForwardSingleMessage.vue';
import MessageTypeSystemReply from './MessageTypeSystemReply.vue';

export default {
  props: {
    shownSubscription: Object,
    selectMode: { type: Boolean, default: false },
  },
  data: function () {
    return {
      showUnreadToaster: false,
      loadNewMessagesOnClickingUnreadToaster: false,
      selectAll: false,
      selectedMessagesCache: [],
      savedLastReadMessageId: null,
      extFilesNotImported: [],
      isDocumentFormShown: false,
      selectedDocumentId: null,
      selectedDocumentType: null,
      editDrawer: false,
      isShownDocumentsSecureList: false,
      documentTypes,
      portalRoles,
      parentTypes,
    };
  },
  computed: {
    ...mapGetters({
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      lastReadMessageId: types.MESSAGES_GET_LAST_READ,
      messages: types.MESSAGES_GET,
      moreMessagesExist: types.MESSAGES_GET_MORE_MESSAGES_EXITS,
      profile: types.GET_CURRENT_PROFILE,
      date: types.GET_DATE,
      isMobile: types.GET_IS_MOBILE,
      stepUpNotification: types.GET_ACTIVE_STEP_UP_NOTIFICATION,
      isCreatingSecureFile: types.MESSAGES_GET_CREATING_SECURE_FILE,
      messagesSelectable: types.MESSAGES_GET_IN_SELECT_MODE,
      folders: types.MESSAGES_GET_FOLDERS,
      hasPermission: types.HAS_PERMISSION,
      document: types.GET_DOCUMENTS_LIVE,
      authorizedFileFormats: types.GET_AUTHORIZED_FILE_FORMAT_LIST,
      isFileFormatValid: types.GET_VALID_FILE_FORMAT,
      notifications: types.GET_NOTIFICATIONS,
    }),
    selectedMessages() {
      return this.messages.filter(message => message.selected === true);
    },
    amountOfSelectedMessages() {
      return this.selectedMessages.length;
    },
    sortedMessages: function () {
      return [...this.messages].reverse();
    },
    firstUnreadMessage: function () {
      if (this.savedLastReadMessageId && this.savedLastReadMessageId.length > 0) {
        const lastReadMessageIndex = this.sortedMessages.findIndex(x => x.id == this.savedLastReadMessageId);
        const firstUnread = lastReadMessageIndex >= 0 ? this.sortedMessages[lastReadMessageIndex + 1] : false;
        if (firstUnread && this.$refs['message' + firstUnread.id]) {
          return (
            this.$refs['message' + firstUnread.id] &&
            this.$refs['message' + firstUnread.id][0] &&
            this.$refs['message' + firstUnread.id][0].$el
          );
        } else {
          if (this.$refs.hasOwnProperty('message' + this.savedLastReadMessageId)) {
            return (
              this.$refs['message' + this.savedLastReadMessageId] &&
              this.$refs['message' + this.savedLastReadMessageId][0] &&
              this.$refs['message' + this.savedLastReadMessageId][0].$el
            );
          } else {
            return false;
          }
        }
      }
      if (!this.moreMessagesExist) {
        const messagesKeys = Object.keys(this.messages);
        if (messagesKeys.length) {
          return (
            this.$refs['message' + this.messages[messagesKeys.slice(-1)[0]].id] &&
            this.$refs['message' + this.messages[messagesKeys.slice(-1)[0]].id][0] &&
            this.$refs['message' + this.messages[messagesKeys.slice(-1)[0]].id][0].$el
          );
        }
      }
      return false;
    },
    scrollTo() {
      return this.firstUnreadMessage ? this.firstUnreadMessage.offsetTop : 0;
    },
    amountOfUnreadMessages: function () {
      let unreadMessages = 0;
      if (this.savedLastReadMessageId) {
        unreadMessages =
          this.sortedMessages.length - this.sortedMessages.findIndex(x => x.id == this.savedLastReadMessageId) - 1;
      } else {
        unreadMessages = this.sortedMessages.length;
      }
      for (let i = unreadMessages; i--; ) {
        if (
          this.sortedMessages[i].messageType === messageTypes.RECIPIENTS_REMOVED ||
          this.sortedMessages[i].messageType === messageTypes.RECIPIENTS_ADDED
        ) {
          unreadMessages--;
        }
      }
      if (unreadMessages > 4) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showUnreadToaster = true;
      }
      return unreadMessages;
    },
  },
  methods: {
    handleDocumentSaved() {
      this.setIsCreatingSecureFile(false);
      this.setMessagesInSelectMode(false);
      this.setSelectAllMessages(false);
    },
    confirmCreatingDocument() {
      this.$refs.documentTypeModal.hide();
      this.selectedDocumentId = null;
      this.isDocumentFormShown = true;
    },
    getMessageComponentName(messageType) {
      if (messageType === messageTypes.MESSAGE_EDITED) {
        return messageTypes.MESSAGE;
      }
      return messageType;
    },
    openDocumentDrawer(data) {
      this.selectedDocumentId = data.documentId;
      this.selectedDocumentType = data.documentType;
      this.editDrawer = false;
      this.isDocumentFormShown = true;
      const notification = this.notifications.find(not => not.documentId == data.documentId);
      if (notification != null) {
        this.deleteNotifications({
          notifications: [
            {
              notificationId: notification.notificationId,
              institutionProfileId: notification.institutionProfileId,
            },
          ],
        });
      }
    },
    editElement(documentId, documentType) {
      this.$router.push({
        name: 'documentsSecureEdit',
        params: { documentId, documentType },
      });
    },
    onSelectAllClicked(isSelected) {
      this.setSelectAllMessages(isSelected);
    },
    discardMessage() {
      this.setCurrentDraftMessage('');
      this.$refs.cancelMessageModal.hide();
    },
    checkMessageAttachmentValid(selectedMessages) {
      let isAttachmentFileFormatValid = true;
      this.extFilesNotImported = [];
      for (const message of selectedMessages) {
        if (message.hasAttachments) {
          for (const attachment of message.attachments) {
            if (!attachment.document) {
              const fileName = attachment.file != null ? attachment.file.name : attachment.media.file.name;
              const fileNameSplit = fileName.split('.') || '';
              const ext = fileNameSplit[fileNameSplit.length - 1].toLowerCase();
              if (!this.isFileFormatValid(ext)) {
                isAttachmentFileFormatValid = false;
                this.extFilesNotImported.push(fileName);
              }
            }
          }
        }
      }
      return isAttachmentFileFormatValid;
    },
    selectDocumentToImport(isImportFileFormatValid) {
      if (!isImportFileFormatValid) {
        this.$refs.fileErrorModal.show();
      } else {
        this.isShownDocumentsSecureList = true;
      }
    },
    handleCreatingSecureFileClicked() {
      this.selectedDocumentType = documentTypes.RICHDOCUMENT;
      this.$refs.documentTypeModal.show();
    },
    attachSelectedMessages() {
      this.selectedMessagesCache = this.selectedMessages;
      let isAttachmentFileFormatValid = true;
      if (this.authorizedFileFormats.length == 0) {
        this.fetchAuthorisedFileFormats().then(() => {
          isAttachmentFileFormatValid = this.checkMessageAttachmentValid(this.selectedMessages);
          this.selectDocumentToImport(isAttachmentFileFormatValid);
        });
      } else {
        isAttachmentFileFormatValid = this.checkMessageAttachmentValid(this.selectedMessages);
        this.selectDocumentToImport(isAttachmentFileFormatValid);
      }
    },
    closeSelectAllMode() {
      this.switchMessagesToSelectMode(false);
      this.selectAll = false;
      this.setSelectAllMessages(false);
      this.setIsCreatingSecureFile(false);
    },
    removeUnreadToaster() {
      this.showUnreadToaster = false;
    },
    async attachToSelectedFiles(documents) {
      let messageIds = this.selectedMessagesCache.map(message => message.id);
      let commonInboxId = null;
      const secureDocumentPromises = [];

      if (this.chosenFolderAndMailOwner.mailOwnerId != null) {
        commonInboxId = this.chosenFolderAndMailOwner.mailOwnerId;
      }

      if (this.selectAll) {
        messageIds = null;
      }

      for (const document of documents) {
        secureDocumentPromises.push(
          this.attachMessagesIntoDocument({
            secureDocumentId: document.attachedSecureDocumentId,
            messageIds,
            threadId: this.shownSubscription.id,
            commonInboxId,
          })
        );
      }

      await Promise.all(secureDocumentPromises);
      this.isShownDocumentsSecureList = false;
      this.closeSelectAllMode();
    },
    clickUnreadMessages() {
      if (this.loadNewMessagesOnClickingUnreadToaster) {
        this.loadMoreMessages({
          scrollElement: this.$el.closest('.threads-container'),
        }).then(() => {
          setTimeout(() => {
            this.goToMessage();
          }, 500);
        });
      } else {
        this.goToMessage();
      }
    },
    goToMessage() {
      this.showUnreadToaster = false;
      $('.threads-container').scrollTop(0);
      let scrollTo = $('.message-info').offset().top - 200;
      if ($('.unreadFromHere').length > 0) {
        scrollTo = $('.unreadFromHere').offset().top - 200;
      }
      $('.threads-container').scrollTop(scrollTo);
      $(this.$el.closest('.conversation-holder')).animate({ scrollTop: scrollTo }, 500, () => {
        this.$nextTick(() => {
          if (this.moreMessagesExist && !this.$refs['message' + this.savedLastReadMessageId]) {
            this.showUnreadToaster = true;
            this.loadNewMessagesOnClickingUnreadToaster = true;
          }
        });
      });
    },
    hasBeenScrolledTo(el) {
      if (this.amountOfUnreadMessages > 1) {
        if (this.scrollTo >= el.scrollTop && this.firstUnreadMessage) {
          this.removeUnreadToaster();
        }
      }
    },
    unreadMessagesOnScreen() {
      if (this.scrollTo < this.$el.closest('.conversation-holder').scrollTop) {
        this.showUnreadToaster = true;
        this.loadNewMessagesOnClickingUnreadToaster = false;
      }
    },
    scrollMessagesToBottom() {
      const el = this.$el.closest('.threads-container');
      el.scrollTop = el.scrollHeight;
      if (window.innerWidth < 450) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
          window.scrollTo(0, el.scrollHeight - 150);
        } else {
          setTimeout(function () {
            $('html').animate({ scrollTop: el.scrollHeight - 150 });
          }, 500);
        }
      }
    },
    ...mapActions({
      saveMessage: types.MESSAGES_SAVE_DRAFT_MESSAGE,
      setCurrentDraftMessage: types.MESSAGES_EMPTY_CURRENT_DRAFT_MESSAGE,
      loadMoreMessages: types.LOAD_MORE_MESSAGES,
      attachMessagesIntoDocument: types.ATTACH_MESSAGES_INTO_DOCUMENT,
      fetchAuthorisedFileFormats: types.ACTIONS_CENTRAL_CONFIG_AUTHORIZED_FILE_FORMATS,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    ...mapMutations({
      setSelectAllMessages: types.MUTATE_SELECTALL_MESSAGES,
      setIsSelectedAllMessages: types.MUTATE_IS_SELECTED_ALL_MESSAGES,
      switchMessagesToSelectMode: types.MUTATE_MESSAGES_IN_SELECT_MODE,
      setIsCreatingSecureFile: types.MUTATE_MESSAGES_CREATING_SECURE_FILE,
      setMessagesInSelectMode: types.MUTATE_MESSAGES_IN_SELECT_MODE,
    }),
  },
  watch: {
    amountOfSelectedMessages() {
      const selectableMessages = this.messages.filter(message =>
        messageUtil.getIsSelectableMessageType(message.messageType)
      );
      this.selectAll = this.amountOfSelectedMessages === selectableMessages.length;
      this.setIsSelectedAllMessages(this.selectAll);
    },
    messages() {
      this.$nextTick(() => {
        this.unreadMessagesOnScreen();
      });
    },
    lastReadMessageId() {
      this.savedLastReadMessageId = this.lastReadMessageId;
    },
    sortedMessages(newMessages, oldMessages) {
      const newLastSendDateTime = newMessages[newMessages.length - 1].sendDateTime;
      const oldLastSendDateTime = oldMessages[oldMessages.length - 1].sendDateTime;
      if (moment(newLastSendDateTime).isAfter(oldLastSendDateTime)) {
        this.$nextTick(() => {
          this.scrollMessagesToBottom();
        });
      }
    },
    showUnreadToaster: {
      handler(shown) {
        const showMoreButton =
          !(shown && this.amountOfUnreadMessages > 0) && this.$refs['message' + this.savedLastReadMessageId];
        this.$emit('toggleLoadMoreButton', showMoreButton);
      },
      immediate: true,
    },
    stepUpNotification() {
      if (this.stepUpNotification.showStepUpNotification) {
        if (!this.shownSubscription.leaveTime) {
          this.saveMessage({
            id: this.shownSubscription.id,
            navLocal: this.$route.name === 'uniqueThread',
          });
        }
      }
    },
  },
  beforeDestroy() {
    if (!this.shownSubscription.leaveTime) {
      this.saveMessage({
        id: this.shownSubscription.id,
        navLocal: this.$route.name === 'uniqueThread',
      });
    }
    this.closeSelectAllMode();
  },
  mounted: function () {
    this.scrollMessagesToBottom();
    this.unreadMessagesOnScreen();
    this.savedLastReadMessageId = this.lastReadMessageId;
    this.$root.$on(
      'scrolledTo',
      function (el) {
        this.hasBeenScrolledTo(el);
      }.bind(this)
    );
  },
  components: {
    DocumentDrawer,
    Message: MessageTypeMessage,
    AutoReply: MessageTypeAutoReply,
    Forward: MessageTypeForward,
    RecipientsAdded: MessageTypeRecipientsAdded,
    RecipientsRemoved: MessageTypeRecipientsRemoved,
    MessageDeleted: MessageTypeDeleted,
    SystemForward: MessageTypeSystemForward,
    SystemReply: MessageTypeSystemReply,
    SystemForwardSingleMessage: MessageTypeSystemForwardSingleMessage,
    DocumentsFolderPicker,
    'documents-secure-list': DocumentSecureList,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/messages/_messagetype.scss';

.conversation {
  .message-type-container {
    position: relative;
  }
  .message-info {
    font-size: 15px;
    padding: 0 10%;
    @include breakpoint-lg-down() {
      padding: 0 24px;
    }
  }

  .more-unread-messages {
    font-size: 13px;
    position: fixed;
    top: 355px;
    padding: 15px;
    background-color: $color-grey-light;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(193, 192, 192, 0.5);
    z-index: 1;
    cursor: pointer;
  }

  .more-unread-messages-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .conversation-content-with-unread {
    position: relative;
    @include breakpoint-tablet-landscape() {
      padding-bottom: 0 !important;
    }
    @include breakpoint-tablet-portrait() {
      padding-bottom: 0 !important;
    }
  }

  .messagesSelectable /deep/ .row {
    cursor: pointer;
  }

  .message-multiselect {
    position: sticky;
    top: 57px;
    padding: 20px;
    background-color: var(--color-grey-light);
    z-index: 2;

    .actions {
      display: flex;
      gap: 12px;
      justify-content: flex-end;
    }
  }

  .unreadFromHere .col-8 {
    span {
      margin-left: 20px;
      background: $color-grey-light;
      color: $color-help-text;
      display: inline-block;
      padding: 4px;
      position: relative;
      z-index: 2;
      font-size: 12px;
    }
    &:after {
      display: block;
      height: 2px;
      position: relative;
      z-index: 1;
      top: -13px;
      content: '';
      border-top: 1px solid $color-help-text;
    }
  }

  .btn-link {
    cursor: pointer;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}

article {
  &.message {
    padding: 2px 0px;
  }
  @include breakpoint-xs-up() {
    &.message {
      padding: 18px 0px;

      &.message-from-me {
        width: 90%;
        margin-left: 10%;
      }

      &.message-from-person {
        max-width: 90%;
      }
    }
  }

  @include breakpoint-sm-up() {
    &.message {
      &.message-from-me,
      &.message-from-person {
        max-width: 80%;
      }

      &.message-from-me {
        margin-left: 20%;
      }
    }
  }
}
</style>
