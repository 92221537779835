<template>
  <aula-modal
    ref="modal"
    header-text="MESSAGE_MOVE_TO_FOLDER"
    :always-visible="true"
    :hide-footer="true"
    :disable-submit="submitButtonIsDisabled"
    :width="!isMobile ? '500px' : ''"
    class="message-move-to-folder"
    @closeClicked="emitVisibility(false)"
    @cancelClicked="emitVisibility(false)"
    @okClicked="moveMessageToFolder"
  >
    <p v-html="descriptionMovingFolderHtml" />
    <MessageInbox
      :folders="normalFolders"
      :label="inboxLabel"
      :selected-folder-id="selectedFolderId"
      :selected="selectedFolderId == null"
      @select="selectInbox"
      @selectFolder="selectFolder"
    />
    <div v-if="!isMobile" class="modal-footer">
      <b-btn variant="link" @click="emitVisibility(false)">
        {{ 'MESSAGE_BUTTON_CANCEL' | fromTextKey }}
      </b-btn>
      <b-btn size="sm" variant="primary" :disabled="submitButtonIsDisabled" @click="moveMessageToFolder">
        {{ 'MESSAGE_MOVE' | fromTextKey }}
      </b-btn>
    </div>
  </aula-modal>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { folderTypes } from '../../../shared/enums/folderTypes';
import MessageInbox from './MessageInbox.vue';
import isNumber from 'lodash/isNumber';

export default {
  components: { MessageInbox },
  props: {
    threadIds: {
      type: Array,
      default: () => [],
    },
    subscriptionIds: {
      type: Array,
      default: () => [],
    },
    folders: {
      type: Array,
      required: true,
    },
    chosenFolderAndMailOwner: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      selectedFolderId: null,
      messageOwnerTypes: messageOwnerTypes,
      currentFolder: this.$store.state.messages.currentFolder,
    };
  },
  computed: {
    submitButtonIsDisabled: function () {
      return this.selectedFolderId === this.chosenFolderAndMailOwner.folderId;
    },
    ...mapGetters({
      hasPermission: types.HAS_PERMISSION,
      isMobile: types.GET_IS_MOBILE,
      latestThreadsMoved: types.MESSAGES_GET_LATEST_MOVED_THREADS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      isInSearch: types.MESSAGES_GET_IN_SEARCH,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
    }),
    inboxLabel() {
      return this.chosenFolderAndMailOwner.commonInboxName || this.$t('messages.inboxes');
    },
    hasPrimarySubscription() {
      return this.subscriptions.some(subscription => this.subscriptionIds.includes(subscription.primarySubscriptionId));
    },
    normalFolders() {
      return this.folders.filter(folder => folder.type === folderTypes.NORMAL);
    },
    descriptionMovingFolderHtml() {
      if (this.hasPrimarySubscription) {
        return this.$t('messages.warning_moving_of_primary_thread');
      }
      return this.$t('messages.warning_moving_of_regular_thread');
    },
  },
  mounted() {
    this.$refs.modal.show();
    this.setDefaultSelectedFolder();
  },
  methods: {
    ...mapActions({
      moveMessagesToFolderAction: types.MOVE_THREADS_TO_FOLDER,
      updateThreadViewAction: types.UPDATE_THREADS_VIEW,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      resetThreadBundles: types.RESET_THREAD_BUNDLES,
    }),
    ...mapMutations({
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
    }),
    selectInbox() {
      this.selectedFolderId = null;
    },
    selectFolder({ folder }) {
      this.selectedFolderId = folder.id;
    },
    async moveMessageToFolder() {
      this.$router.push({ name: 'messages' });
      await this.moveMessagesToFolderAction({
        threadIds: this.threadIds,
        subscriptionIds: this.subscriptionIds,
        folderId: this.selectedFolderId,
      });
      const successMessage =
        this.threadIds.length + this.subscriptionIds.length > 1
          ? 'SUCCESS_TOAST_MESSAGES_MOVE_MULTI_THREADS'
          : 'SUCCESS_TOAST_MESSAGES_MOVE_THREAD';
      this.setSuccessMessage(successMessage);
      this.resetThreadBundles();

      if (!this.isInSearch) {
        await this.updateThreadViewAction({
          folderId: this.chosenFolderAndMailOwner.folderId,
          filterType: this.chosenFolderAndMailOwner.filter,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
          sort: this.chosenFolderAndMailOwner.sort,
          order: this.chosenFolderAndMailOwner.order,
        });
      }

      this.emitVisibility(false);
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    // This component should be used as a child component. By emitting a Bool to the parent about its visibility
    // the parent can remove this component totally and not just hide it.
    emitVisibility(value) {
      this.$emit('hide', value);
    },
    setDefaultSelectedFolder() {
      if (isNumber(this.chosenFolderAndMailOwner.folderId)) {
        this.selectedFolderId = this.chosenFolderAndMailOwner.folderId;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.message-inbox {
  max-width: 500px;
  /deep/.expand-button,
  /deep/.add-folder-button,
  /deep/.sub-folders .folder-actions {
    display: none;
  }
}
</style>
