


























































































































































































































/* eslint-disable */
import { portalRoles } from '../../../shared/enums/portalRoles';
import Vue, { PropType } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import Avatar from '../../../shared/components/Avatar.vue';
import Icon from '../../../shared/components/Icon.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import ContactInformationUploadAvatarForm from '../contacts/ContactInformationUploadAvatarForm.vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: { ContactInformationUploadAvatarForm, AulaButton, Icon, Avatar },
  props: {
    instProfile: Object,
    profileIndex: Number,
    institutionProfileIndex: Number,
    childIndex: Number,
    showPortalRole: { type: Boolean, default: false },
    showInstName: { type: Boolean, default: false },
    isChild: { type: Boolean, default: false },
    institutionProfileMasterData: { type: Object as PropType<InstitutionProfileModel>, default: null },
    isRelatedProfile: {type: Boolean, default: false},
    isEditableRelatedProfile: { type: Boolean, default: false },
  },
  emits: ['emitAvatarUpload'],
  data: function () {
    return {
      orginalProfile: {},
      aulaContactInfo: {},
      portalRoles: portalRoles,
      disableSubmitButton: {
        homePhoneNumber: false,
        mobilePhoneNumber: false,
        workPhoneNumber: false,
        email: false,
      },
      Vue: Vue,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    canEditAvatar() {
      return this.institutionProfileMasterData?.currentUserCanEditProfilePicture;
    },
    isEmployee() {
      return this.instProfile.role === portalRoles.EMPLOYEE;
    },
    isHomePhoneNumberValid() {
      if (!this.$options.filters.isPhoneNumber(this.instProfile.homePhoneNumber) && this.instProfile.homePhoneNumber) {
        this.disableSubmitButton.homePhoneNumber = true;
        return false;
      } else {
        this.disableSubmitButton.homePhoneNumber = false;
        return true;
      }
    },
    isMobilePhoneNumberValid() {
      if (
        !this.$options.filters.isPhoneNumber(this.instProfile.mobilePhoneNumber) &&
        this.instProfile.mobilePhoneNumber
      ) {
        this.disableSubmitButton.mobilePhoneNumber = true;
        return false;
      } else {
        this.disableSubmitButton.mobilePhoneNumber = false;
        return true;
      }
    },
    isWorkPhoneNumberValid() {
      if (!this.$options.filters.isPhoneNumber(this.instProfile.workPhoneNumber) && this.instProfile.workPhoneNumber) {
        this.disableSubmitButton.workPhoneNumber = true;
        return false;
      } else {
        this.disableSubmitButton.workPhoneNumber = false;
        return true;
      }
    },
    isEmailValid() {
      if (!this.$options.filters.isEmail(this.instProfile.email) && this.instProfile.email) {
        this.disableSubmitButton.email = true;
        return false;
      } else {
        this.disableSubmitButton.email = false;
        return true;
      }
    },
  },
  watch: {
    disableSubmitButton: {
      handler: function () {
        let isDisabled =
          this.disableSubmitButton.homePhoneNumber ||
          this.disableSubmitButton.mobilePhoneNumber ||
          this.disableSubmitButton.email;
        if (!this.isChild) {
          isDisabled = isDisabled || this.disableSubmitButton.workPhoneNumber;
        }
        this.$emit('disableSubmitButton', isDisabled, this.instProfile.id);
      },
      deep: true,
    },
  },
  mounted() {
    this.orginalProfile = cloneDeep(this.instProfile);
  },
  methods: {
    handleAvatarSaved() {
      this.$refs.uploadAvatarModal.hide();
      this.$emit('emitAvatarUpload');
    },
    updateProfile(attribute) {
      if (this.isChild) {
        this.$parent.userProfiles.filter(profile => profile.portalRole === portalRoles.GUARDIAN)[
          this.profileIndex
        ].children[this.childIndex].institutionProfile[attribute] = this.instProfile[attribute];
      } else {
        this.$parent.userProfiles[this.profileIndex].institutionProfiles[this.institutionProfileIndex][attribute] =
          this.instProfile[attribute];
      }
      this.orginalProfile[attribute] = this.instProfile[attribute];
    },
    showSubmitButton(attribute) {
      return this.orginalProfile[attribute] == this.instProfile[attribute];
    },
  },
};
