















































import AulaButtons from '../../../shared/components/AulaButtons.vue';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { PropType } from 'vue';
import { ThreadPropsModel } from '../../../shared/PageFragments/ThreadViews/threadProps.model';

export default {
  components: { AulaButton, Icon, AulaButtons },
  props: {
    thread: { type: Object as PropType<ThreadPropsModel>, required: true },
  },
  emits: ['toggleMuteStatus', 'toggleImportant', 'toggleReadStatus', 'moveToFolder', 'delete', 'forward'],
  data() {
    return {
      isShownMoreOptions: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    toggleReadStatusText() {
      if (this.thread.read) {
        return this.$t('messages.unread');
      }
      return this.$t('messages.read');
    },
    numberOfSlots() {
      return this.thread.draft ? 1 : 3;
    },
  },
  methods: {
    emitToggleMuteStatus() {
      this.isShownMoreOptions = false;
      this.$emit('toggleMuteStatus');
    },
    emitToggleImportantStatus() {
      this.isShownMoreOptions = false;
      this.$emit('toggleImportant');
    },
    emitToggleReadStatus() {
      this.isShownMoreOptions = false;
      this.$emit('toggleReadStatus');
    },
    emitMoveToFolder() {
      this.isShownMoreOptions = false;
      this.$emit('moveToFolder');
    },
    emitDelete() {
      this.isShownMoreOptions = false;
      this.$emit('delete');
    },
    emitForward() {
      this.isShownMoreOptions = false;
      this.$emit('forward');
    },
    showMoreOptions() {
      this.isShownMoreOptions = true;
    },
  },
};
