<template>
  <div class="presence-activity-list">
    <div v-if="isMobile" class="mobile-activity-update">
      <template v-if="isEditingMode">
        <div class="activity-update-mobile">
          {{ getStatistic }}

          <AulaButtons class="action-button">
            <AulaButton id="cancelEditing" variant="link" @click="cancelEdit">
              {{ 'CANCEL' | fromTextKey }}
            </AulaButton>
            <AulaButton id="showEditingForm" variant="primary" class="ml-2 px-3" @click="showEditingModal()">
              {{ 'PRESENCE_DASHBOARD_UPDATE_STATUS' | fromTextKey }}
            </AulaButton>
          </AulaButtons>
        </div>
        <div class="check-all">
          <span class="mr-3">{{ 'PRESENCE_DASHBOARD_SELECT_ALL' | fromTextKey }}</span>
          <b-form-checkbox :checked="checkAllRegistration" @change="checkedAllChildren" />
        </div>
      </template>
      <template v-else>
        <div class="mobile-activity-statistic">
          {{ getStatistic }}
          <AulaButton id="editStates" variant="primary" class="pull-right" @click="isEditingMode = true">
            {{ 'BUTTON_EDIT_SMALL' | fromTextKey }}
          </AulaButton>
        </div>
      </template>
    </div>
    <div class="activity-container">
      <div id="activityList" class="activity-list">
        <b-row v-if="!isMobile" class="activity-option row mb-3">
          <b-col class="statistic align-self-end" cols="4">
            {{ getStatistic }}
            <template v-if="!isEditingMode">
              -
              <b-btn variant="link" class="refresh-button" @click="refreshActivityList">
                {{ 'PRESENCE_DASHBOARD_REFRESH_LIST' | fromTextKey }}
              </b-btn>
            </template>
          </b-col>
          <b-col cols="8" class="text-right">
            <template v-if="!isEditingMode">
              <b-btn variant="primary" class="edit-button" @click="isEditingMode = !isEditingMode">
                {{ 'BUTTON_EDIT' | fromTextKey }}
              </b-btn>
            </template>
            <template v-else>
              <b-btn variant="link" class="pl-3 mt-3 pr-3 mr-3" @click="cancelEdit">
                {{ 'CANCEL' | fromTextKey }}
              </b-btn>
              <b-btn variant="primary" class="edit-button" @click="showEditingModal()">
                {{ 'PRESENCE_DASHBOARD_UPDATE_STATUS' | fromTextKey }}
              </b-btn>
            </template>
          </b-col>
        </b-row>
        <div class="children-activities">
          <div v-if="!isMobile" class="activity-data header">
            <div class="user-icon header mr-0" />
            <div
              class="user-name header"
              :class="getSortCss(activityListSortTypes.NAME)"
              :aria-label="
                (filterOptions.orderDirection == orderDirections.ASCENDING
                  ? 'ARIA_LABEL_PRESENCE_ACITIVITY_SORT_DESCENDING_BY_NAME'
                  : 'ARIA_LABEL_PRESENCE_ACITIVITY_SORT_ASCENDING_BY_NAME') | fromTextKey
              "
              @click="sortUpdated(activityListSortTypes.NAME, filterOptions.orderDirection)"
            >
              <span tabindex="0">{{ 'ACTIVITY_CHILDREN' | fromTextKey }}</span>
            </div>
            <div
              class="activity-status header"
              :aria-label="
                (filterOptions.orderDirection == orderDirections.ASCENDING
                  ? 'ARIA_LABEL_PRESENCE_ACITIVITY_SORT_DESCENDING_BY_STATUS'
                  : 'ARIA_LABEL_PRESENCE_ACITIVITY_SORT_ASCENDING_BY_STATUS') | fromTextKey
              "
              :class="getSortCss(activityListSortTypes.STATUS)"
              @click="sortUpdated(activityListSortTypes.STATUS, filterOptions.orderDirection)"
            >
              <span tabindex="0">{{ 'ACTIVITY_STATUS' | fromTextKey }}</span>
            </div>
            <div
              class="activity-name mr-0 header"
              :aria-label="
                (filterOptions.orderDirection == orderDirections.ASCENDING
                  ? 'ARIA_LABEL_PRESENCE_ACITIVITY_SORT_DESCENDING_BY_NEXT_ACTIVITIES'
                  : 'ARIA_LABEL_PRESENCE_ACITIVITY_SORT_ASCENDING_BY_NEXT_ACTIVITIES') | fromTextKey
              "
              :class="getSortCss(activityListSortTypes.NEXT_ACTIVITY_DATE)"
              @click="sortUpdated(activityListSortTypes.NEXT_ACTIVITY_DATE, filterOptions.orderDirection)"
            >
              <span tabindex="0">{{ 'ACTIVITY_NAME' | fromTextKey }}</span>
            </div>
            <div class="header">
              <b-btn
                variant="link"
                :aria-label="
                  (showAllActivities
                    ? 'PRESENCE_ACTIVITY_LIST_SHOW_LESS_CHILDREN_ACTIVITIES'
                    : 'PRESENCE_ACTIVITY_LIST_SHOW_ALL_CHILDREN_ACTIVITIES') | fromTextKey
                "
                @click="toggleAllRows"
              >
                <i
                  :class="
                    showAllActivities
                      ? 'pull-right icon-aula-up-double-arrow'
                      : 'pull-right icon-aula-down-double-arrow'
                  "
                />
              </b-btn>
            </div>
            <div v-if="isEditingMode" class="activity-checkbox header">
              <b-form-checkbox v-model="checkAllRegistration" @click.native.stop @change="checkedAllChildren">
                {{ 'PRESENCE_DASHBOARD_SELECT_ALL' | fromTextKey }}
              </b-form-checkbox>
            </div>
          </div>
          <aula-spinner v-if="isLoading" />
          <div
            v-for="activity in activityList.activities"
            v-show="!isLoading"
            :key="'list' + activity.uniStudent.id"
            :class="getActivityCssClass(activity)"
          >
            <!-- MOBILE TABLE -->
            <template v-if="isMobile">
              <div class="user-icon">
                <b-row>
                  <b-col class="user-status-mobile">
                    <b-btn
                      variant="link"
                      :aria-label="'PRESENCE_VIEW_CHILD_CONTACT_PAGE' | fromTextKey"
                      @click.stop="viewChildContact(activity.uniStudent.id)"
                    >
                      <template v-if="activity.uniStudent.profilePicture">
                        <AulaImg :img-url="activity.uniStudent.profilePicture.url" class="opacity user-img" />
                      </template>
                      <template v-else>
                        <div class="initial-name" @click.stop="viewChildContact(activity.uniStudent.id)">
                          {{ activity.uniStudent.shortName }}
                        </div>
                      </template>
                      <div class="phone">
                        <Icon :name="iconClassEnum.PHONE_2" />
                      </div>
                    </b-btn>
                    <div v-button @click="showEditingModal(activity.presenceRegistrationId)">
                      <div class="status-icon" :class="showPresenceState(activity).class">
                        <i :class="showPresenceState(activity).icon" />
                      </div>
                      <div
                        class="user-details-mobile"
                        :class="{ 'push-details-away': getActivityHasContentOnRightSide(activity) }"
                      >
                        <div class="child-name">
                          {{ activity.uniStudent.name }}
                          <span class="metadata"> ({{ activity.uniStudent.metadata }})</span>
                        </div>
                        <strong>{{ showPresenceState(activity).label }}</strong>
                      </div>
                      <b-btn
                        v-if="(activity.comment || activity.note) && !isEditingMode"
                        variant="link"
                        class="activity-message"
                        :aria-label="'PRESENCE_DASHBOARD_VIEW_MESSAGE' | fromTextKey"
                        @click.stop="showComment(activity)"
                      >
                        <i class="icon-Aula_note" />
                      </b-btn>
                      <i
                        v-if="activity.isEmphasized && !isEditingMode && !activity.comment"
                        class="alert alert-danger"
                      />
                      <div v-if="isEditingMode" class="activity-checkbox" @click.stop>
                        <b-form-checkbox
                          v-model="selectedRegistrationIds"
                          :value="activity.presenceRegistrationId"
                          :aria-label="'SELECT' | fromTextKey"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <hr class="m-0" />
                <div
                  v-if="
                    activity.presenceState !== presenceStates.SICK &&
                    activity.presenceState !== presenceStates.REPORTED_ABSENT
                  "
                  class="activities-mobile"
                >
                  <b-btn
                    variant="link"
                    class="pull-right"
                    :aria-label="
                      (activity.showAll
                        ? 'PRESENCE_ACTIVITY_LIST_SHOW_LESS_CHILD_ACTIVITIES'
                        : 'PRESENCE_ACTIVITY_LIST_SHOW_ALL_CHILD_ACTIVITIES')
                        | fromTextKey({ childName: activity.uniStudent.name })
                    "
                    @click="toggleAllActivities(activity)"
                  >
                    <i :class="activity.showAll ? 'icon-Aula_up-arrow' : 'icon-Aula_down-arrow'" />
                  </b-btn>
                  <template v-for="(pastActivity, i) in getActivities(activity).pastActivities">
                    <div v-if="activity.showAll" :key="'past' + i" class="past-activity">
                      {{ getActivityLabelName(activity, pastActivity) }}
                    </div>
                  </template>
                  <template v-for="(futureActivity, index) in getActivities(activity).futureActivities">
                    <div
                      v-if="index === 0 || activity.showAll"
                      :key="'future' + index"
                      :class="{
                        'future-activity': true,
                        active: index === 0,
                        emphasized: index === 0 && activity.isEmphasized,
                      }"
                    >
                      {{ getActivityLabelName(activity, futureActivity) }}
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <!-- DESKTOP TABLE -->
            <template v-if="!isMobile">
              <b-btn
                tabindex="0"
                :aria-label="`${activity.uniStudent.name} ${activity.uniStudent.metadata}`"
                class="cell user-icon"
                @click="viewChildContact(activity.uniStudent.id)"
              >
                <b-btn
                  variant="link"
                  class="phone"
                  :aria-label="'PRESENCE_VIEW_CHILD_CONTACT_PAGE' | fromTextKey"
                  @click.stop="viewChildContact(activity.uniStudent.id)"
                >
                  <i class="icon-phone-2" />
                </b-btn>
                <template v-if="activity.uniStudent.profilePicture">
                  <AulaImg class="opacity user-img" :img-url="activity.uniStudent.profilePicture.url" />
                </template>
                <template v-else>
                  <div class="initial-name" @click.stop="viewChildContact(activity.uniStudent.id)">
                    {{ activity.uniStudent.shortName }}
                  </div>
                </template>
                <div class="phone">
                  <Icon :name="iconClassEnum.PHONE_2" />
                </div>
              </b-btn>
              <div
                v-button
                class="cell user-name d-flex align-items-start"
                @click="showEditingModal(activity.presenceRegistrationId)"
              >
                <strong> {{ activity.uniStudent.name }}</strong> ({{ activity.uniStudent.metadata }})
                <b-btn
                  v-if="activity.comment || activity.note"
                  variant="link"
                  class="activity-message"
                  :aria-label="'PRESENCE_DASHBOARD_VIEW_MESSAGE' | fromTextKey"
                  @click.stop="showComment(activity)"
                >
                  <i class="icon-Aula_note" />
                  <template v-if="isMobile">
                    {{ 'PRESENCE_DASHBOARD_VIEW_MESSAGE' | fromTextKey }}
                  </template>
                </b-btn>
                <i v-if="activity.isEmphasized" class="alert alert-danger ml-auto" />
              </div>
              <div class="cell activity-status" @click="showEditingModal(activity.presenceRegistrationId)">
                <div class="status-icon" :class="showPresenceState(activity).class">
                  <i :class="showPresenceState(activity).icon" />
                </div>
                <div class="status-label">
                  {{ showPresenceState(activity).label }}
                </div>
              </div>
              <div class="cell activity-name" @click="showEditingModal(activity.presenceRegistrationId)">
                <template
                  v-if="
                    activity.presenceState !== presenceStates.SICK &&
                    activity.presenceState !== presenceStates.REPORTED_ABSENT
                  "
                >
                  <template v-for="(pastActivity, i) in getActivities(activity).pastActivities">
                    <div
                      v-if="i === getActivities(activity).pastActivities.length - 1 || activity.showAll"
                      :key="'past' + i"
                      class="past-activity"
                    >
                      {{ getActivityLabelName(activity, pastActivity) }}
                    </div>
                  </template>
                  <template v-for="(futureActivity, j) in getActivities(activity).futureActivities">
                    <div
                      v-if="j === 0 || activity.showAll"
                      :key="'future' + j"
                      :class="{
                        'future-activity': true,
                        active: j === 0,
                        emphasized: j === 0 && activity.isEmphasized,
                      }"
                    >
                      {{ getActivityLabelName(activity, futureActivity) }}
                    </div>
                  </template>
                </template>
              </div>
              <div class="cell activity-toggle">
                <b-btn
                  variant="link"
                  class="pull-right"
                  :aria-label="
                    (activity.showAll
                      ? 'PRESENCE_ACTIVITY_LIST_SHOW_LESS_CHILD_ACTIVITIES'
                      : 'PRESENCE_ACTIVITY_LIST_SHOW_ALL_CHILD_ACTIVITIES')
                      | fromTextKey({ childName: activity.uniStudent.name })
                  "
                  @click="toggleAllActivities(activity)"
                >
                  <i :class="activity.showAll ? 'icon-Aula_up-arrow' : 'icon-Aula_down-arrow'" />
                </b-btn>
              </div>
              <div v-if="isEditingMode" class="cell activity-checkbox">
                <b-form-checkbox
                  v-model="selectedRegistrationIds"
                  :value="activity.presenceRegistrationId"
                  :aria-label="'SELECT' | fromTextKey"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
      <aula-spinner v-if="isLoadingMore" />
      <div
        v-if="departments.length > 0 && activityList.activities != null && activityList.activities.length == 0"
        class="mt-2 ml-2"
      >
        {{ 'PRESENCE_WARNING_NO_RESULTS_FOUND' | fromTextKey }}
      </div>
      <div v-if="departments.length === 0" class="px-2 px-lg-0">
        {{ 'PRESENCE_WARNING_NOT_MEMBER_OF_PRESENCE' | fromTextKey }}
      </div>
    </div>
    <update-children-status-form
      ref="updateChildrenStatusForm"
      @onClosed="closeUpdateStatusForm"
      @uriToLong="$refs.tooManyChildrenSelected.show()"
    />
    <aula-modal
      ref="activityComment"
      :header-text="activityComment && activityComment.comment ? 'PRESENCE_COMMENT' : 'PRESENCE_DASHBOARD_COMMENT'"
      :show-cancel="false"
      @okClicked="$refs.activityComment.hide()"
    >
      <template v-if="activityComment != null">
        <template v-if="!!activityComment.comment"> {{ activityComment.comment }}<br /><br /> </template>
        <template v-if="!!activityComment.note">
          <strong>{{ 'PRESENCE_SPARE_ACTIVITY_COMMENT' | fromTextKey }}</strong
          ><br />
          {{ activityComment.note }}
        </template>
      </template>
    </aula-modal>
    <aula-modal
      ref="incompatibleWarning"
      @cancelClicked="$refs.incompatibleWarning.hide()"
      @okClicked="
        $refs.incompatibleWarning.hide();
        updateStatus();
      "
    >
      {{ 'PRESENCE_WARNING_INCOMPATIBLE_STATUS' | fromTextKey }}<br />
      <span v-for="(name, index) in incompatibleRegistrationNames" :key="index" class="d-block">
        {{ name }}
      </span>
      {{ 'PRESENCE_WARNING_INCOMPATIBLE_STATUS_2' | fromTextKey }}
    </aula-modal>
    <aula-modal ref="tooManyChildrenSelected" :show-cancel="false" @okClicked="$refs.tooManyChildrenSelected.hide()">
      {{ 'TOO_MANY_CHILDREN_SELECTED' | fromTextKey }}
    </aula-modal>
  </div>
</template>
<script>
import { types } from '../../store/types/types';
import { presenceStates } from '../../../shared/enums/presenceStates';
import { exitTypes } from '../../../shared/enums/exitTypes';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import UpdateChildrenStatusForm from './UpdateChildrenStatusForm';
import Vue from 'vue';
import $ from 'jquery';
import { activityListSortTypes } from '../../../shared/enums/activityListSortTypes';
import { orderDirections } from '../../../shared/enums/orderDirections';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { presenceModules } from '../../../shared/enums/presenceModules';
import { presenceModuleStatus } from '../../../shared/enums/presenceModuleStatus';
import { presenceDashboardContext } from '../../../shared/enums/presenceDashboardContext';
import AulaImg from '../../../shared/components/AulaImg';
import AulaButtons from '../../../shared/components/AulaButtons';
import AulaButton from '../../../shared/components/AulaButton';
import Icon from '../../../shared/components/Icon';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import Button from '../../../shared/directives/button';

export default {
  directives: { Button },
  data: function () {
    return {
      Vue: Vue,
      exitTypes: exitTypes,
      orderDirections: orderDirections,
      presenceStates: presenceStates,
      activityListSortTypes: activityListSortTypes,
      isLoading: false,
      isLoadingMore: false,
      activityPerPage: 20,
      activeSort: activityListSortTypes.NEXT_ACTIVITY_DATE,
      showAllActivities: false,
      activityComment: null,
      selectedRegistrationIds: [],
      isEditingMode: false,
      compatibleRegistrationsToUpdate: [],
      incompatibleRegistrationsToUpdate: [],
      incompatibleRegistrationNames: [],
      changedStatus: null,
      checkAllRegistration: false,
      reloadInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      getPresenceFilters: types.GET_PRESENCE_FILTERS,
      activityList: types.GET_ACTIVITY_LIST,
      hasMoreActivity: types.GET_HAS_MORE_ACTIVITY,
      profile: types.GET_CURRENT_PROFILE,
      departments: types.GET_DEPARTMENTS,
      selectedDepartment: types.GET_SELECTED_DEPARTMENT,
      filterOptions: types.GET_FILTER_OPTIONS,
      states: types.GET_PRESENCE_STATES,
      activities: types.GET_PRESENCE_ACTIVITIES,
      getDepartments: types.GET_DEPARTMENTS,
      presenceConfiguration: types.GET_PRESENCE_CONFIGURATION,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
    getStatistic() {
      let statistic = '';
      if (this.isEditingMode) {
        statistic = Vue.filter('fromTextKey')('PRESENCE_DASHBOARD_SELECTED_STATISTIC', {
          numberOfSelected: this.selectedRegistrationIds.length,
          numberOfChildren: this.activityList.totalNumberOfChildren,
        });
      } else {
        statistic = Vue.filter('fromTextKey')('PRESENCE_DASHBOARD_NO_STATISTIC');
        let cleanFilters = true;
        if (
          this.filterOptions.groupId != null ||
          this.filterOptions.activityType != null ||
          this.filterOptions.status != null
        ) {
          cleanFilters = false;
        }
        if (!cleanFilters) {
          statistic = Vue.filter('fromTextKey')('PRESENCE_DASHBOARD_STATISTIC_WITH_FILTERING', {
            totalNumberOfChildren: this.activityList.totalNumberOfChildren,
          });
        } else {
          if (
            this.activityList != null &&
            this.activityList.numberOfChildrenPresent != null &&
            this.activityList.totalNumberOfChildren != null
          ) {
            statistic = Vue.filter('fromTextKey')('PRESENCE_DASHBOARD_STATISTIC', {
              numberOfChildrenPresent: this.activityList.numberOfChildrenPresent,
              totalNumberOfChildren: this.activityList.totalNumberOfChildren,
            });
          }
        }
      }
      return statistic;
    },
    chosenDepartment() {
      return this.getDepartments.find(d => d.id === this.filterOptions.departmentId);
    },
  },
  methods: {
    ...mapActions({
      loadActivityList: types.LOAD_ACTIVITY_LIST,
      reloadActivityList: types.RELOAD_ACTIVITY_LIST,
      loadPresenceFilters: types.LOAD_PRESENCE_FILTER,
      updateSelectedChildrenStatus: types.UPDATE_SELECTED_CHILDREN_STATUS,
      loadPresenceConfiguration: types.LOAD_PRESENCE_CONFIGURATION,
    }),
    ...mapMutations({
      updateFilterOptions: types.MUTATE_FILTER_OPTIONS,
      setSelectedRegistrations: types.MUTATE_SELECTED_REGISTRATION_TO_EDIT,
    }),
    getActivityHasContentOnRightSide(activity) {
      const hasComment = activity.comment || activity.note;
      const isEditing = this.isEditingMode;
      const isEmphasized = activity.isEmphasized;
      return hasComment || isEditing || isEmphasized;
    },
    viewChildContact(id) {
      this.$router.push({
        name: 'userview',
        params: { id: id },
        query: { parent: parentTypes.PRESENCE },
      });
    },
    getSortCss(sortType) {
      return this.activeSort == sortType ? this.filterOptions.orderDirection : '';
    },
    getActivityLabelName(activity, futureOrPastActivity) {
      if (
        futureOrPastActivity.activityType === exitTypes.DROP_OFF_TIME &&
        activity.isDefaultEntryTimes &&
        !futureOrPastActivity.checkinTime
      ) {
        return Vue.filter('fromTextKey')('PRESENCE_ENTRY_TIME_NOT_SET');
      } else if (activity.isDefaultExitTimes && !futureOrPastActivity.checkoutTime) {
        if (futureOrPastActivity.activityType === exitTypes.PICKED_UP_BY && futureOrPastActivity.exitWith) {
          return Vue.filter('fromTextKey')('PRESENCE_EXIT_TIME_NOT_SET_PICKED_UP_BY', {
            exitWith: futureOrPastActivity.exitWith,
          });
        } else if (futureOrPastActivity.activityType === exitTypes.PICKED_UP_BY && !futureOrPastActivity.exitWith) {
          return Vue.filter('fromTextKey')('PRESENCE_EXIT_TIME_NOT_SET_PICKED_UP_BY_UNDEFINED');
        } else if (futureOrPastActivity.activityType === exitTypes.GO_HOME_WITH) {
          return Vue.filter('fromTextKey')('PRESENCE_EXIT_TIME_NOT_SET_GO_HOME_WITH', {
            exitWith: futureOrPastActivity.exitWith,
          });
        } else if (
          futureOrPastActivity.activityType === exitTypes.SEND_HOME ||
          futureOrPastActivity.activityType === exitTypes.SELF_DECIDER
        ) {
          return Vue.filter('fromTextKey')('PRESENCE_EXIT_TIME_NOT_SET');
        }
      }

      return futureOrPastActivity.label;
    },
    changeDrawerShow(isShowed) {
      this.$router.push({ name: 'presenceEmployee' });
      if (!isShowed) {
        this.loadActivities(false);
      }
    },
    showEditingModal(presenceRegistrationId = null) {
      let selectedRegistrations = [];
      if (presenceRegistrationId) {
        selectedRegistrations = this.activityList.activities.filter(
          a => presenceRegistrationId == a.presenceRegistrationId
        );
      } else {
        selectedRegistrations = this.activityList.activities.filter(a =>
          this.selectedRegistrationIds.includes(a.presenceRegistrationId)
        );
      }
      this.setSelectedRegistrations({
        selectedRegistrations: selectedRegistrations,
      });
      this.$refs.updateChildrenStatusForm.show();
    },
    getActivityCssClass(activity) {
      let cssClass = 'activity-data';
      if (activity.presenceState === presenceStates.PHYSICAL_PLACEMENT) {
        cssClass += ' physical-placement';
      }
      for (const groupStatus of this.states) {
        for (const status of groupStatus.options) {
          if (activity.presenceState === status.value) {
            cssClass += ' ' + status.class;
          }
        }
      }
      return cssClass;
    },
    sortUpdated(sortType, orderDirection) {
      this.activeSort = sortType;
      this.updateFilterOptions({
        activityListSort: sortType,
        orderDirection: orderDirection,
      });
    },
    refreshActivityList() {
      this.loadActivities(false);
    },
    resetEditMode() {
      this.isEditingMode = false;
      this.checkAllRegistration = false;
      this.selectedRegistrationIds = [];
    },
    cancelEdit() {
      this.resetEditMode();
      this.$router.push({ name: 'presenceEmployee' });
    },
    checkedAllChildren(allSelected) {
      this.checkAllRegistration = !this.checkAllRegistration;
      if (allSelected) {
        this.selectedRegistrationIds = [...new Set(this.activityList.activities.map(u => u.presenceRegistrationId))];
      } else {
        this.selectedRegistrationIds = [];
      }
    },
    updateStatus() {
      if (this.compatibleRegistrationsToUpdate.length > 0) {
        const params = {
          presenceRegistrationIds: [
            ...new Set(this.compatibleRegistrationsToUpdate.map(r => r.presenceRegistrationId)),
          ],
          status: this.changedStatus,
        };
        this.updateSelectedChildrenStatus(params).then(() => {
          this.isEditingMode = false;
          this.selectedRegistrationIds = [];

          this.loadActivities(false);
        });
      } else {
        this.isEditingMode = false;
        this.selectedRegistrationIds = [];
      }
    },
    showPresenceState(activity) {
      const presenceState = {};
      if (this.canShowPresenceState(activity)) {
        if (activity.location != null && activity.presenceState === presenceStates.PHYSICAL_PLACEMENT) {
          presenceState.label = activity.location.name;
          presenceState.icon = activity.location.symbol;
          presenceState.class = 'physical-placement';
        } else {
          for (const groupStatus of this.states) {
            for (const status of groupStatus.options) {
              if (activity.presenceState === status.value) {
                presenceState.label = status.label;
                presenceState.icon = status.icon;
                presenceState.class = status.class;
              }
            }
          }
        }
      }
      return presenceState;
    },
    getActivities(activity) {
      const activities = { pastActivities: [], futureActivities: [] };
      for (const pastActivity of activity.pastActivities) {
        pastActivity.label = this.calculateActivityLabel(pastActivity, true);
        activities.pastActivities.push(pastActivity);
      }
      for (const futureActivity of activity.futureActivities) {
        futureActivity.label = this.calculateActivityLabel(futureActivity, false);
        activities.futureActivities.push(futureActivity);
      }
      return activities;
    },
    calculateActivityLabel(activity, isPast) {
      let label = '';
      if (this.activities.find(act => act.value === activity.activityType) != null) {
        label = isPast
          ? this.activities.find(act => act.value === activity.activityType).labelPast
          : this.activities.find(act => act.value === activity.activityType).label;
      }
      if (activity.activityType === exitTypes.DROP_OFF_TIME || activity.activityType === exitTypes.CHECK_IN) {
        if (isPast) {
          label = label + ' ' + Vue.filter('fromTextKey')('AT') + ' ' + activity.checkinTime;
        } else {
          label = label + ' ' + Vue.filter('fromTextKey')('AT') + ' ' + activity.entryTime;
        }
      }
      if (activity.activityType === exitTypes.SPARE_TIME) {
        label =
          Vue.filter('fromTextKey')('PRESENCE_STATE_SPARE_TIME_ACTIVITY') +
          ' ' +
          Vue.filter('fromTextKey')('AT') +
          ' ' +
          activity.startTime +
          ' - ' +
          activity.endTime;
      }
      if (activity.activityType === exitTypes.SLEEPING) {
        label =
          Vue.filter('fromTextKey')('PRESENCE_STATE_SLEEPING_PAST') +
          ' ' +
          Vue.filter('fromTextKey')('AT') +
          ' ' +
          activity.startTime +
          ' - ' +
          activity.endTime;
      }
      if (activity.activityType === exitTypes.PICKED_UP_BY || activity.activityType === exitTypes.GO_HOME_WITH) {
        if (isPast) {
          label += ' ' + Vue.filter('fromTextKey')('AT') + ' ' + activity.checkoutTime;
        } else {
          const labelWithoutExitWith =
            activity.activityType === exitTypes.PICKED_UP_BY
              ? Vue.filter('fromTextKey')('PRESENCE_ACTIVITY_PICKED_UP')
              : Vue.filter('fromTextKey')('PRESENCE_ACTIVITY_GO_HOME');
          label =
            (activity.exitWith ? label + ': ' + activity.exitWith : labelWithoutExitWith) +
            ' ' +
            Vue.filter('fromTextKey')('AT') +
            ' ' +
            activity.exitTime;
        }
      }
      if (activity.activityType === exitTypes.SEND_HOME) {
        if (isPast) {
          label += ' ' + Vue.filter('fromTextKey')('AT') + ' ' + activity.checkoutTime;
        } else {
          label += ': ' + Vue.filter('fromTextKey')('AT') + ' ' + activity.exitTime;
        }
      }
      if (activity.activityType === exitTypes.SELF_DECIDER) {
        if (isPast) {
          label += ' ' + Vue.filter('fromTextKey')('AT') + ' ' + activity.checkoutTime;
        } else {
          label =
            Vue.filter('fromTextKey')('PRESENCE_PICKUP_TYPE_SELF_DECIDER') +
            ': ' +
            Vue.filter('fromTextKey')('AT') +
            ' ' +
            activity.selfDeciderStartTime +
            ' - ' +
            activity.selfDeciderEndTime;
        }
      }
      return label;
    },
    toggleAllActivities(activity) {
      if (activity.showAll == null) {
        activity.showAll = true;
      } else {
        activity.showAll = !activity.showAll;
      }
      this.$forceUpdate();
    },
    toggleAllRows() {
      this.showAllActivities = !this.showAllActivities;
      for (const activity of this.activityList.activities) {
        activity.showAll = this.showAllActivities;
      }
      this.$forceUpdate();
    },
    reloadActivities() {
      if (this.activityList.activities != null && this.departments.length > 0 && !this.isEditingMode) {
        this.loadPresenceFilters({
          institutionCodes: this.activeInstitutions,
        }).then(() => {
          this.reloadActivityList();
        });
      }
    },
    closeUpdateStatusForm() {
      this.resetEditMode();
      this.loadActivities(false);
    },
    loadActivities(loadMore, loadAll = false) {
      this.isLoading = !loadMore;
      this.loadPresenceConfiguration({
        institutionCode: this.chosenDepartment.institutionCode,
      }).then(() => {
        this.loadActivityList({ loadMore: loadMore, loadAll: loadAll }).then(() => {
          if (this.showAllActivities) {
            this.activityList.activities.map(activity => {
              activity.showAll = this.showAllActivities;
            });
          }

          if (this.checkAllRegistration === true) {
            this.checkedAllChildren(true);
          }

          this.isLoading = false;
          this.isLoadingMore = false;
        });
      });
    },
    changeActivityTypeFilter(activityType = null) {
      this.filterOptions.activityType = activityType;
      this.changeFilter();
    },
    changeFilter() {
      this.loadActivities(false);
    },
    handleScroll() {
      if (this.hasMoreActivity && !this.isLoadingMore && !this.isLoading) {
        const scrollDiv = $('#activityList');
        if (scrollDiv.length > 0) {
          const scrollHeight = $(window).scrollTop() + $(window).height();
          const contentHeight = scrollDiv.offset().top + scrollDiv.height();
          if (scrollHeight >= contentHeight) {
            this.isLoadingMore = true;
            this.loadActivities(true);
          }
        }
      }
    },
    showComment(activity) {
      this.activityComment = activity;
      this.$refs.activityComment.show();
    },
    canShowPresenceState(activity) {
      const settingsDashboard = this.presenceConfiguration.dashboardModuleSettings.find(
        setting => setting.presenceDashboardContext === presenceDashboardContext.EMPLOYEE_DASHBOARD
      );
      let presenceModule = null;
      switch (activity.presenceState) {
        case presenceStates.FIELDTRIP:
          presenceModule = settingsDashboard.presenceModules.find(
            presenceModule => presenceModule.moduleType === presenceModules.FIELD_TRIP
          );
          break;
        case presenceStates.SPARE_TIME_ACTIVITY:
          presenceModule = settingsDashboard.presenceModules.find(
            presenceModule => presenceModule.moduleType === presenceModules.SPARE_TIME_ACTIVITY
          );
          break;
      }

      if (presenceModule !== null) {
        return presenceModule.permission !== presenceModuleStatus.DEACTIVATED;
      }

      return true;
    },
  },
  watch: {
    filterOptions: {
      handler() {
        if (this.filterOptions.departmentId) {
          this.loadActivities(false);
        }
      },
      deep: true,
    },
    'filterOptions.activityListSort'() {
      this.activeSort = this.filterOptions.activityListSort;
    },
    selectedRegistrationIds() {
      if (this.selectedRegistrationIds.length == this.activityList.totalNumberOfChildren) {
        this.checkAllRegistration = true;
      } else {
        this.checkAllRegistration = false;
      }
    },
  },
  mounted() {
    if (this.filterOptions.departmentId) {
      this.loadActivities(false);
    }
    window.addEventListener('scroll', this.handleScroll);

    if (this.reloadInterval != null) {
      clearInterval(this.reloadInterval);
    }

    this.reloadInterval = setInterval(() => {
      this.reloadActivities();
    }, 600000);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    clearInterval(this.reloadInterval);
  },
  components: {
    Icon,
    AulaButton,
    AulaButtons,
    AulaImg,
    UpdateChildrenStatusForm,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/presence/_presence-employee.scss';

.mobile-statistic {
  min-height: 45px;
}

.user-name {
  .alert {
    &.alert-danger {
      &::before {
        top: -9px;
      }
    }
  }
}
</style>
