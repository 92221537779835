<template>
  <header class="subscription-metadata">
    <IconContainer v-if="shownSubscription.sensitive">
      <Icon :name="iconClassEnum.LOCK" />
    </IconContainer>

    <div class="subscription-title-container selectable-text">
      <div class="subscription-title" :title="shownSubscription.subject">
        {{ shownSubscription.subject }}
      </div>
      <div class="subscription-creator">
        {{
          $t('messages.thread_metadata_1', {
            creator: shownSubscription.threadCreator.fullName,
          })
        }}
        <span @click="toggleMetadataVisibility" v-html="getParticipantNames(shownSubscription.threadCreator)"></span>
        <span class="datetime">
          {{
            $t('messages.thread_metadata_2', {
              startedAt: format(shownSubscription.threadStartedDateTime, dateFormatEnum.LONG_DATE_TIME_WITHOUT_YEAR),
            })
          }}
        </span>
      </div>
    </div>
  </header>
</template>
<script>
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import { format } from '../../../shared/utils/dateUtil';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import IconContainer from '../../../shared/components/IconContainer.vue';

export default {
  components: { IconContainer, Icon },
  props: {
    shownSubscription: { Type: Object, default: null },
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    dateFormatEnum() {
      return dateFormatEnum;
    },
    threadParticipants() {
      return this.shownSubscription.recipients.filter(r => r.leaveTime == null);
    },
  },
  methods: {
    format,
    getParticipantNames(item) {
      if (item == null) {
        return '';
      }

      if (item.metadata == null) {
        return item.fullName;
      }

      const children = item.metadata.split(',');

      if (children.length <= 3) {
        return ' (' + item.metadata + ')';
      }
      const subMetadata = children.slice(0, 3).join(',');
      const remainingMetadata = children.slice(3).join(',');

      return (
        ' (' +
        subMetadata +
        ' <span class="childExpand" title="Se mere" id="metadata' +
        item.mailBoxOwner.id +
        '">+</span>' +
        '<span class="childList" id="full-metadata' +
        item.mailBoxOwner.id +
        '" style="display:none;">' +
        remainingMetadata +
        '</span>)'
      );
    },
    toggleMetadataVisibility(event) {
      const target = event.target;
      const fullMetadataId = 'full-metadata' + target.id.replace('metadata', '');
      const fullMetadataElement = document.getElementById(fullMetadataId);
      if (fullMetadataElement) {
        if (fullMetadataElement.style.display === 'none') {
          fullMetadataElement.style.display = 'inline';
          target.textContent = '-';
        } else {
          fullMetadataElement.style.display = 'none';
          target.textContent = '+';
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.subscription-metadata {
  display: flex;
  flex-direction: row;
  gap: 4px;

  .subscription-title-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 40px;
    width: 100%;

    .subscription-title {
      min-height: 40px;
      font-size: 22px;
      font-weight: 700;
      padding-top: 4px;
    }

    .subscription-creator {
      display: block;
      color: var(--color-grey-dim);
      min-height: 24px;

      .datetime {
        text-transform: capitalize;
      }
    }
  }

  .icon-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    color: var(--color-alert);
    background-color: transparent;

    .aula-icon {
      --font-size: 22px;
      padding-top: 8px;
    }
  }
}
</style>

<style lang="scss">
.clickable {
  cursor: pointer;
  font-weight: bold;
  margin-left: 5px;
}

.childExpand {
  cursor: pointer;
  font-weight: bold;
}
</style>
