import { fileUtil } from '../utils/fileUtil';
import { ZippingService } from './Zipping.service';

interface AlbumForDownload {
  title: string;
  albumMedias: Array<{
    file: {
      url: string;
      name: string;
    };
  }>;
}

type CallbackFunction = () => void;

export class AlbumDownloader {
  static async downloadAlbums(
    downloadedFolderName: string,
    selectedAlbums: AlbumForDownload[],
    shouldSplitFolders: boolean,
    successCallBack: CallbackFunction,
    failCallback: CallbackFunction
  ): Promise<void> {
    let zipStream = ZippingService.createEmptyZipStream();
    const duplicatedFileNamesCounter = fileUtil.createDuplicateNamesCounter();
    const duplicatedAlbumNamesCounter = fileUtil.createDuplicateNamesCounter();
    for (const album of selectedAlbums) {
      const albumMedias = album.albumMedias;
      const albumTitle = duplicatedAlbumNamesCounter.getNonDuplicatedName(album.title);
      for (const media of albumMedias) {
        const filePath = AlbumDownloader.generateFilePath(albumTitle, media.file.name, shouldSplitFolders);
        const nonDuplicatedFilePath = duplicatedFileNamesCounter.getNonDuplicatedName(filePath);
        zipStream = await ZippingService.addFileToZipStream(
          zipStream,
          nonDuplicatedFilePath,
          media.file.url,
          failCallback
        );
      }
    }
    ZippingService.downloadFilesAsZip(zipStream, downloadedFolderName, successCallBack, failCallback);
  }

  static generateFilePath(albumTitle: string, fileName: string, shouldSplitFolders: boolean): string {
    return shouldSplitFolders ? `${albumTitle}/${fileName}` : fileName;
  }
}
