<template>
  <NotificationDesktop
    v-if="desktopOnly || !isMobile"
    :is-loading="isLoading"
    :is-view-mode="isViewMode"
    :available-areas="availableAreas"
    :profile="profile"
    :every-day="everyDay"
    :show-no-day-selected="showNoDaySelected"
    @updateEveryDay="updateEveryDay"
    @updateModelDays="updateModelDays"
    @updateViewMode="updateViewMode"
    @initEditMode="initEditMode"
    @resetTable="resetTable"
    @deleteNotificationsFromModule="deleteNotificationsFromModule"
    @saveChanges="saveChanges"
  />
  <NotificationMobile
    v-else
    :is-loading="isLoading"
    :is-view-mode="isViewMode"
    :available-areas="availableAreas"
    :profile="profile"
    :every-day="everyDay"
    :show-no-day-selected="showNoDaySelected"
    :show-select-days="showSelectDays"
    @setAllSelectedDays="setAllSelectedDays"
    @updateEveryDay="updateEveryDay"
    @updateViewMode="updateViewMode"
    @initEditMode="initEditMode"
    @resetTable="resetTable"
    @deleteNotificationsFromModule="deleteNotificationsFromModule"
    @saveChanges="saveChanges"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import NotificationDesktop from '../../../shared/PageFragments/Notification/NotificationDesktop';
import NotificationMobile from '../../../shared/PageFragments/Notification/NotificationMobile';
import { comeGoNotificationAreas } from '../../../shared/enums/comeGoNotificationAreas';
import { notificationProviderKeyEnum } from '../../../shared/enums/notificationProviderKeyEnum';
import { NotificationSetting } from '../../business/notificationSetting';

export default {
  name: 'NotificationSettings',
  components: {
    NotificationMobile,
    NotificationDesktop,
  },
  provide() {
    return {
      [notificationProviderKeyEnum.NOTIFICATION_SETTING_MODEL]: () => this.notificationSettingModel,
    };
  },
  props: {
    editMode: { type: Boolean, default: false },
    desktopOnly: { type: Boolean, default: false },
  },
  data: function () {
    return {
      everyDay: null,
      isLoading: false,
      isViewMode: true,
      showSelectDays: false,
      render: false,
      moduleEnums: [
        { value: moduleTypes.OVERVIEW, name: this.$options.filters.fromTextKey('NOTIFICATION_CLEAR_MODULE_OVERVIEW') },
        { value: moduleTypes.CALENDAR, name: this.$options.filters.fromTextKey('NOTIFICATION_CLEAR_MODULE_CALENDAR') },
        { value: moduleTypes.MESSAGES, name: this.$options.filters.fromTextKey('NOTIFICATION_CLEAR_MODULE_MESSAGES') },
        { value: moduleTypes.PRESENCE, name: this.$options.filters.fromTextKey('NOTIFICATION_CLEAR_MODULE_PRESENCE') },
        { value: moduleTypes.GALLERY, name: this.$options.filters.fromTextKey('NOTIFICATION_CLEAR_MODULE_GALLERY') },
        {
          value: moduleTypes.DOCUMENTS,
          name: this.$options.filters.fromTextKey('NOTIFICATION_CLEAR_MODULE_DOCUMENT'),
        },
      ],
      notificationSettingModel: new NotificationSetting(),
    };
  },
  computed: {
    ...mapGetters({
      notificationSettings: types.GET_NOTIFICATION_SETTINGS,
      isMobile: types.GET_IS_MOBILE,
      profile: types.GET_CURRENT_PROFILE,
      moduleWidgetConfig: types.GET_PAGE_CONFIGURATION,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
    }),
    availableAreas() {
      const moduleType = this.moduleWidgetConfig.moduleConfigurations.map(
        moduleConfiguration => moduleConfiguration.module.type
      );
      return this.moduleEnums.filter(function (moduleEnum) {
        return moduleType.includes(moduleEnum.value);
      });
    },
    messages: function () {
      return (
        this.notificationSettingModel.getMessagesFromEmployees() ||
        this.notificationSettingModel.getMessagesFromGuardians() ||
        this.notificationSettingModel.getMessagesFromChildren()
      );
    },
    modelEveryDay: function () {
      return (
        this.notificationSettingModel.getMonday() &&
        this.notificationSettingModel.getTuesday &&
        this.notificationSettingModel.getWednesday() &&
        this.notificationSettingModel.getThursday() &&
        this.notificationSettingModel.getFriday() &&
        this.notificationSettingModel.getSaturday() &&
        this.notificationSettingModel.getSunday()
      );
    },
    showNoDaySelected() {
      return (
        (!this.notificationSettingModel.getMonday() &&
          !this.notificationSettingModel.getTuesday() &&
          !this.notificationSettingModel.getWednesday() &&
          !this.notificationSettingModel.getThursday() &&
          !this.notificationSettingModel.getFriday() &&
          !this.notificationSettingModel.getSaturday() &&
          !this.notificationSettingModel.getSunday()) ||
        !this.notificationSettingModel.getScheduledTime()
      );
    },
  },
  watch: {
    modelEveryDay(val, oldVal) {
      if (val != oldVal) {
        this.everyDay = val;
      }
    },
  },
  mounted() {
    this.initNotificationSetting();
  },
  methods: {
    ...mapActions({
      deleteModuleNotifications: types.DELETE_MODULE_NOTIFICATIONS,
      saveSettings: types.SAVE_NOTIFICATION_SETTINGS,
      getNotificationSetting: types.SET_NOTIFICATION_SETTINGS,
    }),
    convertTimeToScheduledTime(time) {
      if (time != null && time != '') {
        let scheduledTime = null;
        if (time.length === 5) {
          scheduledTime = time.slice(0, 2) + time.slice(3, 5);
        } else {
          scheduledTime = ('0' + time.HH).slice(-2) + ('0' + time.mm).slice(-2);
        }
        return scheduledTime;
      }
      return null;
    },
    setAllSelectedDays() {
      if (!this.everyDay) {
        this.showSelectDays = true;
      }
      this.notificationSettingModel.setMonday(this.everyDay);
      this.notificationSettingModel.setTuesday(this.everyDay);
      this.notificationSettingModel.setWednesday(this.everyDay);
      this.notificationSettingModel.setThursday(this.everyDay);
      this.notificationSettingModel.setFriday(this.everyDay);
      this.notificationSettingModel.setSaturday(this.everyDay);
      this.notificationSettingModel.setSunday(this.everyDay);
    },
    updateEveryDay(value) {
      this.everyDay = value;
    },
    updateViewMode(value) {
      this.isViewMode = value;
    },
    saveChanges() {
      let sendTime = null;
      this.isLoading = true;
      let canSave = true;

      if (this.notificationSettingModel.getInstant()) {
        this.notificationSettingModel.setMonday(false);
        this.notificationSettingModel.setTuesday(false);
        this.notificationSettingModel.setWednesday(false);
        this.notificationSettingModel.setThursday(false);
        this.notificationSettingModel.setFriday(false);
        this.notificationSettingModel.setSaturday(false);
        this.notificationSettingModel.setSunday(false);
        this.notificationSettingModel.setScheduledTime(null);
      } else {
        if (this.everyDay) {
          this.notificationSettingModel.setMonday(true);
          this.notificationSettingModel.setTuesday(true);
          this.notificationSettingModel.setWednesday(true);
          this.notificationSettingModel.setThursday(true);
          this.notificationSettingModel.setFriday(true);
          this.notificationSettingModel.setSaturday(true);
          this.notificationSettingModel.setSunday(true);
        } else if (this.showNoDaySelected) {
          this.isLoading = false;
          return;
        }
        if (
          this.notificationSettingModel.getScheduledTime() == null ||
          this.notificationSettingModel.getScheduledTime() === ''
        ) {
          canSave = false;
        } else {
          sendTime = this.convertTimeToScheduledTime(this.notificationSettingModel.getScheduledTime());
        }
      }

      if (canSave) {
        const notificationSetting = {
          notifyMessages:
            this.notificationSettingModel.getMessagesFromEmployees() ||
            this.notificationSettingModel.getMessagesFromGuardians() ||
            this.notificationSettingModel.getMessagesFromChildren(),
          messagesFromEmployees: this.notificationSettingModel.getMessagesFromEmployees(),
          messagesFromGuardians: this.notificationSettingModel.getMessagesFromGuardians(),
          messagesFromChildren: this.notificationSettingModel.getMessagesFromChildren(),
          calendar: this.notificationSettingModel.getCalendar(),
          notifyAssignedAsSubstituteTeacher: this.notificationSettingModel.getNotifyAssignedAsSubstituteTeacher(),
          notifyLessonNoteChanged: this.notificationSettingModel.getNotifyLessonNoteChanged(),
          gallery: this.notificationSettingModel.getGallery(),
          post: this.notificationSettingModel.getPost(),
          app: this.notificationSettingModel.getAppDisabled(),
          email: this.notificationSettingModel.getEmailDisabled(),
          instant: this.notificationSettingModel.getInstant(),
          scheduledTime: sendTime,
          monday: this.notificationSettingModel.getMonday(),
          tuesday: this.notificationSettingModel.getTuesday(),
          wednesday: this.notificationSettingModel.getWednesday(),
          thursday: this.notificationSettingModel.getThursday(),
          friday: this.notificationSettingModel.getFriday(),
          saturday: this.notificationSettingModel.getSaturday(),
          sunday: this.notificationSettingModel.getSunday(),
          widgetSettings: this.notificationSettingModel.getWidgetSettings(),
        };
        if (this.profile.role === portalRoles.GUARDIAN || this.profile.role === portalRoles.OTP) {
          notificationSetting.pickUpActivity = this.notificationSettingModel.getPickUpActivity();
          notificationSetting.vacationRequest = this.notificationSettingModel.getVacationRequest();
        }
        this.saveSettings(notificationSetting).then(
          function () {
            this.isLoading = false;
            this.isViewMode = true;
          }.bind(this)
        );
      } else {
        this.isLoading = false;
      }
    },
    updateModelDays() {
      this.notificationSettingModel.setMonday(this.everyDay);
      this.notificationSettingModel.setTuesday(this.everyDay);
      this.notificationSettingModel.setWednesday(this.everyDay);
      this.notificationSettingModel.setThursday(this.everyDay);
      this.notificationSettingModel.setFriday(this.everyDay);
      this.notificationSettingModel.setSaturday(this.everyDay);
      this.notificationSettingModel.setSunday(this.everyDay);
    },
    deleteNotificationsFromModule() {
      this.deleteModuleNotifications({
        notificationAreas: this.notificationSettingModel.getSelectedAreas(),
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    resetTable() {
      this.initNotificationSetting();
    },
    initNotificationSetting() {
      this.render = false;
      this.isViewMode = !this.editMode;
      this.notificationSettingModel.setScheduledTime('08:00');
      this.getNotificationSetting().then(() => {
        if (this.notificationSettings.notifyMessages) {
          this.notificationSettingModel.setMessagesFromEmployees(this.notificationSettings.notifyMessagesFromEmployees);
          this.notificationSettingModel.setMessagesFromGuardians(this.notificationSettings.notifyMessagesFromGuardians);
          this.notificationSettingModel.setMessagesFromChildren(this.notificationSettings.notifyMessagesFromChildren);
        } else {
          this.notificationSettingModel.setMessagesFromEmployees(false);
          this.notificationSettingModel.setMessagesFromGuardians(false);
          this.notificationSettingModel.setMessagesFromChildren(false);
        }
        this.notificationSettingModel.setCalendar(this.notificationSettings.notifyCalendar);
        this.notificationSettingModel.setNotifyAssignedAsSubstituteTeacher(
          this.notificationSettings.notifyAssignedAsSubstituteTeacher
        );
        this.notificationSettingModel.setNotifyLessonNoteChanged(this.notificationSettings.notifyLessonNoteChanged);
        this.notificationSettingModel.setGallery(this.notificationSettings.notifyGallery);
        this.notificationSettingModel.setPost(this.notificationSettings.notifyPosts);
        if (
          (this.profile.role === portalRoles.GUARDIAN || this.profile.role === portalRoles.OTP) &&
          this.notificationSettings.comeGoNotificationSettings
        ) {
          const pickUpActivitySetting = this.notificationSettings.comeGoNotificationSettings.find(
            setting => setting.comeGoType === comeGoNotificationAreas.PICK_UP_ACTIVITY
          );
          const vacationRequestSetting = this.notificationSettings.comeGoNotificationSettings.find(
            setting => setting.comeGoType === comeGoNotificationAreas.VACATION_REQUEST
          );
          if (pickUpActivitySetting) {
            this.notificationSettingModel.setPickUpActivity(pickUpActivitySetting.activated);
          }
          if (vacationRequestSetting) {
            this.notificationSettingModel.setVacationRequest(vacationRequestSetting.activated);
          }
        }
        this.notificationSettingModel.setAppDisabled(!this.notificationSettings.appDisabled);
        this.notificationSettingModel.setEmailDisabled(!this.notificationSettings.emailDisabled);
        this.notificationSettingModel.setInstant(this.notificationSettings.instant);
        this.notificationSettingModel.setMonday(this.notificationSettings.monday);
        this.notificationSettingModel.setTuesday(this.notificationSettings.tuesday);
        this.notificationSettingModel.setWednesday(this.notificationSettings.wednesday);
        this.notificationSettingModel.setThursday(this.notificationSettings.thursday);
        this.notificationSettingModel.setFriday(this.notificationSettings.friday);
        this.notificationSettingModel.setSaturday(this.notificationSettings.saturday);
        this.notificationSettingModel.setSunday(this.notificationSettings.sunday);
        this.notificationSettingModel.setAppAvailable(this.notificationSettings.appAvailable);
        this.notificationSettingModel.setEmailAvailable(this.notificationSettings.emailAvailable);
        this.notificationSettingModel.setWidgetSettings(this.notificationSettings.widgetNotificationSettingDtos);
        if (
          this.notificationSettingModel.getMonday() &&
          this.notificationSettingModel.getTuesday() &&
          this.notificationSettingModel.getWednesday() &&
          this.notificationSettingModel.getThursday() &&
          this.notificationSettingModel.getFriday() &&
          this.notificationSettingModel.getSaturday() &&
          this.notificationSettingModel.getSunday()
        ) {
          this.everyDay = true;
          this.showSelectDays = false;
        } else {
          this.everyDay = false;
          this.showSelectDays = true;
        }
        if (this.notificationSettings.scheduledTime != null) {
          this.notificationSettingModel.setScheduledTime(
            this.notificationSettings.scheduledTime.slice(0, 2) +
              ':' +
              this.notificationSettings.scheduledTime.slice(2, 4)
          );
        }
        this.render = true;
      });
    },
    initEditMode() {
      this.isViewMode = false;
      this.showSelectDays = true;
    },
  },
};
</script>

<style scoped lang="scss" />
